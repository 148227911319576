.number-input__btn { // hide custom increment buttons if js is disabled
  display: none;
}

.js {
  .number-input { // basic style
    .form-control::-webkit-inner-spin-button,
    .form-control::-webkit-outer-spin-button { // hide native increment on webkit
      display: none;
    }

    .form-control { // hide native increment on Firefox
      -moz-appearance: textfield;
      display: block;
    }
  }

  .number-input__btn { // custom increment buttons
    display: inline-block; // flex fallback
    display: flex;
  }
}

// --v2 - custom increment w/ buttons outside input element
.js .number-input--v2 {
  --number-input-btn-width: 20px;
  display: flex;
  align-items: center;

  .form-control {
    margin: 0 $space-xs;
    width: 20px;
    order: 1;
    text-align: center;
    color: cc(dark, text);
  }

  .number-input__btn {
    width: var(--number-input-btn-width);
    height: var(--number-input-btn-width);
    border-radius: 50%;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    background-color: cc(h400, dark);
    transition: background-color 500ms cb(reg);

    &:hover {
      background-color: cc(h500, dark);
    }

    &:focus {
      outline: none;
      background-color: cc(branding);

      .icon {
        color: cc(light, text);
      }
    }

    &:active {
      background-color: darken(cc(branding), 5%);
    }

    .icon {
      font-size: $text-sm;
      width: 1em;
      height: 1em;
    }
  }

  .number-input__btn--plus {
    order: 2;
  }
}
