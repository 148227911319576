/* --------------------------------

File#: _1_slider
Title: Slider
Descr: Slider element for choosing numbers between a min and a max value
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --slider-width: 100%;
  --slider-track-height: 4px;
  --slide-thumb-size: 18px;
  // ⚠️ CSS variables cannot be used in Edge to style range pseudo-elements
  // check slider__input::-ms-thumb 👇
}

@mixin sliderThumbStyle { // thumb style
  width: var(--slide-thumb-size);
  height: var(--slide-thumb-size);
  border-radius: 50%;
  border: 3px solid cc(branding);
  background-color: cc(light);
  box-shadow: cc(h800, dark);
  cursor: grab;
}

@mixin sliderThumbFocusStyle { // thumb focus style
  box-shadow: 0 0 5px 3px cc(branding, .2);
}

@mixin sliderTrackStyle { // track style
  height: var(--slider-track-height);
  border-radius: 50em;
  // this is used to set the background color of the slider: --color-primary for the fill and --color-contrast-low for the deafult color
  // --slider-fill-value and --slider-empty-value are percentage values defined inside the .slider__range class
  background-image: linear-gradient(to right, cc(branding) var(--slider-fill-value), cc(h400, dark) var(--slider-fill-value), cc(h400, dark) var(--slider-empty-value));
}

// slider
.slider {
  position: relative;
  width: var(--slider-width);
}

.slider__range { // wrapper of the input[type="range"] element
  width: 100%;
  // these css variables are updated using JS - change the background color of the slider
  --slider-fill-value: 0%;
  --slider-empty-value: 100%;
}

.slider__input { // range input
  display: block;
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
}

.slider__input:focus {
  outline: none;
}

// thumb style
.slider__input::-webkit-slider-thumb {
  -webkit-appearance: none;
  @include sliderThumbStyle;
  margin-top: calc((var(--slider-track-height) - var(--slide-thumb-size))*0.5);
}

.slider__input:active::-webkit-slider-thumb {
  cursor: grabbing;
}

.slider__input::-moz-range-thumb {
  @include sliderThumbStyle;
}

.slider__input:active::-moz-range-thumb {
  cursor: grabbing;
}

.slider__input:active::-ms-thumb {
  cursor: grabbing;
}

// thumb focus style
.slider__input:focus::-webkit-slider-thumb {
  @include sliderThumbFocusStyle;
}

.slider__input:focus::-moz-range-thumb {
  @include sliderThumbFocusStyle;
}

// track style
.slider__input::-webkit-slider-runnable-track {
  @include sliderTrackStyle;
  margin-top: calc((var(--slide-thumb-size) - var(--slider-track-height))*0.5);
}

.slider__input::-moz-range-track {
  @include sliderTrackStyle;
}

.slider__input::-moz-focus-outer { // fix bug on Firefox - remove dotted outline when input is in focus
  border: 0;
}

.js .slider__input::-ms-tooltip { // on IE, hide default tooltip if JS is enabled
  display: none;
}

// ⚠️ CSS variables cannot be used in Edge to style range pseudo-elements 👇
.slider__input::-ms-thumb {
  @include sliderThumbStyle;
  transform: translateY(0);
  background-color: white !important;
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, .1);
  height: 20px;
  width: 20px;
}

.slider__input:focus::-ms-thumb {
  box-shadow: inset 0 0 0 2px hsla(220, 90%, 56%, 0.2);
}

.slider__input::-ms-track {
  @include sliderTrackStyle;
  background-color: hsl(240, 1%, 60%);
  color: transparent;
  height: 8px;
}

.slider__value {
  font-size: $text-sm;
  strong, span {
    color: cc(dark, text);
    font-weight: $semi-bold;
  }
}

// no-js + browsers not supporting range input
.slider--range-not-supported .slider__value,
html:not(.js) .slider__value {
  display: none;
}


/* --------------------------------

File#: _2_slider-multi-value
Title: Multi Value Slider
Descr: Slider element for choosing a minimum and maximum value in a specified range
Usage: codyhouse.co/license

-------------------------------- */
@mixin sliderMultiValueTrackStyle { // track style
  pointer-events: none;
  // this is used to set the background color of the slider: --color-primary for the fill and --color-contrast-low for the deafult color
  // --slider-fill-value-start and --slider-fill-value-end are percentage values defined inside the .slider__range class
  background-image: linear-gradient(to right, cc(h400, dark) var(--slider-fill-value-start), cc(branding) var(--slider-fill-value-start), cc(branding) var(--slider-fill-value-end), cc(h400, dark) var(--slider-fill-value-end));
}

@mixin sliderMultiValueThumbStyle { // thumb style
  position: relative;
  z-index: 2;
  pointer-events: auto;
}

.slider--multi-value {
  position: relative;

  .slider__range {
    pointer-events: none;
  }
}

.slider--multi-value .slider__range:nth-of-type(1) {
  // CSS variables updated in JS - change the background color of the slider
  --slider-fill-value-start: 0%;
  --slider-fill-value-end: 100%;
}

.slider--multi-value .slider__range:nth-of-type(2) {
  position: absolute;
  top: 0;
  left: 0;
}

.slider--multi-value {
  // thumb style
  .slider__input::-webkit-slider-thumb {
    @include sliderMultiValueThumbStyle;
  }

  .slider__input::-moz-range-thumb {
    @include sliderMultiValueThumbStyle;
  }

  // track style
  .slider__input::-webkit-slider-runnable-track {
    @include sliderMultiValueTrackStyle;
  }

  .slider__input::-moz-range-track {
    @include sliderMultiValueTrackStyle;
  }

  .slider__range:nth-of-type(2) .slider__input::-moz-range-track {
    background-image: none;
  }
}

// IE and Edge Fallback
:not(*)::-ms-track,
.slider--multi-value {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

:not(*)::-ms-track,
.slider--multi-value .slider__range {
  pointer-events: auto;
  width: 48%;
}

:not(*)::-ms-track,
.slider--multi-value .slider__range:nth-of-type(2) {
  position: relative;
}

.slider__separator {
  margin: auto $space-xs;
}

// no js + browsers not supporting range input
.slider--multi-value.slider--range-not-supported .slider__range {
  display: inline-block;
  width: 45%;

  &:nth-of-type(2) {
    position: relative;
  }
}

.slider--range-not-supported .slider__separator,
html:not(.js) .slider__separator {
  display: none;
}
