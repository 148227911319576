.section {
  padding: 50px $space-sm;

  @media #{$mq-large-up} {
    padding: 100px 0;
  }

  &--light {
    background-color: cc(light);
  }

  &--no-pb {
    padding: 50px 0 0 0;

    @media #{$mq-large-up} {
      padding: 100px 0 0 0;
    }
  }

  &--adjust {
    padding: 60px $space-sm 100px $space-sm;

    @media #{$mq-large-up} {
      padding: 60px 0 100px 0;
    }
  }

  &--search-bar {
    padding: 0;
    width: 100%;
    margin: 0 auto;

    @media #{$mq-large-up} {
      position: relative;
      top: -50px;
      max-width: 1260px;
    }
  }

  &--background {
    background: url("/client/public/assets/img/img-promo-background.jpg") no-repeat;
    background-size: cover;
    position: relative;

    @media #{$mq-large-up} {
      padding: 10% $space-sm;
    }

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      background-color: cc(bg-dark);
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.7;
    }

    .section__group-title {
      text-align: center;
      position: relative;
      padding: 0 2%;
      margin: 0;

      @media #{$mq-large-up} {
        padding: 0 30%;
      }
    }

    .section__subtitle {
      color: cc(light, text);
      font-weight: $regular;

      span {
        font-weight: $bold;
      }
    }
  }

  &__group-title {
    margin-bottom: $space-xl;

    @media #{$mq-large-up} {
      margin-bottom: 0;
    }

    &--img {
      position: relative;

      &:before {
        @media #{$mq-large-up} {
          content: "";
          width: 400px;
          height: 200px;
          background: url(/client/public/assets/img/img-title.svg) center center no-repeat;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  &__title {
    text-transform: uppercase;
    color: cc(branding);
    font-weight: $semi-bold;
    letter-spacing: 2px;
    text-align: center;
  }

  &__subtitle {
    font-size: $text-xl;
    color: cc(dark, text);
    font-weight: $semi-bold;
    text-align: center;

    @media #{$mq-large-up} {
      font-size: $text-xxl;
    }
  }

  &__content {
    margin-top: $space-sm;

    @media #{$mq-large-up} {
      margin-top: $space-xl;
    }
  }

  &__slider {
    margin-top: 100px;
  }

  &__btn {
    margin-top: $space-sm;

    &--center {
      text-align: center;
    }
  }
}

//index static menu
.section__sitemap {
  margin-top: 60px;
}
