.sidebar {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: $zindex-overlay;
  overflow-y: scroll;
  background-color: cc(bg);
  top: 0;
  left: 0;
  visibility: hidden;
  transform: translateX(-100%);
  z-index: 9999;
  padding: 0 $space-sm $space-lg $space-sm;
  display: none;

  &.js-active {
    visibility: visible;
    transform: translateX(0);
    display: block;
  }

  @media #{$mq-large-up} {
    position: relative;
    top: 0;
    min-height: 800px;
    width: 350px;
    height: auto;
    margin-right: $gutter-width;
    background: transparent;
    visibility: visible;
    transform: translateX(0);
    overflow-y: initial;
    z-index: initial;
    padding: 0;
    display: block;
  }

  &--panel {
    width: 100%;
    background-color: cc(bg);
    transform: translateY(100px) scale(0);
    position: absolute;
    visibility: hidden;
    opacity: 0;
    top: 170px;
    left: 0;
    z-index: $zindex-overlay;
    padding: 0 $space-lg $space-lg $space-lg;
    transition: all 300ms cb(reg);

    &.js-open {
      transform: translateY(0) scale(1);
      visibility: visible;
      opacity: 1;
    }
  }

  &--light {
    background-color: cc(light);
  }

  &--detail {
    margin-right: 0;
    padding-bottom: 140px;

    @media #{$mq-large-up} {
      padding-bottom: 0;
    }
  }

  &__close {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: $space-sm 0 $space-lg 0;
    justify-content: flex-end;


    @media #{$mq-large-up} {
      display: none;
    }
  }

  &__item {
    margin-top: $space-lg;

    &:first-child {
      margin-top: 0;
    }
  }

  &__head {
    margin-bottom: $space-xs;
  }

  &__header {
    padding: $space-sm 0;
  }


  &__title {
    font-weight: $medium;
    display: block;
  }

  &__form {
    padding: $space-lg;
    background-color: cc(light);
    border-radius: $radius-sm;
  }
}