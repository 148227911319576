.tooltip {
 position: relative;

  &:hover {
    .tooltip__content {
      visibility: visible;
      opacity: 1;
    }
  }

  &__content {
    opacity: 0;
    visibility: hidden;
    width: 150px;
    text-align: center;
    max-width: 300px;
    background-color: cc(bg-dark);
    font-size: $text-xs;
    color: cc(light, text);
    padding: $space-xs;
    border-radius: $radius-xs;
    position: absolute;
    top: 110%;
    left: 50%;
    transform: translateX(-50%);
    transition: all 300ms cb(reg);
  }
}
