.mbt-card {
  width: 100%;
  background: cc(light);
  border: 1px solid #E6E8EC;
  border-radius: $radius-xxl;
  overflow: hidden;
  will-change: transform;
  background-repeat: no-repeat;
  display: flex;

  @media #{$mq-medium-up} {
    min-height: 225px;
  }

  &--ad {
    min-height: 100%;

    .mbt-card__content {
      h4 {
        border: none;
      }
    }

    .mbt-card__offer {
      justify-content: center;
      border: none;
    }

    .mbt-card__img {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        max-width: 160px;
        height: auto;
        padding: 0 $space-sm;

        @media #{$mq-medium-up} {
          padding: 0 $space-xs;
        }
      }
    }
  }

  &__img {
    width: 35%;
    height: 100%;


    @media #{$mq-medium-up} {
      width: 300px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .swiper {
      height: 100%;
      width: 100%;
    }

    .swiper-slide {
      background-size: cover;
    }

    .swiper-button-next,
    .swiper-button-prev {
      &:after {
        font-family: 'mybuddiestrip';
        content: $icon--arrow-ios-forward-outline;
        color: cc(white);
        font-size: $text-xl;
        background-color: cc(01, neutral);
        border-radius: 50%;
        padding: 4px;
      }
    }


    .swiper-button-next {
      right: 5px;
    }

    .swiper-button-prev {
      left: 5px;

      &:after {
        content: $icon--arrow-ios-back-outline;
      }
    }
  }

  &__container {
    width: 65%;
    padding: $space-xs 0;

    @media #{$mq-medium-up} {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      padding: $space-sm 0;
    }
  }

  &__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 $space-xs;

    @media #{$mq-medium-up} {
      padding: 0 $space-md;
      height: 100%;
    }
  }

  &__content {
    font-weight: $medium;
    height: inherit;

    h4 {
      font-size: $text-sm;
      color: cc(h900, dark);
      margin-bottom: 4px;
      padding-bottom: 4px;
      border-bottom: 1px solid cc(h400, dark);

      @media #{$mq-medium-up} {
        font-size: $text-md;
        padding-bottom: 8px;
        margin-bottom: 8px;
      }
    }

    p {
      font-size: $text-xs;
      color: cc(h600, dark);
    }

    &--flight {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

  }

  &__list {
    display: flex;
    flex-wrap: wrap;

    li {
      display: flex;
      margin-right: $space-xs;
      padding-top: $space-xs;

      &:first-child {
        margin-left: 0;
      }

      .icon {
        margin-right: $space-xs;
      }

      p {
        // display: none;

        @media #{$mq-medium-up} {
          // display: block;
        }
      }
    }
  }

  &__rating {
    display: flex;
    align-items: center;
    margin-top: $space-xs;
  }

  &__star {
    display: flex;
    align-items: center;
    color: cc(h800, dark);
    font-size: $text-xs;
    font-weight: $bold;

    @media #{$mq-medium-up} {
      margin-left: $space-md;
    }

    i {
      color: cc(warning, alert);
      margin-right: 4px;
    }

    span {
      color: cc(h600, dark);
      font-weight: $regular;
      margin-left: 4px;
    }
  }

  &__icon {
    color: cc(alert);
  }

  &__logo {
    max-width: 125px;
    height: auto;
    display: none;

    @media #{$mq-medium-up} {
      display: block;
    }
  }

  &__offer {
    padding: 0 $space-xs;
    margin-top: $space-sm;

    @media #{$mq-medium-up} {
      margin-top: 0;
      border-left: 1px solid cc(05, neutral);
      padding: 0 $space-md;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &__amount {

    @media #{$mq-medium-up} {
      display: flex;
      align-items: center;
      flex-direction: column;
      align-items: flex-end;
    }

  }

  &__price {
    font-size: 16px;
    font-weight: $bold;
    color: cc(branding);
    margin-top: 4px;

    @media #{$mq-medium-up} {
      font-size: 20px;
      text-align: right;
      margin-top: 0;
    }

    span {
      color: cc(01, neutral);
      font-size: $text-xs;
      font-weight: $medium;

      @media #{$mq-medium-up} {
        font-size: $text-sm;
      }
    }
  }

  &__discount-price {
    color: cc(03, neutral) !important;
    text-decoration: line-through;
    font-size: $text-xs  !important;
    margin: 0 4px;

    @media #{$mq-medium-up} {
      margin-right: $space-xs;
      font-size: $text-sm  !important;
    }
  }

  &__total-price {
    font-size: $text-sm;
    color: cc(01, neutral);
    font-weight: $semi-bold;
    margin-top: 4px;
    display: none;

    span {
      font-size: $text-xs;
      font-weight: $medium;
      color: cc(03, neutral);
    }

    @media #{$mq-medium-up} {
      display: block;
    }

  }

  &__action {
    display: flex;
    margin-top: $space-xs;

    @media #{$mq-medium-up} {
      margin-top: 0;
      align-items: center;
      justify-content: flex-end;
    }

    .mbt-button {
      padding: 6px 4px;
      margin: 0;

      &:first-child {
        margin-right: 4px;
      }

      @media #{$mq-medium-up} {
        padding: 10px 12px;
        margin: 0 4px;
      }

    }
  }
}

.mbt-card-flight {
  width: 100%;
  background: cc(light);
  border: 1px solid #E6E8EC;
  border-radius: $radius-xxl;
  overflow: hidden;

  .mbt-card__container {
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;

    @media #{$mq-medium-up} {
      margin: $space-sm 0;
      display: flex;
    }
  }

  .mbt-card__body {
    padding: 0 $space-xs;

    @media #{$mq-medium-up} {
      padding: 0 $space-md;
    }
  }

  .mbt-card__offer {
    display: flex;
    margin: $space-sm 0;
    padding: 0 $space-xs;
    justify-content: space-between;
    align-items: center;

    @media #{$mq-medium-up} {
      padding: 0 $space-md;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 0;
      border-left: 1px solid cc(05, neutral);
    }
  }

  .mbt-card__action {
    display: flex;

    @media #{$mq-medium-up} {
      flex-direction: row;
      align-items: flex-end;
    }

    .mbt-button {
      padding: 6px 4px;
      margin: 0;

      &:first-child {
        margin-right: 4px;
      }

      @media #{$mq-medium-up} {
        padding: 10px 12px;
        margin: 0 4px;
      }
    }

  }

  @media #{$mq-medium-up} {
    min-height: 225px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__desc {
    margin: $space-xs 0 $space-xs 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media #{$mq-medium-up} {
      display: block;
    }

    &:first-child {

      @media #{$mq-medium-up} {
        margin: $space-sm 0;
      }
    }

    @media #{$mq-medium-up} {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .mbt-checkbox {
      justify-content: center;


      @media #{$mq-medium-up} {
        display: flex;
      }

      &__label {
        display: none;

        @media #{$mq-medium-up} {
          display: block;
        }
      }

      @media #{$mq-medium-up} {
        justify-content: center;
        margin-bottom: 0;
      }
    }

    >* {
      @media #{$mq-medium-up} {
        margin-right: $space-xl;
      }
    }
  }

  &__infos {
    display: flex;
    justify-content: space-between;

    @media #{$mq-medium-up} {
      justify-content: flex-start;
    }

    p {
      font-size: $text-sm;
      font-weight: $bold;
      color: cc(h900, dark);
      text-align: center;


      &:last-child {
        margin-right: 0;
      }

      &:nth-child(2) {
        font-size: $text-xs;
        font-weight: $medium;
        margin: 0 8px;

        @media #{$mq-medium-up} {
          margin: 0 $space-md;
        }

        span {
          margin-top: 2px;
          padding-top: 2px;
          border-top: 1px solid cc(h400, dark);
        }
      }
    }

    span {
      font-size: 10px;
      display: block;
      color: cc(h600, dark);

      @media #{$mq-medium-up} {
        font-size: $text-xs;
      }
    }
  }

  &__time {
    margin-top: 4px;
    text-align: center;
    font-size: $text-xs;
    font-weight: $semi-bold;
    color: cc(h900, dark);

    @media #{$mq-large-up} {
      margin-top: 0;
    }
  }

  &__footer {
    font-size: $text-xs; // design -> 10px
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: cc(06, neutral);
    padding: $space-xs $space-lg;
  }
}

.mbt-card-ad {
  background-color: cc(light);
  border-radius: $radius-xxl;
  padding: 30px 16px 30px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__body {
    display: flex;
    align-items: center;
  }

  &__logo {
    max-width: 160px;
    height: auto;
    margin-right: $space-md;
  }

  &__text {
    font-size: $text-md;
    font-weight: $bold;
    color: cc(h900, dark);

    p {
      font-size: $text-xs;
      font-weight: $regular;
      color: cc(h600, dark);
    }
  }
}

.mbt-card-cover {
  cursor: pointer;
  width: 100%;
  min-height: 250px;
  background-color: cc(h400, dark, .1);
  border-radius: $radius-lg;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  padding: $space-md;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  &--portrait {
    min-height: 400px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(141, 142, 145, 0) 0%, rgba(31, 32, 34, 0.5) 100%);
    z-index: 0;
  }

  &__rating {
    z-index: 1;
  }

  &__text {
    z-index: 1;
    color: cc(light);
    font-weight: var(--font-weight-bold);

    h4 {
      font-size: var(--text-sm);
    }

    p {
      font-size: var(--text-xs);
    }
  }
}

.mbt-card-tiny {
  cursor: pointer;
  width: 100%;
  min-height: 200px;
  background-color: cc(h400, dark, .1);
  border-radius: $radius-lg;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;

  &__image {
    border-radius: $radius-md;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 150px;
    margin-bottom: $space-md;

    &--contain {
      background-size: contain;
    }

    &--small {
      background-size: 75%;
    }

  }

  &__text {
    color: cc(h900, dark);
    font-weight: var(--font-weight-bold);

    h4 {
      font-size: var(--text-sm);
      font-weight: $semi-bold;
    }

    p {
      color: cc(h900, dark, .6);
      font-size: var(--text-xs);
    }
  }

  &--border {
    border: 1px solid cc(h900, dark, .1);
    // padding: $space-md;
    transition: all $time-medium cb(bounce);

    .mbt-card-tiny__image {
      height: 150px;
      margin-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .mbt-card-tiny__text {
      padding: $space-sm;
    }

    &:hover {
      background-color: cc(light);
      border: 1px solid cc(light);
      box-shadow: 0px 16px 63px -16px rgba(15, 15, 15, 0.08);
    }
  }

  &--horizontal {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: auto;

    .mbt-card-tiny__image {
      width: 140px;
      height: 125px;
      margin-right: $space-sm;
    }
  }

}

.mbt-card-step {
  border-radius: $radius-sm;
  padding: 12px;
  background-color: cc(light);
  border: 1px solid cc(light);
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &:hover {
    cursor: pointer;
    border: 1px solid cc(branding);
  }

  &.js-current {
    border: 1px solid cc(branding);
    position: relative;

    &:before {
      content: "selected";
      position: absolute;
      top: -18px;
      left: 6px;
      background-color: cc(branding);
      font-family: $typo-regular;
      color: cc(white);
      font-size: 10px;
      text-transform: uppercase;
      font-weight: $semi-bold;
      padding: 2px 8px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }

  &.js-active {

    .mbt-card-step__checked {
      opacity: 1;
      color: cc(default, secondary);
    }

    .mbt-card-step__header {
      color: cc(default, primary);

      i {
        opacity: 1;
      }
    }
  }

  &--disabled {
    background-color: cc(05, neutral, .8);

    &.js-active {
      border: 1px solid cc(h400, dark);
    }
  }

  &__checked {
    position: absolute;
    top: 4px;
    right: 4px;
    font-size: $text-xl;
    opacity: .5;
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    color: cc(branding);
    font-weight: $bold;
    font-size: $text-sm;
    color: cc(01, neutral);
    line-height: 0;
    margin-bottom: $space-xs;

    i {
      margin-right: $space-xs;
      font-size: $text-md;
      opacity: .5;
    }
  }

  &__body {
    font-weight: $semi-bold;
    margin: $space-xs 0;
    font-size: $text-sm;
  }

  &__footer {
    display: flex;
    margin: auto -4px 0 -4px;

    >* {
      margin: 0 4px;
    }
  }
}

.mbt-card-empty {
  width: 100%;
  background-color: rgb(197, 197, 197);
  border-radius: $radius-lg;
  padding: $space-lg;
  min-height: 200px;
  display: flex;
  align-items: center;

  &--flight {
    background-color: cc(flight, service, .1);

    .mbt-card-empty__icon {
      .icon {
        color: cc(flight, service);
      }
    }

    .mbt-card-empty__title {
      color: cc(flight, service);
    }

    .btn {
      &:before {
        background-color: cc(flight, service);
        border-color: cc(flight, service);
      }

      &:hover {
        &:before {
          background-color: darken(cc(flight, service), 5);
          border-color: darken(cc(flight, service), 5);
        }
      }
    }
  }

  &--stay {
    background-color: cc(stay, service, .1);

    .mbt-card-empty__icon {
      .icon {
        color: cc(stay, service);
      }
    }

    .mbt-card-empty__title {
      color: cc(stay, service);
    }

    .btn {
      &:before {
        background-color: cc(stay, service);
        border-color: cc(stay, service);
      }

      &:hover {
        &:before {
          background-color: darken(cc(stay, service), 5);
          border-color: darken(cc(stay, service), 5);
        }
      }
    }
  }

  &--event {
    background-color: cc(event, service, .1);

    .mbt-card-empty__icon {
      .icon {
        color: cc(event, service);
      }
    }

    .mbt-card-empty__title {
      color: cc(event, service);
    }

    .btn {
      &:before {
        background-color: cc(event, service);
        border-color: cc(event, service);
      }

      &:hover {
        &:before {
          background-color: darken(cc(event, service), 5);
          border-color: darken(cc(event, service), 5);
        }
      }
    }
  }

  &--car {
    background-color: cc(car, service, .1);

    .mbt-card-empty__icon {
      .icon {
        color: cc(car, service);
      }
    }

    .mbt-card-empty__title {
      color: cc(car, service);
    }

    .btn {
      &:before {
        background-color: cc(car, service);
        border-color: cc(car, service);
      }

      &:hover {
        &:before {
          background-color: darken(cc(car, service), 5);
          border-color: darken(cc(car, service), 5);
        }
      }
    }
  }

  &--golf {
    background-color: cc(golf, service, .1);

    .mbt-card-empty__icon {
      .icon {
        color: cc(golf, service);
      }
    }

    .mbt-card-empty__title {
      color: cc(golf, service);
    }

    .btn {
      &:before {
        background-color: cc(golf, service);
        border-color: cc(golf, service);
      }

      &:hover {
        &:before {
          background-color: darken(cc(golf, service), 5);
          border-color: darken(cc(golf, service), 5);
        }
      }
    }
  }

  &--restaurant {
    background-color: cc(restaurant, service, .1);

    .mbt-card-empty__icon {
      .icon {
        color: cc(restaurant, service);
      }
    }

    .mbt-card-empty__title {
      color: cc(restaurant, service);
    }

    .btn {
      &:before {
        background-color: cc(restaurant, service);
        border-color: cc(restaurant, service);
      }

      &:hover {
        &:before {
          background-color: darken(cc(restaurant, service), 5);
          border-color: darken(cc(restaurant, service), 5);
        }
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;

    @media #{$mq-medium-up} {
      flex-direction: row;
    }
  }

  &__icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: cc(white, default);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 10px 10px 0px cc(h900, dark, 0.02);

    .icon {
      font-size: $text-xl;
    }
  }

  &__content {
    margin-top: $space-md;

    @media #{$mq-medium-up} {
      margin-left: $space-md;
      margin-top: 0;
    }
  }

  &__title {
    font-weight: $bold;
    font-size: $text-lg;
  }

  &__desc {
    font-weight: $regular;
    margin-bottom: $space-md;
  }
}

.mbt-card-newsletter {
  width: 100%;
  background-color: rgba(197, 197, 197, .2);
  border-radius: $radius-lg;
  padding: $space-lg;

  @media #{$mq-medium-up} {
    display: flex;
    align-items: center;
    min-height: 200px;
  }

  &__image {
    margin-right: $space-xl;
  }

  &__content {
    font-weight: $medium;
    height: inherit;

    h4 {
      font-size: $text-xl;
      font-weight: $semi-bold;
      color: cc(h900, dark);
      margin-bottom: 4px;
    }

    p {
      font-size: $text-md;
      color: cc(h600, dark);
    }

  }

  .btn {
    margin-left: auto;
    margin-top: $space-xl;

    @media #{$mq-medium-up} {
      margin-top: 0;
    }
  }
}

.mbt-card-checkout {
  background-color: cc(02, neutral, .05);
  display: flex;
  align-items: center;
  padding: $space-xs;
  border-radius: $radius-sm;

  &__img {
    width: 100px;
    height: 60px;
    margin-right: $space-sm;

    img {
      border-radius: $radius-sm;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__content {

    h4 {
      display: flex;
      align-items: center;
      font-weight: $semi-bold;
      color: cc(02, neutral);
      font-size: $text-sm;
    }

    .icon {
      color: cc(branding);
      margin-right: $space-xs;
      font-size: $text-md;
    }

    p {
      font-size: $text-sm;
      font-weight: $medium;
    }
  }

  &__offer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    .mbt-button {
      font-size: $text-xs;
      padding: 4px;
    }
  }

  &__price {
    font-weight: $semi-bold;
  }

}