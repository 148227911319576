.mbt-hero {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // background-color: cc(01, neutral);
  // overflow: hidden;
  // z-index: 0;
  padding: $space-sm 0 $space-sm 0;

  @media #{$mq-large-up} {
    // min-height: 300px;
    // height: 50vh;
    padding: 0;
  }

  &--home {
    // max-height: 80vh;
    height: calc(100vh - 60px);
    padding: 0;

    @media #{$mq-large-up} {
      min-height: 300px;
      height: 60vh;
    }
  }

  &__slide {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @media #{$mq-large-up} {
      background-position: 0% 38%;
    }

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, cc(01, neutral), cc(01, neutral, .4));
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: .6;
      transition: all 300ms cb(reg);
    }

    &:hover {
      &:after {
        background: linear-gradient(180deg, cc(01, neutral), cc(01, neutral, 0));
        opacity: .5;
      }
    }
  }

  &--page {
    background-color: cc(white);

    @media #{$mq-large-up} {
      // min-height: 300px;
    }
  }


  &__content {
    &--home {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
  }

  // &--setting {
  //   min-height: 0;
  //   height: 100px;
  //   overflow: visible;
  // }

  &__img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    // animation: scaleDown 2s cb(reg);

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, cc(01, neutral), cc(01, neutral, 0));
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: .7;
    }

    //   opacity: .8;
  }

}