$input-height: 48px;


.mbt-field {
    position: relative;
    width: 100%;

    &__input {
        border-radius: $radius-sm;
        height: $input-height;
        width: inherit;
        border: 1px solid cc(04, neutral);
        padding: 0 $space-lg 0 36px;
        position: relative;
        font-size: $text-md;
        font-weight: $medium;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        display: flex;
        align-items: center;

        &--no-icon {
            padding: 0 $space-sm 0 $space-sm;
        }

        &::placeholder {
            color: cc(03, neutral);
        }

        &:hover {
            border-color: cc(03, neutral);
        }

        &:focus {
            outline: none;
            border: 2px solid cc(default, primary);
            border-color: cc(default, primary);


            @media #{$mq-large-up} {
                outline: 1px solid cc(default, primary);
                border: 1px solid cc(default, primary);
            }

            +.icon {
                color: cc(default, primary);
            }
        }

        &::-webkit-inner-spin-button,
        &::-webkit-calendar-picker-indicator {
            display: none;
            -webkit-appearance: none;
        }

        &-textarea {
            height: 200px;
            resize: none;
            padding-top: 10px;
        }

    }

    &.error {

        .mbt-field__input {
            border-color: cc(error, alert);

            &:focus {
                border-color: cc(default, primary);
            }
        }

        .checkbox__label,
        .checkbox__field+label::before {
            border-color: cc(error, alert);
            color: cc(error, alert);
            accent-color: cc(error, alert);
            outline-color: cc(error, alert);

            &:focus {
                border-color: cc(default, primary);
                color: cc(default, primary);
                accent-color: cc(default, primary);
                outline-color: cc(default, primary);
                border: 2px solid cc(medium, border);
            }
        }

        .icon {
            color: cc(error, alert);
        }

        ::placeholder {
            color: cc(error, alert);
        }

        span {
            color: cc(error, alert);
        }
    }



    .icon {
        position: absolute;
        left: $space-xs;
        top: 50%;
        transform: translateY(-50%);
        color: cc(04, neutral);
        font-size: $text-xl;

        &-calendar {
            position: inherit;
            left: inherit;
            top: inherit;
            transform: inherit;
            color: inherit;
            font-size: inherit;
        }
    }



    &__placeholder {
        font-size: $text-md;
        font-weight: $semi-bold;
        color: cc(03, neutral);

        &.js-active {
            color: cc(02, neutral);
            font-weight: $semi-bold;
            margin-left: $space-xs;
        }

    }

    &__delete {
        position: absolute;
        top: 50%;
        right: $space-lg;
        border: none;
        background: none;
        transform: translateY(-50%);
        visibility: hidden;

        &:hover {
            cursor: pointer;

            .icon {
                color: cc(02, neutral);
            }
        }

        .icon {
            font-size: $text-lg;
        }

        &.js-active {
            visibility: visible;
        }
    }

    &--news {
        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: $radius-xl;
            background-color: cc(default, primary);
            color: cc(white);
            width: $space-lg;
            height: $space-lg;
            right: $space-xs;
            left: auto;
        }

        .mbt-field__input {
            border-radius: $radius-xxl;
            padding-left: $space-sm;

            &:focus {
                +.icon {
                    color: cc(white);
                }
            }
        }
    }


}