.mbt-intro {
  width: 100%;
  margin-bottom: $space-md;

  h1 {
    font-size: $text-xl;
    color: cc(light, text);
    font-weight: $bold;
    animation: fadeInUp 1s cubic-bezier(0.77, 0, 0.175, 1);

    @media #{$mq-large-up} {
      font-size: 56px;
    }

  }

  h2 {
    color: cc(light, text);
    font-size: $text-sm;
    font-weight: $semi-bold;
    // margin: 0 auto;
    animation: fadeInUp 1.5s cubic-bezier(0.77, 0, 0.175, 1);

    @media #{$mq-large-up} {
      font-size: $text-xl;
      width: 60%;
    }
  }

  &--home {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .btn {
      margin-top: $space-lg;
      animation: fadeInUp 2s cubic-bezier(0.77, 0, 0.175, 1);
    }
  }

  &--page {
    margin-bottom: 0;


    @media #{$mq-large-up} {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }


    h1 {
      color: cc(02, neutral);

      @media #{$mq-large-up} {
        font-size: $text-xxxl;
      }

    }
  }

  &__content {

    &:first-child {
      margin-bottom: $space-md;
    }

    @media #{$mq-large-up} {
      &:first-child {
        margin-bottom: 0;
      }

    }

    .btn {
      @media #{$mq-large-up} {
        margin-bottom: $space-md;
      }
    }

    .mbt-button {
      @media #{$mq-large-up} {
        margin-bottom: $space-md;
      }
    }
  }

  &__actions {
    display: flex;

    .btn {
      &:first-child {
        margin-right: $space-sm;
      }
    }

    .mbt-button {
      &:first-child {
        margin-right: $space-sm;
      }
    }
  }


  &__services {
    margin-top: $space-sm;

    @media #{$mq-medium-up} {
      margin-top: $space-lg;
    }

    p {
      color: cc(white);
      font-size: $text-md;
      font-weight: $semi-bold;
      margin-bottom: $space-sm;

      @media #{$mq-large-up} {
        font-size: $text-lg;
      }
    }

    ul {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: $space-xs;

      @media #{$mq-medium-up} {
        display: flex;
      }

    }

    li {
      // width: calc(33.33% - 4px);

      .mtb-tags {
        width: 100%;

        &__input {
          +label {
            justify-content: center;
          }
        }

      }

      @media #{$mq-medium-up} {
        width: auto;
        margin-right: $space-xs;

        &:last-child {
          margin-right: 0;
        }

        .mtb-tags {
          width: auto;

          &__input {
            +label {
              justify-content: center;
            }
          }

        }
      }
    }
  }

  &__details {

    .mbt-chip {
      margin-right: $space-xs;
    }

    li {
      display: flex;
      align-items: center;
    }
  }

}




.mtb-tags {
  display: inline-block;
  border-radius: 50px;
  position: relative;

  &__input {
    display: none;
    left: 0;
    top: 0;
    margin: 0 !important;
    padding: 0 !important;
    opacity: 0;
    height: 0;
    width: 0;
    pointer-events: none;

    +label {
      background-color: cc(05, neutral);
      padding: 6px $space-xs;
      border-radius: 50px;
      display: flex;
      align-items: center;
      color: cc(02, neutral);

      @media #{$mq-medium-up} {
        padding: $space-xs $space-sm;
      }


      &:before {
        font-family: "mybuddiestrip";
        content: $icon--plus-outline;
        margin-right: $space-xxs;
        transition: transform 0.2s;
        color: cc(03, neutral);

        @media #{$mq-medium-up} {
          margin-right: $space-xs;
        }
      }
    }

    &:checked {

      +label {
        background-color: cc(default, secondary);
        color: cc(light);

        &:before {
          content: $icon--checkmark-outline;
          color: cc(light);
          box-shadow: none;
          ;
        }
      }
    }


  }

  &__label {
    display: inline-block; // fallback
    display: flex;
    cursor: pointer;
    user-select: none;
    font-weight: $semi-bold;
    font-size: $text-xs;

    @media #{$mq-medium-up} {
      font-size: $text-sm;
    }
  }
}