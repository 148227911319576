.article {

  &:first-child {
    margin-top: 0;
  }

  &__title {
    color: cc(branding);
    font-weight: $semi-bold;
    margin-bottom: $space-xs;
  }
}
