body,
html {
  &.active-menu {
    overflow: hidden;
    height: 100vh;

    @media #{$mq-large-up} {
      height: auto;
      overflow: initial;
    }
  }

  &.hidden {
    overflow: hidden;
    overflow-y: scroll;
    height: 100vh;

    @media #{$mq-large-up} {
      height: auto;
      overflow: initial;
    }
  }
}
