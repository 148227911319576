.flight {
  flex: 1;

  &__airline {
    border-top: 1px dashed cc(medium, border);
    padding: $space-sm 0;

    &:first-child {
      border: none;
      padding-top: 0;
    }
  }

  &__date {
    color: cc(dark, text);
    font-size: $text-sm;
    display: block;
    margin-bottom: $space-sm;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media #{$mq-large-up} {
      flex-direction: row;
      width: 100%;
    }
  }

  &__company {

    @media #{$mq-large-up} {
      width: 20%;
    }
  }

  &__logo {
    width: 100px;

    @media #{$mq-large-up} {
      width: 70px;
      margin-right: $space-sm;
    }
  }

  &__hour {
    color: cc(branding);
    font-weight: $semi-bold;
    font-size: $text-lg;
    display: block;
  }

  &__company-name {
    font-size: $text-xs;
    display: block;
  }

  &__stop {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: $space-xl;

    @media #{$mq-large-up} {
      width: 80%;
    }
  }

  &__time {
    color: cc(medium, text);
    font-size: $text-xs;
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;

    @media #{$mq-large-up} {
      width: auto;
    }


    strong {
      color: cc(dark, text);
      font-size: $text-sm;
      margin: 0 $space-xs;
    }
  }

  &__img {
    margin: $space-sm 0;

    &--reverse {
      transform: rotate(180deg);
    }
  }

  &__way {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 $space-sm;
    padding-top: 14px;
    position: relative;

    @media #{$mq-large-up} {
      margin: 0 $space-md;
    }
  }

  &__stopover {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;

    &:before {
      content: '';
      width: calc(100% - 28px);
      height: 1px;
      background-color: cc(h400, dark);
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    li {
      display: block;
      position: relative;
      padding-top: 14px;
      font-size: $text-sm;
      font-weight: $regular;

      &:before {
        content: '';
        width: 22px;
        height: 18px;
        background: url("/client/public/assets/img/img-flight.svg") center center no-repeat;
        position: absolute;
        top: -9px;
        left: 50%;
        transform: translateX(-50%);
      }

      &:first-child,
      &:last-child {
        &:before {
          width: 10px;
          height: 10px;
          top: -4px;
          background-color: cc(branding);
          border-radius: 50%;
        }
      }

    }

  }

  &__jetlag {
    color: cc(warning, alert);
    font-weight: $bold;
    margin-left: $space-xs;
  }

}
