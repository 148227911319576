.partner {
    // display: flex;
    // justify-content: center;
    // align-items: center;

    &__item {
        text-align: center;

        @media #{$mq-medium-up} {
            margin-left: $space-lg;

            &:first-child {
                margin-left: 0;
            }

        }
    }

}