.counter {
  font-size: $text-xs;
  background-color: cc(alert, notification);
  display: block;
  width:  18px;
  height: 18px;
  border-radius: 100%;
  text-align: center;
  line-height: 18px;
  font-weight: $bold;

  &--docked {
    position: absolute;
    top:   -8px;
    right: -6px;
  }
}
