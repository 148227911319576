.box {
  padding: $space-lg;
  border-radius: $radius-sm;

  @media #{$mq-large-up} {
    padding: $space-xxl;
  }

  &--light {
    background-color: cc(light);
  }

  &--brand {
    background-color: cc(branding);
    color: cc(light, text);
    font-weight: $regular;
  }

  &--error {
    background-color: cc(error, alert);
    color: cc(light, text);
    font-weight: $regular;
  }

  &--dark {
    background-color: cc(h900, dark);
  }

  &--offset-r,
  &--offset-l {
    position: relative;
    z-index: $zindex-header;
  }

  &--offset-r {
    @media #{$mq-large-up} {
      left: 200px;
    }
  }

  &--offset-l {
    @media #{$mq-large-up} {
      right: 100px;
    }
  }

  &--img {
    padding: 0;
  }

  &--img-cover {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  &--overlay,
  &--overlay-airbnb {
    position: relative;
    overflow: hidden;
    z-index: 0;

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      background-color: cc(branding);
      position: absolute;
      top: 0;
      left: 0;
      z-index: $zindex-backward;
      opacity: 0.8;
    }
  }

  &--overlay-airbnb {
    &:after {
      background-color: cc(airbnb, other);
      opacity: 0.8;
    }
  }

  &--center {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &--small {
    padding: $space-md;
  }

  &--micro {
    padding: $space-xs $space-md;
  }

  &--story {
    font-size: 15px;
    p {
      margin-bottom: $space-sm;
    }
  }

  &__title {
    font-size: $text-xl;
    font-weight: $medium;
    color: cc(dark, text);
    margin-bottom: $space-sm;
    font-weight: $bold;
    display: flex;
    align-items: center;

    &--light {
      color: cc(light, text);
    }

    &--lg {
      font-size: $text-lg;
    }
  }

  &__subtitle {
    text-transform: uppercase;
    color: cc(branding);
    margin-bottom: $space-sm;
    font-weight: $regular;
    letter-spacing: 1px;
  }

  &__link {
    color: cc(light, text);
    font-size: $text-sm;
    display: flex;
    align-items: center;

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: black;
      opacity: 0;
      z-index: -1;
      transition: opacity 0.3s cb(reg);
    }

    .icon {
      fill: cc(light, text);
      margin-right: $space-xs;
      font-size: $text-lg;
      transition: margin-right 0.3s cb(reg);
    }

    &:hover {
      &:after {
        opacity: 0.6;
      }
    }
  }

  &__img {
    margin-top: $space-lg;
  }
}
