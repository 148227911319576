.toggle {

  &__switch {
    display: none;

    &:checked {
      ~ label {
        transition: background-color 500ms;
        background-color: cc(branding);

        &:after {
          left: 24px;
          transition: background-color 0.4s, left 0.2s;
          background-color: cc(light);
        }
      }
    }
  }

  &__label {
    display: inline-block;
    border-radius: 20px;
    cursor: pointer;
    width: 50px;
    height: 30px;
    position: relative;
    vertical-align: middle;
    user-select: none;
    background-color: cc(bg-medium);

    &:after {
      content: "";
      border-radius: 50%;
      width:  22px;
      height: 22px;
      position: absolute;
      top: 50%;
      left: 4px;
      transform: translateY(-50%);
      background-color: cc(branding);
      transition: all 300ms cb(reg);
    }
  }

}


