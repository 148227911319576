.intro {
  text-align: center;
  padding: 0 $space-md;

  @media #{$mq-large-up} {
    padding: 0;
  }

  &__title {
    text-transform: uppercase;
    color: cc(light, text);
    font-weight: $semi-bold;
    letter-spacing: 2px;
    animation: fadeInUp .5s cubic-bezier(0.77, 0, 0.175, 1);
    font-size: $text-xs;
    margin-bottom: $space-xs;
    line-height: $text-lg;

    @media #{$mq-large-up} {
      margin-bottom: 0;
      font-size: $text-lg;
    }
  }

  &__subtitle {
    font-size: $text-xxl;
    color: cc(light, text);
    font-weight: $bold;
    animation: fadeInUp 1s cubic-bezier(0.77, 0, 0.175, 1);

    @media #{$mq-large-up} {
      font-size: $text-xxxl;
    }

    img {
      vertical-align: middle;
      width: 80%;

      @media #{$mq-large-up} {
        width: auto;
      }
    }

  }

  &__text {
    color: cc(light, text);
    font-size: $text-sm;
    font-weight: $medium;
    margin: 0 auto;
    animation: fadeInUp 1.5s cubic-bezier(0.77, 0, 0.175, 1);

    @media #{$mq-large-up} {
      font-size: $text-lg;
      width: 60%;
    }
  }

  &__btn {
    margin-top: $space-lg;
    animation: fadeInUp 2s cubic-bezier(0.77, 0, 0.175, 1);
    display: flex;
    flex-direction: column;
    align-items: center;

    .btn {
      margin: 0 $space-xs;
    }
  }

}
