.btn {
  border-radius: $radius-xs;
  font-weight: $bold;
  display: inline-block;
  position: relative;
  z-index: 0;
  cursor: pointer;
  outline: none;
  width: fit-content;

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: $zindex-backward;
    border: 2px solid;
  }

  &--link {
    color: cc(branding);
    transition: color 500ms cb(reg);
    padding: 0;
    border: none;

    &:before {
      border: none;
    }

    &:hover {
      color: darken(cc(branding), 5%);
    }

    &-white {
      color: cc(light);
      padding: 0;
      border: none;

      &:before {
        border: none;
      }

      &:hover {
        color: darken(cc(light), 15%);
      }

    }
  }

  &--back {
    color: cc(branding);
    transition: color 500ms cb(reg);
    padding: $space-md 0 0 0;
    border: none;
    display: inline-block;
    vertical-align: middle;

    &:before {
      display: none;
    }

    .icon {
      vertical-align: middle;
      margin-right: $space-xs;
      transition: all $time-medium;
    }

    &:hover {
      color: darken(cc(branding), 5%);

      .icon {
        transform: translateX(-5px);
      }
    }
  }

  &--small {
    padding: 8px 12px;
    font-size: $text-xs;
    border-radius: $radius-xs;
  }

  &--micro {
    padding: 4px 8px;
    font-size: 12px;
  }

  &--full {
    width: 100%;
    text-align: center;
    justify-content: center;
  }

  &--primary {
    color: cc(light);
    vertical-align: middle;

    &:before {
      background-color: cc(branding);
      border-color: cc(branding);
      border-radius: $radius-xs;
      transition: all 400ms cb(bounce);
    }

    &:hover {
      &:before {
        transform: scale(1.05);
        background-color: darken(cc(branding), 5);
        border-color: darken(cc(branding), 5);
      }
    }

    &.btn--border {
      color: cc(branding);
    }

    span {
      vertical-align: middle;
    }
  }

  &--secondary {
    color: cc(dark, text);

    &:before {
      background-color: darken(cc(light), 7);
      border-radius: $radius-xs;
      transition: transform 300ms cb(bounce), background-color 500ms cb(reg);
      border-color: darken(cc(light), 7);
    }

    &:hover {
      &:before {
        transform: scale(1.05);
        background-color: darken(cc(light), 8);
        border-color: darken(cc(light), 8);
      }
    }

    &.btn--border {
      color: cc(dark, text);
    }

    span {
      vertical-align: middle;
    }
  }

  &--tertiary {
    color: cc(dark, text);

    .icon {
      font-size: $text-xl;
      color: cc(default, action);
    }

    &:before {
      background-color: darken(cc(light), 7);
      border-radius: $radius-xs;
      transition: transform 300ms cb(bounce), background-color 500ms cb(reg);
      border-color: darken(cc(light), 7);
    }

    &:hover {
      &:before {
        transform: scale(1.05);
        background-color: darken(cc(light), 8);
        border-color: darken(cc(light), 8);
      }
    }

    &.btn--border {
      color: cc(dark, text);
    }

    span {
      vertical-align: middle;
    }
  }

  &--action {
    color: cc(light);
    vertical-align: middle;

    &:before {
      background-color: cc(default, action);
      border-color: cc(default, action);
      border-radius: $radius-xs;
      transition: all 400ms cb(bounce);
    }

    &:hover {
      &:before {
        transform: scale(1.05);
        background-color: darken(cc(default, action), 5);
        border-color: darken(cc(default, action), 5);
      }
    }

    &.btn--border {
      color: cc(action);
    }

    span {
      vertical-align: middle;
    }
  }

  &--rbnb {
    &:before {
      background-color: cc(airbnb, other);
      border-color: cc(airbnb, other);
      border-radius: $radius-xs;
      transition: all 400ms cb(bounce);
    }

    &:hover {
      &:before {
        background-color: darken(cc(airbnb, other), 5);
        border-color: darken(cc(airbnb, other), 5);
      }
    }

    svg {
      fill: cc(light);
    }
  }

  &--light {
    &:before {
      background-color: cc(light);
      border-color: cc(light);
      border-radius: $radius-xs;
      transition: all $time-min;
    }

    .icon {
      color: cc(branding);
    }

    &:hover {
      color: cc(branding);

      &:before {
        background-color: darken(cc(light), 1);
        border-color: darken(cc(light), 1);
      }
    }

    &-active {

      &:hover,
      &.js-active {
        color: cc(light);

        &:before {
          background-color: cc(branding);
          border-color: cc(branding);
        }
      }
    }
  }

  &--rounded {
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: "";
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: $zindex-backward;
      transition: all 300ms cb(bounce), background-color 500ms cb(reg);
    }

    &:hover {
      &:before {
        transform: translate(-50%, -50%) scale(1.05);
      }
    }
  }

  &--border {
    &:before {
      background-color: transparent;
    }

    &:hover {
      &:before {
        background-color: transparent;
      }
    }
  }

  &--icon {

    &-right,
    &-left {
      display: flex;
      align-items: center;
    }

    &-left {
      flex-direction: row-reverse;

      .icon {
        margin-right: $space-xs;
      }
    }

    &-right {
      .icon {
        margin-left: $space-xs;
      }
    }
  }

  &--sm {
    font-size: $text-xs;
    padding: 8px 16px;

    &.btn--rounded {
      padding: 0;
      width: 30px;
      height: 30px;

      &:before {
        width: inherit;
        height: inherit;
      }

      .icon {
        font-size: $text-lg;
      }
    }
  }

  &--md {
    padding: 12px 20px;
    font-size: $text-sm;

    &.btn--rounded {
      padding: 0;
      width: 50px;
      height: 50px;

      &:before {
        width: inherit;
        height: inherit;
      }

      .icon {
        font-size: $text-xl;
      }
    }
  }

  &--lg {
    padding: 16px 24px;
    font-size: $text-lg;

    &.btn--rounded {
      padding: 0;
      width: 70px;
      height: 70px;

      &:before {
        width: inherit;
        height: inherit;
      }

      .icon {
        font-size: $text-xxl;
      }
    }
  }

  &--img {
    // background-size: co;
    display: flex;
    align-items: center;
  }

  // to check make by Mike
  &--disabled {
    pointer-events: none;
    cursor: default;

    &:hover {
      &:before {
        background-color: cc(switch-off, form);
        transform: none;
        border: none;
        opacity: 0.5;
      }
    }

    &:before {
      background-color: cc(switch-off, form);
      border: none;
      opacity: 0.5;
    }
  }

  // to here

  &__svg {
    width: 20px;
    height: 20px;
  }
}