.map {
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  display: flex;
  background-color: cc(light);
  transition: opacity .2s cb(reg);

  &__iframe {
    width: 100%;
    height: calc(100vh - 50px);
    margin-top: 50px;

    @media #{$mq-large-up} {
      width: calc(100% - 400px);
      margin-top: 0;
      height: 100%;
    }
  }

  &__sidebar {
    display: none;

    @media #{$mq-large-up} {
      display: block;
      width: 400px;
      height: 100vh;
      background-color: cc(bg);
      overflow-y: scroll;
      padding-top: $space-lg;
      position: relative;
    }
  }

  &__header {
    padding: $space-sm $space-lg;
    background-color: cc(light);
  }

  &__close {
    width: inherit;
    padding: 0 $space-lg;
    background: cc(light);
    border-bottom: 1px solid cc(light, border);
    position: fixed;
    top: 0;
    left: 0;
    z-index: $zindex-overlay-up;

    &-link {
      height: $space-lg;
      display: flex;
      align-items: center;
      font-size: $text-sm;
      color: cc(medium, text);
      transition: color .2s;

      .icon {
        font-size: $text-lg;
        margin-right: $space-xs;
        color: cc(dark, text);
      }

      &:hover {
        color: cc(dark, text);
      }
    }
  }

  &__content {
    padding: 0 $space-lg;
  }

  &__cards {
    opacity: 0;
    &.js-open {
    opacity: 1;
    }
  }

  &__title {
    @extend .content__title;
  }

  &__subtitle {
    @extend .content__subtitle;
  }

  &__sort {
    @extend .content__sort;
  }

  &__filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $space-sm 0;

    &-link {
      display: flex;
      align-items: center;
      color: cc(medium, text);
      .icon {
        color: cc(branding);
        font-size: $space-md;
        margin-right: $space-xs;
        transition: all 400ms cb(reg);
      }

      &.js-active {
        .icon {
          transform: rotate(-90deg);
        }
      }
    }
  }

  &__item {
    margin-top: $space-sm;
    &:first-child {
      margin-top: 0;
    }
  }
}
