/* external css: flickity.css */

/*! Flickity v2.2.1
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
  min-height: 250px;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  background: cc(branding);
  border: none;
  color: #333;
  transition: all $time-medium;
}

.flickity-button:hover {
  background: darken(cc(branding), 5%);
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: cc(light, text);
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  top: 50%;
  width:  35px;
  height: 35px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);

  @media #{$mq-large-up} {
    width: 44px;
    height: 44px;
  }

}

.flickity-prev-next-button.previous {
  left: -$space-md;
}

.flickity-prev-next-button.next {
  right: -$space-md;
}

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  width:  12px;
  height: 12px;

  @media #{$mq-large-up} {
    width: 40%;
    height: 40%;
  }
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -50px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: cc(branding);
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}


/* Custom
---------------------------------------------- */

.slider {

  &__wrapper {
    padding: 0 $space-sm;

    @media #{$mq-large-up} {
      padding: 0 $space-xxxl;
    }
  }
}

.carousel {
  padding: 0;
}

.carousel-cell {
  width: 100%;
  min-height: 200px;
  margin-right: 40px;
  border-radius: 5px;
  counter-increment: carousel-cell;

  @media #{$mq-medium-up} {
    width: 40%;
  }
}
