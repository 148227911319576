$container-width: 1280px;

.mbt-container {
  padding: 0;
  // position: relative;
  padding: $space-sm;


  @media #{$mq-large-up} {
    max-width: $container-width;
    margin: 0 auto;
    padding: 0;
  }

  &--content {
    padding: 30px $space-sm 80px $space-sm;

    @media #{$mq-large-up} {
      padding: 60px 0 100px 0;
    }
  }
}
