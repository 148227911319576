@tailwind base;
@tailwind components;
@tailwind utilities;

@import "00-styleguide/_reset";
@import "00-styleguide/_mixins";
@import "00-styleguide/_mediaqueries";
@import "00-styleguide/_easing";
@import "00-styleguide/_animate";
@import "00-styleguide/_colors";
@import "00-styleguide/_breakpoints";
@import "00-styleguide/_typography";
@import "00-styleguide/_grid";
@import "00-styleguide/_icons";
@import "00-styleguide/_z-index";
@import "00-styleguide/_spacing";
@import "00-styleguide/_radius";
@import "00-styleguide/_util";
@import "00-styleguide/_layout";

@import "01-components/_container";
@import "01-components/_header";
@import "01-components/_footer";
@import "01-components/_button";
@import "01-components/_intro";
@import "01-components/_box";
@import "01-components/_cards";
@import "01-components/_rating";
@import "01-components/_form";
@import "01-components/_hero";
@import "01-components/_dropdown";
@import "01-components/_counter";
@import "01-components/_sidebar";
@import "01-components/_content";
@import "01-components/_section";
@import "01-components/_flight";
@import "01-components/_subscribe";
@import "01-components/_list";
@import "01-components/_slider";
@import "01-components/_slider-range";
@import "01-components/_search-bar";
@import "01-components/_datepicker";
@import "01-components/_nav";
@import "01-components/_sort";
@import "01-components/_car";
@import "01-components/_timeline";
@import "01-components/_avatar";
@import "01-components/_modal";
@import "01-components/_alert";
@import "01-components/_article";
@import "01-components/_map";
@import "01-components/_loader";
@import "01-components/_input-number";
@import "01-components/_tooltip";
@import "01-components/_back-to-top";
@import "01-components/_empty-state";
@import "01-components/_story";
@import "01-components/_toggle";
@import "01-components/_progress-bar";
@import "01-components/_excerpt";
@import "01-components/_burger";
@import "01-components/_filter";
@import "01-components/_elmdatepicker";
@import "01-components/_separate";
@import "01-components/_trust-pilot";
@import "01-components/_dropdown";

@import "00-mbt-styleguide/_breakpoint";
@import "00-mbt-styleguide/_grid";
@import "00-mbt-styleguide/_spacing";
@import "00-mbt-styleguide/_spacing";
@import "00-mbt-styleguide/_aspect-ratio";

@import "02-mbt-components/_header";
@import "02-mbt-components/_button";
@import "02-mbt-components/_card";
@import "02-mbt-components/_carousel";
@import "02-mbt-components/_chip";
@import "02-mbt-components/_container";
@import "02-mbt-components/_footer";
@import "02-mbt-components/_form";
@import "02-mbt-components/_input-checkbox";
@import "02-mbt-components/_sections";
@import "02-mbt-components/_hero";
@import "02-mbt-components/_intro";
@import "02-mbt-components/_search-bar";
@import "02-mbt-components/_field";
@import "02-mbt-components/_date-picker";
@import "02-mbt-components/_dropdown";
@import "02-mbt-components/_burger";
@import "02-mbt-components/_partner";
@import "02-mbt-components/_filter";
@import "02-mbt-components/_checkout";
@import "02-mbt-components/_modal";
@import "02-mbt-components/_wetravel";
@import "02-mbt-components/_myswiper";
@import "02-mbt-components/_step";


@import "03-mbt-modified-components/_form";