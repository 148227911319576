// functions

// color-control
@function cc($color, $option: false, $alpha: false) {
  $opacity: null;
  $palette: null;

  @if ($alpha)  {$opacity: $alpha;}
  @else         {$opacity: if(type-of($option) == 'number', $option, false);}

  @if ($option) {$palette: if(type-of($option) == 'number', default, $option);}
  @else         {$palette: default;}

  $getPalette: map-get($colors, $palette);

  @return if($opacity, rgba(map-get($getPalette, $color), $opacity), map-get($getPalette, $color));
}

// cubic-bezier
@function cb($fn: out) {
  @return map-get($easings, $fn);
}


/* px to rem
------------------------------------------------------------------ */

$baseFontSize : 16;
@function rem($pixels, $context: $baseFontSize) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1rem;
}
