$icon--font-path: "../../public/assets/font" !default;
$icon--background-color: #eee !default;
$icon--li-margin-right: 0.4em !default;

@font-face {
  font-family: 'mybuddiestrip';
  src: url('#{$icon--font-path}/mybuddiestrip.eot');
  src: url('#{$icon--font-path}/mybuddiestrip.eot') format('embedded-opentype'),
  url('#{$icon--font-path}/mybuddiestrip.woff2') format('woff2'),
  url('#{$icon--font-path}/mybuddiestrip.woff') format('woff'),
  url('#{$icon--font-path}/mybuddiestrip.ttf') format('truetype'),
  url('#{$icon--font-path}/mybuddiestrip.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  display: inline-block;
  font: normal normal normal 1em/1 'mybuddiestrip';
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*------------------------
	icons
-------------------------*/
@function unicode($str) {
  @return unquote("\"\\#{$str}\"");
}

$icon--alert-circle-outline: unicode(ea02);
$icon--archive-outline: unicode(ea03);
$icon--alert-triangle-outline: unicode(ea04);
$icon--arrow-back-outline: unicode(ea05);
$icon--arrow-circle-left-outline: unicode(ea06);
$icon--arrow-circle-down-outline: unicode(ea07);
$icon--arrow-circle-right-outline: unicode(ea08);
$icon--arrow-circle-up-outline: unicode(ea09);
$icon--arrow-down-outline: unicode(ea0a);
$icon--arrow-downward-outline: unicode(ea0c);
$icon--arrow-forward-outline: unicode(ea0d);
$icon--arrow-ios-back-outline: unicode(ea0e);
$icon--arrow-ios-downward-outline: unicode(ea0f);
$icon--arrow-ios-forward-outline: unicode(ea10);
$icon--arrow-ios-upward-outline: unicode(ea11);
$icon--arrow-left-outline: unicode(ea12);
$icon--arrow-right-outline: unicode(ea13);
$icon--arrow-up-outline: unicode(ea14);
$icon--arrow-upward-outline: unicode(ea15);
$icon--arrowhead-down-outline: unicode(ea16);
$icon--arrowhead-left-outline: unicode(ea17);
$icon--arrowhead-right-outline: unicode(ea18);
$icon--arrowhead-up-outline: unicode(ea19);
$icon--at-outline: unicode(ea1a);
$icon--attach-2-outline: unicode(ea1b);
$icon--attach-outline: unicode(ea1c);
$icon--award-outline: unicode(ea1d);
$icon--backspace-outline: unicode(ea1e);
$icon--bar-chart-2-outline: unicode(ea1f);
$icon--bar-chart-outline: unicode(ea20);
$icon--battery-outline: unicode(ea21);
$icon--behance-outline: unicode(ea22);
$icon--bell-off-outline: unicode(ea23);
$icon--bell-outline: unicode(ea24);
$icon--bluetooth-outline: unicode(ea25);
$icon--book-open-outline: unicode(ea26);
$icon--book-outline: unicode(ea27);
$icon--bookmark-outline: unicode(ea28);
$icon--briefcase-outline: unicode(ea29);
$icon--browser-outline: unicode(ea2a);
$icon--brush-outline: unicode(ea2b);
$icon--bulb-outline: unicode(ea2c);
$icon--calendar-outline: unicode(ea2d);
$icon--calendar: unicode(ea2e);
$icon--camera-outline: unicode(ea2f);
$icon--car-outline: unicode(ea30);
$icon--car: unicode(ea31);
$icon--cast-outline: unicode(ea32);
$icon--charging-outline: unicode(ea33);
$icon--checkmark-circle-2-outline: unicode(ea34);
$icon--checkmark-circle-outline: unicode(ea35);
$icon--checkmark-outline: unicode(ea36);
$icon--checkmark-square-2-outline: unicode(ea37);
$icon--checkmark-square-outline: unicode(ea38);
$icon--chevron-down-outline: unicode(ea39);
$icon--chevron-left-outline: unicode(ea3a);
$icon--chevron-right-outline: unicode(ea3b);
$icon--chevron-up-outline: unicode(ea3c);
$icon--clipboard-outline: unicode(ea3d);
$icon--clock-outline: unicode(ea3e);
$icon--close-circle-outline: unicode(ea3f);
$icon--close-outline: unicode(ea40);
$icon--close-square-outline: unicode(ea41);
$icon--cloud-download-outline: unicode(ea42);
$icon--cloud-upload-outline: unicode(ea43);
$icon--code-download-outline: unicode(ea44);
$icon--code-outline: unicode(ea45);
$icon--collapse-outline: unicode(ea46);
$icon--color-palette-outline: unicode(ea47);
$icon--color-picker-outline: unicode(ea48);
$icon--compass-outline: unicode(ea49);
$icon--copy-outline: unicode(ea4a);
$icon--corner-down-left-outline: unicode(ea4b);
$icon--corner-down-right-outline: unicode(ea4c);
$icon--corner-left-down-outline: unicode(ea4d);
$icon--corner-left-up-outline: unicode(ea4e);
$icon--corner-right-down-outline: unicode(ea4f);
$icon--corner-right-up-outline: unicode(ea50);
$icon--corner-up-left-outline: unicode(ea51);
$icon--corner-up-right-outline: unicode(ea52);
$icon--credit-card-outline: unicode(ea53);
$icon--crop-outline: unicode(ea54);
$icon--cube-outline: unicode(ea55);
$icon--diagonal-arrow-left-down-outline: unicode(ea56);
$icon--diagonal-arrow-left-up-outline: unicode(ea57);
$icon--diagonal-arrow-right-down-outline: unicode(ea58);
$icon--diagonal-arrow-right-up-outline: unicode(ea59);
$icon--done-all-outline: unicode(ea5a);
$icon--download-outline: unicode(ea5b);
$icon--droplet-off-outline: unicode(ea5c);
$icon--droplet-outline: unicode(ea5d);
$icon--edit-2-outline: unicode(ea5e);
$icon--edit-outline: unicode(ea5f);
$icon--email-outline: unicode(ea60);
$icon--event: unicode(ea61);
$icon--expand-outline: unicode(ea62);
$icon--external-link-outline: unicode(ea63);
$icon--eye-off-2-outline: unicode(ea64);
$icon--eye-off-outline: unicode(ea65);
$icon--eye-outline: unicode(ea66);
$icon--facebook-outline: unicode(ea67);
$icon--file-add-outline: unicode(ea69);
$icon--file-outline: unicode(ea6a);
$icon--file-remove-outline: unicode(ea6b);
$icon--file-text-outline: unicode(ea6c);
$icon--film-outline: unicode(ea6d);
$icon--flag-outline: unicode(ea6e);
$icon--flash-off-outline: unicode(ea6f);
$icon--flash-outline: unicode(ea70);
$icon--flight: unicode(ea71);
$icon--flip-2-outline: unicode(ea72);
$icon--flip-outline: unicode(ea73);
$icon--folder-add-outline: unicode(ea74);
$icon--folder-outline: unicode(ea75);
$icon--funnel-outline: unicode(ea76);
$icon--folder-remove-outline: unicode(ea77);
$icon--gift-outline: unicode(ea78);
$icon--github-outline: unicode(ea79);
$icon--globe-2-outline: unicode(ea7a);
$icon--globe-outline: unicode(ea7b);
$icon--golf: unicode(ea7c);
$icon--google-outline: unicode(ea7d);
$icon--grid-outline: unicode(ea7f);
$icon--hard-drive-outline: unicode(ea80);
$icon--hash-outline: unicode(ea81);
$icon--headphones-outline: unicode(ea82);
$icon--heart-outline: unicode(ea83);
$icon--heart: unicode(ea84);
$icon--home-outline: unicode(ea85);
$icon--image-outline: unicode(ea86);
$icon--hotel: unicode(ea87);
$icon--inbox-outline: unicode(ea88);
$icon--info-outline: unicode(ea89);
$icon--keypad-outline: unicode(ea8a);
$icon--layers-outline: unicode(ea8b);
$icon--layout-outline: unicode(ea8c);
$icon--link-2-outline: unicode(ea8d);
$icon--link-outline: unicode(ea8e);
$icon--linkedin-outline: unicode(ea8f);
$icon--list-outline: unicode(ea91);
$icon--loader-outline: unicode(ea92);
$icon--lock-outline: unicode(ea93);
$icon--log-in-outline: unicode(ea94);
$icon--log-out-outline: unicode(ea95);
$icon--map-outline: unicode(ea96);
$icon--map: unicode(ea97);
$icon--maximize-outline: unicode(ea98);
$icon--menu-2-outline: unicode(ea99);
$icon--menu-arrow-outline: unicode(ea9a);
$icon--menu-outline: unicode(ea9b);
$icon--message-circle-outline: unicode(ea9c);
$icon--message-square-outline: unicode(ea9d);
$icon--mic-off-outline: unicode(ea9e);
$icon--mic-outline: unicode(ea9f);
$icon--minimize-outline: unicode(eaa0);
$icon--minus-circle-outline: unicode(eaa1);
$icon--minus-outline: unicode(eaa2);
$icon--minus-square-outline: unicode(eaa3);
$icon--monitor-outline: unicode(eaa4);
$icon--moon-outline: unicode(eaa5);
$icon--more-horizontal-outline: unicode(eaa6);
$icon--more-vertical-outline: unicode(eaa7);
$icon--move-outline: unicode(eaa8);
$icon--music-outline: unicode(eaa9);
$icon--navigation-2-outline: unicode(eaaa);
$icon--navigation-outline: unicode(eaab);
$icon--options-2-outline: unicode(eaac);
$icon--npm-outline: unicode(eaad);
$icon--options-outline: unicode(eaae);
$icon--package: unicode(eaaf);
$icon--pantone-outline: unicode(eab0);
$icon--paper-plane-outline: unicode(eab1);
$icon--pause-circle-outline: unicode(eab2);
$icon--people-outline: unicode(eab3);
$icon--percent-outline: unicode(eab4);
$icon--person-add-outline: unicode(eab5);
$icon--person-delete-outline: unicode(eab6);
$icon--person-done-outline: unicode(eab7);
$icon--person-outline: unicode(eab8);
$icon--person-remove-outline: unicode(eab9);
$icon--phone-call-outline: unicode(eaba);
$icon--phone-missed-outline: unicode(eabb);
$icon--phone-off-outline: unicode(eabc);
$icon--phone-outline: unicode(eabd);
$icon--pie-chart-outline: unicode(eabe);
$icon--pin-outline: unicode(eabf);
$icon--play-circle-outline: unicode(eac0);
$icon--plus-circle-outline: unicode(eac1);
$icon--plus-outline: unicode(eac2);
$icon--plus-square-outline: unicode(eac3);
$icon--power-outline: unicode(eac4);
$icon--pricetags-outline: unicode(eac5);
$icon--printer-outline: unicode(eac6);
$icon--question-mark-circle-outline: unicode(eac7);
$icon--question-mark-outline: unicode(eac8);
$icon--radio-button-off-outline: unicode(eac9);
$icon--radio-button-on-outline: unicode(eaca);
$icon--radio-outline: unicode(eacb);
$icon--recording-outline: unicode(eacc);
$icon--refresh-outline: unicode(eacd);
$icon--repeat-outline: unicode(eace);
$icon--restaurant: unicode(eacf);
$icon--rewind-left-outline: unicode(ead0);
$icon--rewind-right-outline: unicode(ead1);
$icon--scissors-outline: unicode(ead2);
$icon--save-outline: unicode(ead3);
$icon--search-outline: unicode(ead4);
$icon--settings-outline: unicode(ead5);
$icon--settings-2-outline: unicode(ead6);
$icon--shake-outline: unicode(ead7);
$icon--share-outline: unicode(ead8);
$icon--shield-off-outline: unicode(ead9);
$icon--shield-outline: unicode(eada);
$icon--shopping-bag-outline: unicode(eadb);
$icon--shopping-cart-outline: unicode(eadc);
$icon--shuffle-2-outline: unicode(eadd);
$icon--shuffle-outline: unicode(eade);
$icon--skip-back-outline: unicode(eadf);
$icon--skip-forward-outline: unicode(eae0);
$icon--slash-outline: unicode(eae1);
$icon--smartphone-outline: unicode(eae2);
$icon--speaker-outline: unicode(eae3);
$icon--square-outline: unicode(eae4);
$icon--star-outline: unicode(eae5);
$icon--star: unicode(eae6);
$icon--stop-circle-outline: unicode(eae7);
$icon--sun-outline: unicode(eae8);
$icon--swap-outline: unicode(eae9);
$icon--sync-outline: unicode(eaea);
$icon--text-outline: unicode(eaeb);
$icon--thermometer-minus-outline: unicode(eaec);
$icon--thermometer-outline: unicode(eaed);
$icon--thermometer-plus-outline: unicode(eaee);
$icon--toggle-left-outline: unicode(eaef);
$icon--toggle-right-outline: unicode(eaf0);
$icon--trash-outline: unicode(eaf1);
$icon--trash-2-outline: unicode(eaf2);
$icon--trending-down-outline: unicode(eaf3);
$icon--tv-outline: unicode(eaf4);
$icon--trending-up-outline: unicode(eaf5);
$icon--twitter-outline: unicode(eaf6);
$icon--umbrella-outline: unicode(eaf8);
$icon--undo-outline: unicode(eaf9);
$icon--unlock-outline: unicode(eafa);
$icon--upload-outline: unicode(eafb);
$icon--video-off-outline: unicode(eafc);
$icon--video-outline: unicode(eafd);
$icon--volume-down-outline: unicode(eafe);
$icon--volume-mute-outline: unicode(eaff);
$icon--volume-off-outline: unicode(eb00);
$icon--volume-up-outline: unicode(eb01);
$icon--wifi-off-outline: unicode(eb02);
$icon--wifi-outline: unicode(eb03);
$icon--archery-target: unicode(eb04);
$icon--flight-connection: unicode(eb05);
$icon--currency-exchange-2: unicode(eb06);
$icon--ungroup: unicode(eb07);
$icon--logo-twitter: unicode(eb09);
$icon--logo-fb-simple: unicode(eb0a);
$icon--logo-linkedin: unicode(eb0b);
$icon--seatbelt: unicode(eb0c);
$icon--car-door: unicode(eb0d);
$icon--snow: unicode(eb0e);
$icon--engine: unicode(eb0f);
$icon--logo-instagram: unicode(eb10);
$icon--activity-outline: unicode(ea01);

.icon--alert-circle-outline::before {
  content: $icon--alert-circle-outline;
}

.icon--archive-outline::before {
  content: $icon--archive-outline;
}

.icon--alert-triangle-outline::before {
  content: $icon--alert-triangle-outline;
}

.icon--arrow-back-outline::before {
  content: $icon--arrow-back-outline;
}

.icon--arrow-circle-left-outline::before {
  content: $icon--arrow-circle-left-outline;
}

.icon--arrow-circle-down-outline::before {
  content: $icon--arrow-circle-down-outline;
}

.icon--arrow-circle-right-outline::before {
  content: $icon--arrow-circle-right-outline;
}

.icon--arrow-circle-up-outline::before {
  content: $icon--arrow-circle-up-outline;
}

.icon--arrow-down-outline::before {
  content: $icon--arrow-down-outline;
}

.icon--arrow-downward-outline::before {
  content: $icon--arrow-downward-outline;
}

.icon--arrow-forward-outline::before {
  content: $icon--arrow-forward-outline;
}

.icon--arrow-ios-back-outline::before {
  content: $icon--arrow-ios-back-outline;
}

.icon--arrow-ios-downward-outline::before {
  content: $icon--arrow-ios-downward-outline;
}

.icon--arrow-ios-forward-outline::before {
  content: $icon--arrow-ios-forward-outline;
}

.icon--arrow-ios-upward-outline::before {
  content: $icon--arrow-ios-upward-outline;
}

.icon--arrow-left-outline::before {
  content: $icon--arrow-left-outline;
}

.icon--arrow-right-outline::before {
  content: $icon--arrow-right-outline;
}

.icon--arrow-up-outline::before {
  content: $icon--arrow-up-outline;
}

.icon--arrow-upward-outline::before {
  content: $icon--arrow-upward-outline;
}

.icon--arrowhead-down-outline::before {
  content: $icon--arrowhead-down-outline;
}

.icon--arrowhead-left-outline::before {
  content: $icon--arrowhead-left-outline;
}

.icon--arrowhead-right-outline::before {
  content: $icon--arrowhead-right-outline;
}

.icon--arrowhead-up-outline::before {
  content: $icon--arrowhead-up-outline;
}

.icon--at-outline::before {
  content: $icon--at-outline;
}

.icon--attach-2-outline::before {
  content: $icon--attach-2-outline;
}

.icon--attach-outline::before {
  content: $icon--attach-outline;
}

.icon--award-outline::before {
  content: $icon--award-outline;
}

.icon--backspace-outline::before {
  content: $icon--backspace-outline;
}

.icon--bar-chart-2-outline::before {
  content: $icon--bar-chart-2-outline;
}

.icon--bar-chart-outline::before {
  content: $icon--bar-chart-outline;
}

.icon--battery-outline::before {
  content: $icon--battery-outline;
}

.icon--behance-outline::before {
  content: $icon--behance-outline;
}

.icon--bell-off-outline::before {
  content: $icon--bell-off-outline;
}

.icon--bell-outline::before {
  content: $icon--bell-outline;
}

.icon--bluetooth-outline::before {
  content: $icon--bluetooth-outline;
}

.icon--book-open-outline::before {
  content: $icon--book-open-outline;
}

.icon--book-outline::before {
  content: $icon--book-outline;
}

.icon--bookmark-outline::before {
  content: $icon--bookmark-outline;
}

.icon--briefcase-outline::before {
  content: $icon--briefcase-outline;
}

.icon--browser-outline::before {
  content: $icon--browser-outline;
}

.icon--brush-outline::before {
  content: $icon--brush-outline;
}

.icon--bulb-outline::before {
  content: $icon--bulb-outline;
}

.icon--calendar-outline::before {
  content: $icon--calendar-outline;
}

.icon--calendar::before {
  content: $icon--calendar;
}

.icon--camera-outline::before {
  content: $icon--camera-outline;
}

.icon--car-outline::before {
  content: $icon--car-outline;
}

.icon--car::before {
  content: $icon--car;
}

.icon--cast-outline::before {
  content: $icon--cast-outline;
}

.icon--charging-outline::before {
  content: $icon--charging-outline;
}

.icon--checkmark-circle-2-outline::before {
  content: $icon--checkmark-circle-2-outline;
}

.icon--checkmark-circle-outline::before {
  content: $icon--checkmark-circle-outline;
}

.icon--checkmark-outline::before {
  content: $icon--checkmark-outline;
}

.icon--checkmark-square-2-outline::before {
  content: $icon--checkmark-square-2-outline;
}

.icon--checkmark-square-outline::before {
  content: $icon--checkmark-square-outline;
}

.icon--chevron-down-outline::before {
  content: $icon--chevron-down-outline;
}

.icon--chevron-left-outline::before {
  content: $icon--chevron-left-outline;
}

.icon--chevron-right-outline::before {
  content: $icon--chevron-right-outline;
}

.icon--chevron-up-outline::before {
  content: $icon--chevron-up-outline;
}

.icon--clipboard-outline::before {
  content: $icon--clipboard-outline;
}

.icon--clock-outline::before {
  content: $icon--clock-outline;
}

.icon--close-circle-outline::before {
  content: $icon--close-circle-outline;
}

.icon--close-outline::before {
  content: $icon--close-outline;
}

.icon--close-square-outline::before {
  content: $icon--close-square-outline;
}

.icon--cloud-download-outline::before {
  content: $icon--cloud-download-outline;
}

.icon--cloud-upload-outline::before {
  content: $icon--cloud-upload-outline;
}

.icon--code-download-outline::before {
  content: $icon--code-download-outline;
}

.icon--code-outline::before {
  content: $icon--code-outline;
}

.icon--collapse-outline::before {
  content: $icon--collapse-outline;
}

.icon--color-palette-outline::before {
  content: $icon--color-palette-outline;
}

.icon--color-picker-outline::before {
  content: $icon--color-picker-outline;
}

.icon--compass-outline::before {
  content: $icon--compass-outline;
}

.icon--copy-outline::before {
  content: $icon--copy-outline;
}

.icon--corner-down-left-outline::before {
  content: $icon--corner-down-left-outline;
}

.icon--corner-down-right-outline::before {
  content: $icon--corner-down-right-outline;
}

.icon--corner-left-down-outline::before {
  content: $icon--corner-left-down-outline;
}

.icon--corner-left-up-outline::before {
  content: $icon--corner-left-up-outline;
}

.icon--corner-right-down-outline::before {
  content: $icon--corner-right-down-outline;
}

.icon--corner-right-up-outline::before {
  content: $icon--corner-right-up-outline;
}

.icon--corner-up-left-outline::before {
  content: $icon--corner-up-left-outline;
}

.icon--corner-up-right-outline::before {
  content: $icon--corner-up-right-outline;
}

.icon--credit-card-outline::before {
  content: $icon--credit-card-outline;
}

.icon--crop-outline::before {
  content: $icon--crop-outline;
}

.icon--cube-outline::before {
  content: $icon--cube-outline;
}

.icon--diagonal-arrow-left-down-outline::before {
  content: $icon--diagonal-arrow-left-down-outline;
}

.icon--diagonal-arrow-left-up-outline::before {
  content: $icon--diagonal-arrow-left-up-outline;
}

.icon--diagonal-arrow-right-down-outline::before {
  content: $icon--diagonal-arrow-right-down-outline;
}

.icon--diagonal-arrow-right-up-outline::before {
  content: $icon--diagonal-arrow-right-up-outline;
}

.icon--done-all-outline::before {
  content: $icon--done-all-outline;
}

.icon--download-outline::before {
  content: $icon--download-outline;
}

.icon--droplet-off-outline::before {
  content: $icon--droplet-off-outline;
}

.icon--droplet-outline::before {
  content: $icon--droplet-outline;
}

.icon--edit-2-outline::before {
  content: $icon--edit-2-outline;
}

.icon--edit-outline::before {
  content: $icon--edit-outline;
}

.icon--email-outline::before {
  content: $icon--email-outline;
}

.icon--event::before {
  content: $icon--event;
}

.icon--expand-outline::before {
  content: $icon--expand-outline;
}

.icon--external-link-outline::before {
  content: $icon--external-link-outline;
}

.icon--eye-off-2-outline::before {
  content: $icon--eye-off-2-outline;
}

.icon--eye-off-outline::before {
  content: $icon--eye-off-outline;
}

.icon--eye-outline::before {
  content: $icon--eye-outline;
}

.icon--facebook-outline::before {
  content: $icon--facebook-outline;
}

.icon--file-add-outline::before {
  content: $icon--file-add-outline;
}

.icon--file-outline::before {
  content: $icon--file-outline;
}

.icon--file-remove-outline::before {
  content: $icon--file-remove-outline;
}

.icon--file-text-outline::before {
  content: $icon--file-text-outline;
}

.icon--film-outline::before {
  content: $icon--film-outline;
}

.icon--flag-outline::before {
  content: $icon--flag-outline;
}

.icon--flash-off-outline::before {
  content: $icon--flash-off-outline;
}

.icon--flash-outline::before {
  content: $icon--flash-outline;
}

.icon--flight::before {
  content: $icon--flight;
}

.icon--flip-2-outline::before {
  content: $icon--flip-2-outline;
}

.icon--flip-outline::before {
  content: $icon--flip-outline;
}

.icon--folder-add-outline::before {
  content: $icon--folder-add-outline;
}

.icon--folder-outline::before {
  content: $icon--folder-outline;
}

.icon--funnel-outline::before {
  content: $icon--funnel-outline;
}

.icon--folder-remove-outline::before {
  content: $icon--folder-remove-outline;
}

.icon--gift-outline::before {
  content: $icon--gift-outline;
}

.icon--github-outline::before {
  content: $icon--github-outline;
}

.icon--globe-2-outline::before {
  content: $icon--globe-2-outline;
}

.icon--globe-outline::before {
  content: $icon--globe-outline;
}

.icon--golf::before {
  content: $icon--golf;
}

.icon--google-outline::before {
  content: $icon--google-outline;
}

.icon--grid-outline::before {
  content: $icon--grid-outline;
}

.icon--hard-drive-outline::before {
  content: $icon--hard-drive-outline;
}

.icon--hash-outline::before {
  content: $icon--hash-outline;
}

.icon--headphones-outline::before {
  content: $icon--headphones-outline;
}

.icon--heart-outline::before {
  content: $icon--heart-outline;
}

.icon--heart::before {
  content: $icon--heart;
}

.icon--home-outline::before {
  content: $icon--home-outline;
}

.icon--image-outline::before {
  content: $icon--image-outline;
}

.icon--hotel::before {
  content: $icon--hotel;
}

.icon--inbox-outline::before {
  content: $icon--inbox-outline;
}

.icon--info-outline::before {
  content: $icon--info-outline;
}

.icon--keypad-outline::before {
  content: $icon--keypad-outline;
}

.icon--layers-outline::before {
  content: $icon--layers-outline;
}

.icon--layout-outline::before {
  content: $icon--layout-outline;
}

.icon--link-2-outline::before {
  content: $icon--link-2-outline;
}

.icon--link-outline::before {
  content: $icon--link-outline;
}

.icon--linkedin-outline::before {
  content: $icon--linkedin-outline;
}

.icon--list-outline::before {
  content: $icon--list-outline;
}

.icon--loader-outline::before {
  content: $icon--loader-outline;
}

.icon--lock-outline::before {
  content: $icon--lock-outline;
}

.icon--log-in-outline::before {
  content: $icon--log-in-outline;
}

.icon--log-out-outline::before {
  content: $icon--log-out-outline;
}

.icon--map-outline::before {
  content: $icon--map-outline;
}

.icon--map::before {
  content: $icon--map;
}

.icon--maximize-outline::before {
  content: $icon--maximize-outline;
}

.icon--menu-2-outline::before {
  content: $icon--menu-2-outline;
}

.icon--menu-arrow-outline::before {
  content: $icon--menu-arrow-outline;
}

.icon--menu-outline::before {
  content: $icon--menu-outline;
}

.icon--message-circle-outline::before {
  content: $icon--message-circle-outline;
}

.icon--message-square-outline::before {
  content: $icon--message-square-outline;
}

.icon--mic-off-outline::before {
  content: $icon--mic-off-outline;
}

.icon--mic-outline::before {
  content: $icon--mic-outline;
}

.icon--minimize-outline::before {
  content: $icon--minimize-outline;
}

.icon--minus-circle-outline::before {
  content: $icon--minus-circle-outline;
}

.icon--minus-outline::before {
  content: $icon--minus-outline;
}

.icon--minus-square-outline::before {
  content: $icon--minus-square-outline;
}

.icon--monitor-outline::before {
  content: $icon--monitor-outline;
}

.icon--moon-outline::before {
  content: $icon--moon-outline;
}

.icon--more-horizontal-outline::before {
  content: $icon--more-horizontal-outline;
}

.icon--more-vertical-outline::before {
  content: $icon--more-vertical-outline;
}

.icon--move-outline::before {
  content: $icon--move-outline;
}

.icon--music-outline::before {
  content: $icon--music-outline;
}

.icon--navigation-2-outline::before {
  content: $icon--navigation-2-outline;
}

.icon--navigation-outline::before {
  content: $icon--navigation-outline;
}

.icon--options-2-outline::before {
  content: $icon--options-2-outline;
}

.icon--npm-outline::before {
  content: $icon--npm-outline;
}

.icon--options-outline::before {
  content: $icon--options-outline;
}

.icon--package::before {
  content: $icon--package;
}

.icon--pantone-outline::before {
  content: $icon--pantone-outline;
}

.icon--paper-plane-outline::before {
  content: $icon--paper-plane-outline;
}

.icon--pause-circle-outline::before {
  content: $icon--pause-circle-outline;
}

.icon--people-outline::before {
  content: $icon--people-outline;
}

.icon--percent-outline::before {
  content: $icon--percent-outline;
}

.icon--person-add-outline::before {
  content: $icon--person-add-outline;
}

.icon--person-delete-outline::before {
  content: $icon--person-delete-outline;
}

.icon--person-done-outline::before {
  content: $icon--person-done-outline;
}

.icon--person-outline::before {
  content: $icon--person-outline;
}

.icon--person-remove-outline::before {
  content: $icon--person-remove-outline;
}

.icon--phone-call-outline::before {
  content: $icon--phone-call-outline;
}

.icon--phone-missed-outline::before {
  content: $icon--phone-missed-outline;
}

.icon--phone-off-outline::before {
  content: $icon--phone-off-outline;
}

.icon--phone-outline::before {
  content: $icon--phone-outline;
}

.icon--pie-chart-outline::before {
  content: $icon--pie-chart-outline;
}

.icon--pin-outline::before {
  content: $icon--pin-outline;
}

.icon--play-circle-outline::before {
  content: $icon--play-circle-outline;
}

.icon--plus-circle-outline::before {
  content: $icon--plus-circle-outline;
}

.icon--plus-outline::before {
  content: $icon--plus-outline;
}

.icon--plus-square-outline::before {
  content: $icon--plus-square-outline;
}

.icon--power-outline::before {
  content: $icon--power-outline;
}

.icon--pricetags-outline::before {
  content: $icon--pricetags-outline;
}

.icon--printer-outline::before {
  content: $icon--printer-outline;
}

.icon--question-mark-circle-outline::before {
  content: $icon--question-mark-circle-outline;
}

.icon--question-mark-outline::before {
  content: $icon--question-mark-outline;
}

.icon--radio-button-off-outline::before {
  content: $icon--radio-button-off-outline;
}

.icon--radio-button-on-outline::before {
  content: $icon--radio-button-on-outline;
}

.icon--radio-outline::before {
  content: $icon--radio-outline;
}

.icon--recording-outline::before {
  content: $icon--recording-outline;
}

.icon--refresh-outline::before {
  content: $icon--refresh-outline;
}

.icon--repeat-outline::before {
  content: $icon--repeat-outline;
}

.icon--restaurant::before {
  content: $icon--restaurant;
}

.icon--rewind-left-outline::before {
  content: $icon--rewind-left-outline;
}

.icon--rewind-right-outline::before {
  content: $icon--rewind-right-outline;
}

.icon--scissors-outline::before {
  content: $icon--scissors-outline;
}

.icon--save-outline::before {
  content: $icon--save-outline;
}

.icon--search-outline::before {
  content: $icon--search-outline;
}

.icon--settings-outline::before {
  content: $icon--settings-outline;
}

.icon--settings-2-outline::before {
  content: $icon--settings-2-outline;
}

.icon--shake-outline::before {
  content: $icon--shake-outline;
}

.icon--share-outline::before {
  content: $icon--share-outline;
}

.icon--shield-off-outline::before {
  content: $icon--shield-off-outline;
}

.icon--shield-outline::before {
  content: $icon--shield-outline;
}

.icon--shopping-bag-outline::before {
  content: $icon--shopping-bag-outline;
}

.icon--shopping-cart-outline::before {
  content: $icon--shopping-cart-outline;
}

.icon--shuffle-2-outline::before {
  content: $icon--shuffle-2-outline;
}

.icon--shuffle-outline::before {
  content: $icon--shuffle-outline;
}

.icon--skip-back-outline::before {
  content: $icon--skip-back-outline;
}

.icon--skip-forward-outline::before {
  content: $icon--skip-forward-outline;
}

.icon--slash-outline::before {
  content: $icon--slash-outline;
}

.icon--smartphone-outline::before {
  content: $icon--smartphone-outline;
}

.icon--speaker-outline::before {
  content: $icon--speaker-outline;
}

.icon--square-outline::before {
  content: $icon--square-outline;
}

.icon--star-outline::before {
  content: $icon--star-outline;
}

.icon--star::before {
  content: $icon--star;
}

.icon--stop-circle-outline::before {
  content: $icon--stop-circle-outline;
}

.icon--sun-outline::before {
  content: $icon--sun-outline;
}

.icon--swap-outline::before {
  content: $icon--swap-outline;
}

.icon--sync-outline::before {
  content: $icon--sync-outline;
}

.icon--text-outline::before {
  content: $icon--text-outline;
}

.icon--thermometer-minus-outline::before {
  content: $icon--thermometer-minus-outline;
}

.icon--thermometer-outline::before {
  content: $icon--thermometer-outline;
}

.icon--thermometer-plus-outline::before {
  content: $icon--thermometer-plus-outline;
}

.icon--toggle-left-outline::before {
  content: $icon--toggle-left-outline;
}

.icon--toggle-right-outline::before {
  content: $icon--toggle-right-outline;
}

.icon--trash-outline::before {
  content: $icon--trash-outline;
}

.icon--trash-2-outline::before {
  content: $icon--trash-2-outline;
}

.icon--trending-down-outline::before {
  content: $icon--trending-down-outline;
}

.icon--tv-outline::before {
  content: $icon--tv-outline;
}

.icon--trending-up-outline::before {
  content: $icon--trending-up-outline;
}

.icon--twitter-outline::before {
  content: $icon--twitter-outline;
}

.icon--umbrella-outline::before {
  content: $icon--umbrella-outline;
}

.icon--undo-outline::before {
  content: $icon--undo-outline;
}

.icon--unlock-outline::before {
  content: $icon--unlock-outline;
}

.icon--upload-outline::before {
  content: $icon--upload-outline;
}

.icon--video-off-outline::before {
  content: $icon--video-off-outline;
}

.icon--video-outline::before {
  content: $icon--video-outline;
}

.icon--volume-down-outline::before {
  content: $icon--volume-down-outline;
}

.icon--volume-mute-outline::before {
  content: $icon--volume-mute-outline;
}

.icon--volume-off-outline::before {
  content: $icon--volume-off-outline;
}

.icon--volume-up-outline::before {
  content: $icon--volume-up-outline;
}

.icon--wifi-off-outline::before {
  content: $icon--wifi-off-outline;
}

.icon--wifi-outline::before {
  content: $icon--wifi-outline;
}

.icon--archery-target::before {
  content: $icon--archery-target;
}

.icon--flight-connection::before {
  content: $icon--flight-connection;
}

.icon--currency-exchange-2::before {
  content: $icon--currency-exchange-2;
}

.icon--ungroup::before {
  content: $icon--ungroup;
}

.icon--logo-twitter::before {
  content: $icon--logo-twitter;
}

.icon--logo-fb-simple::before {
  content: $icon--logo-fb-simple;
}

.icon--logo-linkedin::before {
  content: $icon--logo-linkedin;
}

.icon--seatbelt::before {
  content: $icon--seatbelt;
}

.icon--car-door::before {
  content: $icon--car-door;
}

.icon--snow::before {
  content: $icon--snow;
}

.icon--engine::before {
  content: $icon--engine;
}

.icon--logo-instagram::before {
  content: $icon--logo-instagram;
}

.icon--activity-outline::before {
  content: $icon--activity-outline;
}
