.nav {
  &__content {
    display: flex;
    align-items: center;
    max-width: $container-width;
    width: 100%;
    height: inherit;
  }

  &__item {
    margin-left: $space-xxxl;
    height: inherit;

    &:first-child {
      margin-left: 0;
    }

    &--date {
      // margin: 0 $space-sm;
    }
  }

  &__link {
    color: cc(medium, text);
    display: inline-block;
    font-size: $text-sm;
    position: relative;
    margin: 0 $space-xl;
    cursor: pointer;
    height: inherit;

    &:after {
      content: "";
      opacity: 0;
      width: 100%;
      height: 2px;
      background-color: cc(branding);
      position: absolute;
      left: 0;
      bottom: -5px;
      transform: translateY(-10px);
      transition: all 500ms cb(reg);
    }

    &:hover,
    &.js-active {
      color: cc(branding);

      &:after {
        content: "";
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  &--sort {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: $space-md;

    .nav__link {
      font-size: $text-md;
    }
  }

  &--setting {
    background-color: cc(light);
    height: 80px;
    display: flex;
    padding: 0 $space-sm;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: none;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    &::-webkit-scrollbar {
      display: none;
    }

    @media #{$mq-large-up} {
      justify-content: center;
    }

    .nav__item {
      margin-left: $space-lg;
      &:first-child {
        margin-left: 0;
      }
    }

    .nav__link {
      margin: 0;
      display: flex;
      align-items: center;
      a &:hover,
      &.js-active {
        &:after {
          content: "";
          opacity: 1;
          transform: translateY(0);
          bottom: 0;
        }
      }
    }

    .icon {
      font-size: $text-lg;
      margin-right: $space-xs;
    }
  }
}
