.mySwiper {
  width: 100%;
  height: 100%;
}

.swiper-pagination-bullet {
  width: 14px;
  height: 14px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
}

.swiper-pagination-bullet-active {
  color: #fff;
  background: cc(branding);
}