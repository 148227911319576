.custom-checkbox {
  position: relative;
  z-index: 1;
  display: inline-block;
  font-size: 20px;

  &--icon {
    width: 30px;
    height: 30px;

    .icon {
      display: block;
      text-align: center;
      line-height: 30px;
      position: relative;
      z-index: 1;
    }
  }

  &__input {
    position: relative;
    cursor: pointer;
    // hide input
    margin: 0;
    padding: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    height: 100%;
    width: 100%;
    display: block;
    z-index: 1;

    &:active {
      ~.custom-checkbox__control {
        transform: scale(0.9);
      }
    }

    &:checked {
      ~.custom-checkbox__control {
        .icon {
          color: cc(branding);
        }
      }
    }
  }

  &__control {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    pointer-events: none;
    transition: transform 0.2s cb(bounce);
    color: cc(h500, dark);

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      background-color: cc(light);
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.form {
  &--profil {
    .form__item {
      border: none;
      padding: $space-sm 0;

      &:first-child {
        padding: $space-sm 0;
      }

      &:last-child {
        padding: $space-sm 0;
        padding-bottom: $space-sm;
      }
    }
  }

  &__item {
    padding: $space-md 0;
    border-top: 1px solid cc(medium, border, 0.5);

    &:first-child {
      margin-top: 0;
      border: none;
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &--checkbox {
      margin-top: $space-md;
    }

    &--title {
      border: none;
    }
  }

  &__label,
  &__title {
    color: cc(dark, text);
    font-weight: $semi-bold;
    font-size: $text-sm;
  }

  &__title {
    width: 100%;

    &.js-active {
      .icon {
        transform: scaleY(-1);
      }
    }

    .icon {
      font-size: $text-xl;
      color: cc(branding);
      transform: scaleY(1);
      transition: all 0.3s cb(reg);
    }

    &--dropdown {
      cursor: pointer;
    }

    &--split {
      color: cc(branding);
      font-weight: $semi-bold;
      font-size: $text-lg;
      display: flex;
      align-items: center;

      .icon {
        margin-right: $space-sm;
      }
    }
  }

  &__result {
    color: cc(medium, text);
    font-weight: $semi-bold;
    font-size: $text-sm;
    display: block;
  }

  &__group {
    width: 100%;
    height: 0;
    visibility: hidden;
    background-color: cc(light);
    position: relative;
    top: 0;
    left: 0;
    overflow: auto;
    transition: all 300ms cb(reg);
    transform-origin: 50% 0;
    z-index: $zindex-popover;
    opacity: 0;
    transform: scale3d(1, 0, 0);

    &.js-open {
      max-height: auto;
      height: auto;
      visibility: visible;
      opacity: 1;
      transform: scale3d(1, 1, 1);
      padding: $space-sm 0;
    }
  }

  &__list {
    margin-top: $space-sm;

    &:first-child {
      margin-top: 0;
    }

    label {
      @extend .form__result;
    }
  }

  &__mention {
    font-size: $text-xs;
    line-height: 1.5;

    &--login {
      display: block;
      width: 100%;
      text-align: center;
      margin-top: $space-sm;
      display: block;

      a {
        font-weight: $bold;
        color: cc(branding);
      }

      .checkbox {
        display: flex;
        justify-content: center;
      }
    }

    &--message {
      display: block;
      margin-top: $space-xs;

      a {
        font-weight: $bold;
        color: cc(branding);
      }
    }
  }
}

.input {
  position: relative;
  //z-index: $zindex-frontward;
  width: 100%;
  height: 60px;
  //margin-top: 10px;
  overflow: hidden;
  padding-top: 10px;

  &--btn-right {
    text-align: right;
    margin-top: 50px;
    padding-top: 0;
  }

  &--btn-centered {
    text-align: center;
    margin-top: 50px;
    padding-top: 0;
  }

  &__field {
    position: relative;
    width: 100%;
    height: 100%;
    border: none;
    border-bottom: 2px solid cc(medium, border);
    color: cc(dark, text);
    font-weight: $semi-bold;
    -webkit-appearance: none;
    /* for box shadows to show on iOS */
    border-radius: 0;
    padding: 0;
    background: transparent;
    //font-size: $size-regular;
    transition: all $time-half cb(reg);
    resize: none;

    &:focus,
    &.not-empty {
      outline: none;
      border-color: cc(branding);

      +.input__label {
        color: cc(branding);
        transform: translate3d(0, -20px, 0) scale3d(0.9, 0.9, 1);
      }
    }
  }

  &__label {
    user-select: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 15px;
    font-size: 14px;
    left: 0;
    display: block;
    width: 100%;
    color: cc(medium, text);
    pointer-events: none;
    transform-origin: 0 0;
    transition: all $time-half cb(reg);
    font-weight: $medium;

    &-content {
      display: block;
      padding: 8px 0;
      width: 100%;
    }
  }

  .disabled {
    opacity: 0.3;
    cursor: not-allowed;

    &+.input__label {
      opacity: 0.6;
    }
  }
}

.select {
  width: 100%;
  position: relative;

  &--dropdown {
    height: auto;

    &:before {
      display: none;
    }

    .select__field {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .icon {
      color: cc(branding);
      font-size: 30px;
    }
  }

  &__field {
    width: inherit;
    position: relative;
    border: 2px solid cc(medium, border, 0.5);
    color: cc(dark, text);
    font-weight: $semi-bold;
    -webkit-appearance: none;
    /* for box shadows to show on iOS */
    border-radius: $radius-xs;
    padding: $space-xs;
    background: transparent;
    font-size: $text-sm;
    height: 45px;
    resize: none;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &:before {
    font-family: "mybuddiestrip";
    content: $icon--chevron-down-outline;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    color: cc(branding);
    font-size: 30px;
  }

  &__dropdown {
    padding: $space-sm;
    width: 100%;
    height: 0;
    visibility: hidden;
    background-color: cc(light);
    position: absolute;
    top: 50px;
    left: 0;
    overflow: hidden;
    overflow-y: scroll;
    transition: all 500ms cb(reg);
    transform: scaleY(0);
    transform-origin: 50% 0;
    z-index: $zindex-popover;
    border: 1px solid cc(light, border);

    &.js-open {
      max-height: 250px;
      height: auto;
      visibility: visible;
      transform: scaleY(1);
    }

    &--push {
      position: relative;
      padding: 0 $space-sm;
      top: 0;
      overflow: auto;

      &.js-open {
        padding: $space-sm;
        max-height: auto;
        height: auto;
        visibility: visible;
        transform: scaleY(1);
      }
    }

    &-item {
      display: flex;
      align-items: center;
      padding: $space-sm 0;
      border-top: 1px solid cc(light, border);
      flex-wrap: wrap;

      &:first-child {
        border: none;
      }

      .icon {
        font-size: $text-lg;
      }
    }
  }
}

.search {
  position: relative;

  &__label {
    padding: 2px $space-xs 0 $space-xs;
    color: cc(branding);
    font-size: $text-md;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    z-index: $zindex-overlay;
  }

  &__field {
    flex: 1;
    border: none;
    background-color: cc(h400, dark, 0.5);
    padding: $space-xs $space-xs $space-xs $space-lg;
    outline: none;
    position: relative;
    font-size: $text-sm;
    width: 100%;
    border-radius: 50px;
    transition: background-color 500ms cb(reg);
    -webkit-appearance: none;

    &::placeholder {
      color: cc(medium, text);
      font-size: $text-sm;
    }

    &:focus {
      background-color: cc(h400, dark, 0.8);
    }
  }
}

//Checkbox & Radio ------------------------------------------------------------

.checkbox,
.radio {
  display: flex;
  align-items: center;
}

.radio__field,
.checkbox__field {
  display: none;
  left: 0;
  top: 0;
  margin: 0 !important;
  padding: 0 !important;
  opacity: 0;
  height: 0;
  width: 0;
  pointer-events: none;
}

.radio__field+label,
.checkbox__field+label {
  // label style
  display: inline-block; // fallback
  display: flex;
  cursor: pointer;
  user-select: none;
  font-weight: $semi-bold;
  font-size: $text-sm;
}

.radio__field+label::before,
.checkbox__field+label::before {
  // custom buttons - basic style
  content: "";
  display: flex;
  width: 18px;
  height: 18px;
  border: 2px solid cc(medium, border);
  margin-right: 20px; // gap between button and label
  transition: transform 0.2s;
}

.radio__field+label::before {
  border-radius: 50%; // radio button radius
}

.checkbox__field+label::before {
  border-radius: $radius-xs; // checkbox button radius
}

.radio__field:checked+label::before,
.checkbox__field:checked+label::before {
  // checked state
  background-color: cc(branding);
  box-shadow: none;
  border-color: cc(branding);
}

.radio__field:active+label::before,
.checkbox__field:active+label::before {
  // active state
  transform: scale(0.8);
}

.radio__field:checked:active+label::before,
.checkbox__field:checked:active+label::before {
  transform: none;
  transition: none;
}

.radio__field:checked+label::before {
  // radio button icon
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cg class='nc-icon-wrapper' fill='%23ffffff'%3E%3Ccircle cx='8' cy='8' r='8' fill='%23ffffff'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
  background-size: 10px;
  background-position: center;
  background-repeat: no-repeat;
}

.checkbox__field:checked+label::before {
  // checkbox button icon
  content: $icon--checkmark-outline;
  color: cc(light);
  display: flex;
  align-items: center;
  justify-content: center;
}

label:before {
  display: inline-block;
  display: flex;
  text-align: center;
  font-family: "mybuddiestrip";
  vertical-align: middle;
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $text-md;
}

.radio__field:checked:active+label::before,
.checkbox__field:checked:active+label::before,
.radio__field:focus+label::before,
.checkbox__field:focus+label::before {
  // focus state
  border-color: cc(branding);
}

.label {
  &--component {
    width: 100%;
  }
}

.checkbox {
  &__logo {
    width: 40px;
    height: 40px;
  }

  .nowrap {
    white-space: nowrap;
  }
}