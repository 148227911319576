// Variable


//------------------------------

.mbt-modified-form {
	background-color: cc(light);
	width: 100%;
	margin-top: $space-md;
	transition: all 500ms cb(reg);
	padding: $space-sm;
	border-radius: $radius-md;

	.mbt-modified-form__close {
		display: none;
	}

	.mbt-modified-cancel-btn {
		display: none;
	}

	@media #{$mq-medium-up} {
		display: flex;
		flex-direction: column;
		// align-items: center;
		box-shadow: 0px 25px 60px 0px cc(h900, dark, 0.05);
	}

	&--fixed {
		position: fixed;
		top: $header-height-mobile;
		max-height: 90px;
	}

	&--mock-height {
		height: calc($space-md + 42px);
	}

	&--completed {
		margin-top: 0;
		border-radius: 0;
		border-top: 1px solid cc(05, neutral);
		z-index: $zindex-fixed-element;
		box-shadow: 0 10px 10px cc(01, neutral, .1);

		@media #{$mq-medium-up} {
			display: none;
		}

		p {
			color: cc(03, neutral);
			font-weight: $semi-bold;
			font-size: $text-xs;
			display: flex;

			&:first-child {
				color: cc(01, neutral);
				text-transform: uppercase;
				font-weight: $bold;
				font-size: $text-sm;
			}

			span {
				display: flex;
				align-items: center;

				.icon {
					font-size: $text-md;
					vertical-align: middle;
					margin-left: $space-xs;
				}
			}
		}

		.col-content {
			display: flex;

			>* {
				margin-left: $space-xs;
			}
		}

	}

	&--modal {
		width: 100%;
		margin-top: 0;
		visibility: hidden;
		transition: all 500ms cb(reg);
		transform-origin: 50% 100%;
		opacity: 0;
		position: fixed;
		top: 0;
		left: 0;
		min-height: 50%;
		background-color: cc(white);
		z-index: 9999999;
		border-radius: 0;

		&:before {
			content: '';
			background-color: cc(02, neutral, .5);
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			height: 100%;
		}

		&::-webkit-scrollbar {
			display: none;
		}

		&.js-open {
			visibility: visible;
			opacity: 1;
		}

		.mbt-modified-form__close {
			display: flex;
		}

		.mbt-modified-cancel-btn {
			display: block;
		}

		@media #{$mq-medium-up} {
			visibility: visible;
			opacity: 1;
			position: initial;
			border-radius: $radius-md;
			margin-top: $space-md;
			min-height: auto;
			height: auto;

			&:before {
				display: none;
			}

			.mbt-modified-form__close {
				display: none;
			}

			.mbt-modified-cancel-btn {
				display: none;
			}
		}


	}

	&__close {
		display: flex;
		justify-content: flex-end;
		color: cc(03, neutral);
		font-size: $text-sm;
		align-items: center;

		.icon {
			font-size: $text-xl;
			color: cc(02, neutral);
		}
	}

	&__services {
		// margin-top: $space-sm;
		margin-bottom: $space-sm;

		@media #{$mq-medium-up} {
			//   margin-top: $space-lg;
		}

		p {
			color: cc(02, neutral);
			font-size: $text-md;
			font-weight: $semi-bold;
			margin-bottom: $space-sm;

			@media #{$mq-large-up} {
				font-size: 20px;
			}
		}

		ul {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: $space-xs;

			@media #{$mq-medium-up} {
				display: flex;
			}

		}

		li {
			// width: calc(33.33% - 4px);

			.mtb-tags {
				width: 100%;

				&__input {
					+label {
						justify-content: center;
					}
				}

			}

			@media #{$mq-medium-up} {
				width: auto;
				margin-right: $space-xs;

				&:last-child {
					margin-right: 0;
				}

				.mtb-tags {
					width: auto;

					&__input {
						+label {
							justify-content: center;
						}
					}

				}
			}
		}
	}

	&__filter {

		&-content {
			width: 100%;
			overflow-x: scroll;
			display: flex;
			padding: $space-sm 0;

			@media #{$mq-large-up} {
				overflow-x: initial;
			}
		}

		&-item {
			margin-left: $space-xs;

			&:first-child {
				margin-left: 0;
			}

			@media #{$mq-large-up} {
				position: relative;
			}
		}
	}

	&__content {
		width: 100%;
		padding: $space-xxs;
	}

	&__item {
		height: inherit;
		cursor: pointer;
		border-radius: 10px;
		display: flex;
		flex-direction: column;

		&:last-child {

			.btn {
				width: 100%;
				text-align: center;
			}
		}

		@media #{$mq-medium-up} {
			position: relative;
			flex-direction: row;
			align-items: center;
		}

		&--flight {
			display: none;

			&.js-active {
				display: block;
			}
		}

	}

	&__label {
		display: flex;
		position: relative;
		align-items: center;
		width: 100%;

		&.js-z-index {
			position: fixed;
			z-index: 999999999;
			top: 80px;
			left: 50%;
			transform: translateX(-50%);
			padding: 0 $space-sm;

			@media #{$mq-large-up} {
				padding: 0;
				position: relative;
				top: 0;
				left: 0;
				width: 100%;
				transform: translateX(0);

				.mbt-modified-field {
					width: calc(400px - 32px);
				}
			}

		}

		&--field {
			&+.mbt-modified-form__dropdown {
				.mbt-modified-form__dropdown-list {
					padding-top: 80px;

					@media #{$mq-large-up} {
						padding-top: 0;
					}
				}
			}
		}

	}

	&__input {
		outline: none;
		border: none;
		background: transparent;
		width: 100%;
		-webkit-appearance: none;
		padding-left: 0;

		&::placeholder {
			color: cc(medium, text);
		}
	}

	&__dropdown {
		width: 100%;
		visibility: hidden;
		transition: all 500ms cb(reg);
		transform-origin: 50% 100%;
		display: none;
		opacity: 0;
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		overflow-y: scroll;
		background-color: cc(white);
		z-index: 9999999;

		&::-webkit-scrollbar {
			display: none;
		}

		@media #{$mq-medium-up} {
			width: 105%;
			margin-top: $space-sm;
			display: block;
			margin-top: 0;
			position: absolute;
			left: -$space-sm;
			top: -$space-sm;
			background-color: cc(light);
			z-index: 1;
			border-radius: $radius-md;
			box-shadow: 0 20px 30px cc(01, neutral, .3);
			height: auto;
			padding-top: $space-xs;
			padding-bottom: $space-xs;
		}



		&.js-open {
			display: block;
			visibility: visible;
			opacity: 1;

			@media #{$mq-medium-up} {
				visibility: visible;
			}
		}

		&--field {

			@media #{$mq-large-up} {
				padding-top: 64px;
			}

			.mbt-modified-form__dropdown-footer {

				@media #{$mq-medium-up} {
					display: none;
				}

			}
		}

		&--date-picker {
			width: 100%;
			padding: $space-md;

			@media #{$mq-medium-up} {
				width: 150%;
				left: 50%;
				transform: scaleY(0) translateX(-50%);
				z-index: 3;
			}

			&.js-open {
				@media #{$mq-medium-up} {
					transform: scaleY(1) translateX(-50%);
					max-height: 350px;
					overflow-y: hidden;
				}
			}
		}

		&--multi-date-picker {

			@media #{$mq-large-up} {
				width: 600px;
			}

			.mbt-modified-form__dropdown-list {
				li {

					&:hover {
						background: transparent;
					}

				}
			}

			.mbt-modified-form__dropdown-item {

				@media #{$mq-large-up} {
					display: none;

					&:first-child {
						display: block;
					}
				}
			}

		}

		&-wrapper {
			height: inherit;
			display: flex;
			flex-direction: column;
			overflow-y: scroll;
		}

		&-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			background-color: cc(05, neutral);
			position: -webkit-sticky;
			position: sticky;
			top: 0;
			padding: $space-sm;
			z-index: 999;

			@media #{$mq-medium-up} {
				display: none;
			}
		}

		&-title {
			color: cc(02, neutral);
			font-weight: $semi-bold;
		}

		&-close {
			display: flex;
			align-items: center;

			.icon {
				font-size: $text-xl;
			}
		}

		&-list {
			margin-top: $space-xs;
		}

		&-item {
			padding: $space-xs $space-sm;

			&:hover {
				background-color: cc(05, neutral);
			}

		}

		&-footer {
			@extend .mbt-grid;
			@extend .gap-x-md;
			margin-top: auto;
			padding: $space-sm;
			position: -webkit-sticky;
			position: sticky;
			z-index: 999999999;
			bottom: 0;
			background-color: cc(white);
			border-top: 1px solid cc(05, neutral);
			box-shadow: 0px 0px 20px 0px cc(02, neutral, .1);

			.btn {
				width: 100%;
				text-align: center;
				@extend .col;
			}

			@media #{$mq-medium-up} {
				display: none;
				box-shadow: 0px 0px 0px 0px cc(02, neutral, 0);
				padding: $space-sm $space-sm $space-xs $space-sm;
				display: flex;
				justify-content: flex-end;

				.btn {
					width: auto;
					flex-grow: 0;
					flex-basis: initial;
					max-width: initial;
				}
			}
		}

	}

	&__date {
		display: flex;
		flex-direction: column;
		padding: 0 0 $space-sm 0;
		align-items: center;

		&-title {
			color: cc(03, neutral);
			font-weight: $medium;
			font-size: $text-sm;
		}

		&-day {
			color: cc(01, neutral);
			font-weight: $semi-bold;
			font-size: $text-lg;
		}

		@media #{$mq-medium-up} {
			display: none;
		}
	}


}




.test {
	width: 100%;
	padding: 20px 0;
	background-color: red;

}

.hide-nav {
	--hide-nav-transition-duration: 0.3s;
	position: -webkit-sticky !important;
	position: sticky !important;
	top: 0;
	will-change: transform;
	transition: background-color var(--hide-nav-transition-duration), -webkit-transform var(--hide-nav-transition-duration);
	transition: transform var(--hide-nav-transition-duration), background-color var(--hide-nav-transition-duration);
	transition: transform var(--hide-nav-transition-duration), background-color var(--hide-nav-transition-duration), -webkit-transform var(--hide-nav-transition-duration);
}