.empty-state {
  text-align: center;
  margin: 0 auto;
  padding: $space-xxxl 0;

  img {
    display: block;
    margin: 0 auto $space-lg auto;
  }

  &__title {
    color: cc(dark, text);
    text-align: center;
    font-weight: $semi-bold;
    margin-bottom: $space-xs;
  }

  &--error {
    color: cc(error, alert);
  }
}
