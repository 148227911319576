.sort {
  transition: all 500ms cb(reg);
  display: flex;

  &__item {
    width: 25%;
    flex: 0 0 auto;
    padding-right: var(--half-gutter-width, 0.5rem);
    padding-left: var(--half-gutter-width, 0.5rem);
  }

}
