//Scroll
.reveal-fx {
  opacity: 0;
  visibility: hidden;
  transition: all .2s cb(reg);

  &::before { // never visible - used to check MQ in JS
    display: none;
    content: 'reveal-fx';
  }
}

.reveal-fx--is-visible {
  opacity: 1;
  visibility: visible;

  &.reveal-fx--translate-up {
    transform: translateY(0);
  }

}


.reveal-fx--translate-up {
  transform: translateY(50px);
  transition: all .3s cb(io);

  @for $i from 1 through 19 {
    &:nth-child(#{$i}) {
      transition-delay: #{0.05 * $i+0.2}s;
    }
  }
}







// Animation

.animate-standard {
  animation: fadeInUp 300ms cubic-bezier(0.77, 0, 0.175, 1);

  @media #{$mq-large-up} {
    animation: fadeInUp 1s cubic-bezier(0.77, 0, 0.175, 1);
  }
}

.animate-slow {
  animation: fadeInUp 900ms cubic-bezier(0.77, 0, 0.175, 1);
  
  @media #{$mq-large-up} {
    animation: fadeInUp 1.5s cubic-bezier(0.77, 0, 0.175, 1);
  }
  
}

.animate-fast {
  animation: fadeInUp 100ms cubic-bezier(0.77, 0, 0.175, 1);

  @media #{$mq-large-up} {
    animation: fadeInUp .5s cubic-bezier(0.77, 0, 0.175, 1);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 30px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: .5;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInUpIntro {
  from {
    opacity: 0;
    transform: translate3d(0, 30px, 0);
  }

  to {
    opacity: .8;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUpBtn {
  from {
    opacity: 0;
    transform: translate3d(0, 30px, 0) skewX(-10deg);
  }

  to {
    opacity: .8;
    transform: translate3d(0, 0, 0) skewX(-10deg);
  }
}

@keyframes scaleDown {
  from {
    opacity: 0;
    transform: scale(1.1);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}



