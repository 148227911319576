.dropdown {
  position: relative;

  &__click {
    border: none;
    display: flex;
    align-items: center;

    &.js-active {
      &+.dropdown__sub {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
        z-index: 6;
        transition: all $time-min cb(smooth);
      }
    }
  }

  &__avatar {
    width: 30px;
    height: 30px;
    border-radius: 100%;

    @media #{$mq-large-up} {
      width: 40px;
      height: 40px;
    }
  }

  &__icon {
    font-size: $text-xl;
    transition: transform $time-medium cb(bounce);

    .icon {
      display: block;
    }
  }

  &__sub {
    visibility: hidden;
    position: absolute;
    top: 50px;
    left: 0;
    background-color: cc(light);
    width: 200px;
    border-radius: $radius-xs;
    opacity: 0;
    transform: translateY(-5px);
    transition: all $time-min cb(smooth);
    z-index: $zindex-popover;
    box-shadow: 0px 25px 60px 0px cc(h900, dark, 0.08);

    &--sm {
      width: auto;
    }

    &:after {
      content: "";
      width: 20px;
      height: 20px;
      background: url("/client/public/assets/img/img-dropdown-arrow.svg") center center no-repeat;
      position: absolute;
      top: -10px;
      right: 4px;
    }

    // &.active {
    //   visibility: visible;
    //   opacity: 1;
    //   transform: translateY(0);
    //   z-index: 6;
    //   transition: all $time-medium cb(smooth);
    // }

    &--right {
      left: auto;
      right: 0;

      &:after {
        top: -10px;
        right: 20px;
        left: auto;
      }

      &:before {
        top: -8px;
        right: 20px;
        left: auto;
      }
    }
  }

  &__item {
    border-top: 1px solid cc(light, border);

    &:first-child {
      border: none;
    }

    &--logout {
      background-color: cc(bg);
      border-bottom-left-radius: $radius-xs;
      border-bottom-right-radius: $radius-xs;
      text-align: right;
    }
  }

  &__link {
    font-size: $text-xs;
    display: block;
    padding: 4px 0;
    color: cc(dark, text);
    border: none;
    padding: 10px 15px;
    display: block;

    &:hover {
      color: cc(branding);
    }

    .icon {
      margin-right: 8px;
      font-size: $text-md;
      color: cc(medium, text);
      vertical-align: top;
    }
  }
}
