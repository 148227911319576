.subscribe {
  position: relative;
  background-color: cc(light);
  padding: $space-xs;
  z-index: $zindex-header;

  @media #{$mq-large-up} {
    padding: 0;
  }

  &__content {
    margin: 0 auto;
    background-color: cc(branding);
    padding: $space-md;
    border-radius: $radius-md;
  
    @media #{$mq-large-up} {
      max-width: 1024px;
      display: flex;
      align-items: center;
      padding: $space-xl;
      justify-content: space-between;
    }

    &--offset {
      position: relative;
      bottom: -100px;
    }
  }

  &__item {
    width: 100%;
    margin-top: $space-xl;

    &:first-child {
      margin-top: 0;
    }

    @media #{$mq-large-up} {
      width: 45%;
      margin-top: 0;
    }
  }

  &__title {
    color: cc(light, text);
    font-size: $text-xxl;
    margin-bottom: $space-sm;
    font-weight: $semi-bold;
  }

  &__text {
    color: cc(light, text);
  }

  &__form {
    width: 100%;
  }

  &__input {
    position: relative;
    border-radius: $radius-xs;
    outline: none;
    display: flex;
    align-items: center;
  }

  &__field {
    position: relative;
    width: 100%;
    height: 100%;
    border: none;
    color: cc(dark, text);
    font-weight: $semi-bold;
    -webkit-appearance: none; /* for box shadows to show on iOS */
    border-radius: $radius-xs;
    resize: none;
    outline: none;
    padding: $space-sm;
    z-index: $zindex-header;

    &::placeholder {
      color: cc(medium, text);
      font-size: $text-sm;
    }

    @media #{$mq-large-up} {
      z-index: 0;
    }
  }

  &__btn {
    border: none;
    background-color: cc(bg-dark);
    color: cc(light, text);
    width:  44px;
    height: 44px;
    border-radius: 50%;
    font-size: $text-lg;
    position: relative;
    left: 8px;
    flex: none;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    @media #{$mq-large-up} {
      left: -22px;
    }
  }

  &__error,
  &__warning {
    display: flex;
    align-items: center;
    padding-top: $space-xs;
    color: cc(error, alert);
    font-weight: $medium;
    font-size: $text-sm;

    i {
      margin-right: $space-xs;
    }
  }

  &__warning {
    color: cc(warning, alert);
  }

}
