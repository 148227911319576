.mbt-modal {
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: cc(bg-dark, 0.6);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 0.2s cb(reg);
  overflow: auto;


  &--step {
    display: block;

    @media #{$mq-large-up} {
      display: flex;
    }

    .mbt-modal__wrapper {


      @media #{$mq-large-up} {
        position: absolute;
        top: 5vh;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .mbt-modal__content {
      flex-direction: column;
      width: 100%;
      margin: 0;
      border-radius: 0;
      padding-top: 8px;

      @media #{$mq-large-up} {
        width: 60vw;
        margin: $space-sm;
        border-radius: 8px;
        padding-top: 0;
      }

      @media #{$mq-xxlarge-up} {
        width: 40vw;
      }
    }

    .mbt-modal__body {
      width: 90%;
      padding: $space-lg $space-sm;

      @media #{$mq-large-up} {
        padding: $space-lg;
      }
    }

    .mbt-modal__title {
      margin: $space-lg 0 $space-xs 0;
    }

    .mbt-search-bar {
      padding: 0;
      box-shadow: none;
    }

    .mbt-search-bar__dropdown {
      height: 100vh;

      @media #{$mq-large-up} {
        height: auto;
      }
    }

    .mbt-section {
      width: inherit;
      padding: $space-md $space-lg;
      text-align: left;
      border-bottom-left-radius: $radius-sm;
      border-bottom-right-radius: $radius-sm;
      overflow: hidden;

      h1 {
        font-size: $text-lg;

        @media #{$mq-large-up} {
          font-size: $text-xl;
        }
      }
    }

    .mbt-modal__action {
      margin-top: $space-xxl;

      .btn:first-child {
        margin-right: $space-sm;
      }
    }

    .modal__close-btn {
      top: 0;
      right: 8px;

      @media #{$mq-large-up} {
        top: -10px;
        right: -10px;
      }

      .icon {
        font-size: $text-xxl;

        @media #{$mq-large-up} {
          font-size: $text-xl;
        }
      }
    }

    .pricing-plan {
      margin-top: 24px;
    }

    .rating {
      max-width: auto;
      flex-basis: auto;

      .icon {
        font-size: 32px;
      }
    }

  }

  &.js-visible {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;

    .mbt-modal__content {
      transform: translateY(0);
    }
  }

  &--newsletter {

    .mbt-modal__content {
      width: 100%;
      flex-direction: column;

      @media #{$mq-large-up} {
        flex-direction: row;
        width: 60vw;
        min-height: 350px;
      }

    }

    .mbt-modal__img {
      width: 100%;
      height: inherit;

      @media #{$mq-large-up} {
        align-self: stretch;
      }

      img {
        border-top-left-radius: $radius-sm;
        border-top-right-radius: $radius-sm;

        @media #{$mq-large-up} {
          border-top-right-radius: 0;
          border-bottom-left-radius: $radius-sm;
          border-top-left-radius: $radius-sm;
        }
      }

    }


    .mbt-modal__body {
      width: 100%;
    }
  }

  &__content {
    display: flex;
    border-radius: $radius-sm;
    width: 50vw;
    min-height: 300px;
    margin: $space-sm;
    background-color: cc(light);
    transform: translateY(100px);
    transition: all 0.5s cb(reg);
    align-items: center;

    &--center {
      text-align: center;
      justify-content: center;
    }
  }

  &__close-btn {
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: $zindex-fixed-element;
  }

  &__body {
    padding: $space-lg;
    font-weight: $regular;
  }

  &__title {
    color: cc(02, neutral);
    font-weight: $semi-bold;
    font-size: $text-xl;
    margin-bottom: $space-sm;
  }

  &__text {
    color: cc(03, neutral);
  }

  &__form {
    margin-top: $space-lg;
  }

  &__label {
    color: cc(02, neutral);
    font-weight: $semi-bold;
  }

  &__action {
    margin-top: $space-lg;
  }

}



.pricing-plan {
  padding: $space-sm;
  border: 1px solid cc(04, neutral);
  border-radius: $radius-sm;

  &--highlight {
    background-color: cc(branding);
    color: cc(light);

    .pricing-plan__title {
      color: cc(light);
    }

    .pricing-plan__price {
      color: cc(light);
    }

    .btn {
      background-color: cc(light);

      &:before {
        border: none;
        background-color: cc(light);
      }
    }
  }

  &__title {
    color: cc(01, neutral);
    font-size: $text-lg;
    font-weight: $semi-bold;
    display: block;
  }

  &__price {
    font-size: $text-xxl;
    color: cc(01, neutral);
    font-weight: $bold;
    display: block;
    margin-bottom: $space-sm;
  }

  &__content {
    margin-top: $space-md;
    font-size: $text-sm;
  }

  &__list {
    margin-top: $space-xs;

    &:first-child {
      margin-top: 0;
    }
  }
}