.header {
  padding: $space-sm;
  position: absolute;
  top: 0;
  width: 100%;
  height: 60px;
  z-index: 9999;

  // background-color: cc(bg-dark, .8);
  transition: all 200ms cubic-bezier(0.4, 0.01, 0.165, 0.99);

  &.js-active {
    height: 100vh;
    background-color: cc(bg-dark);
    overflow-y: scroll;

    @media #{$mq-large-up} {
      background: transparent;
      height: auto;
    }

    .header__nav {
      visibility: visible;
      opacity: 1;
      overflow-x: initial;
    }

    .header__item {
      transform: scale(1) translateY(0);
      opacity: 1;
    }
  }

  @media #{$mq-large-up} {
    overflow: visible;
    background: transparent;
    height: auto;
    max-width: $container-width;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    padding: 25px 0;
    left: 50%;
    transform: translateX(-50%);
    overflow-x: initial;
    z-index: $zindex-header;
  }

  &--setting {
    background-color: cc(bg-dark);
    position: relative;

    @media #{$mq-large-up} {
      height: 100px;
    }

    &:after {
      @media #{$mq-large-up} {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: -100%;
        background-color: cc(bg-dark);
        z-index: $zindex-backward;
      }
    }

    &:before {
      @media #{$mq-large-up} {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        right: -100%;
        background-color: cc(bg-dark);
        z-index: $zindex-backward;
      }
    }
  }

  &__logo-wrapper {
    display: inline-block;
    position: absolute;
    top: 30px;
    margin-top: -16px;

    @media #{$mq-large-up} {
      position: initial;
      margin-top: 0;
    }
  }

  &__logo {
    display: block;
    width: auto;
    height: 32px;

    @media #{$mq-large-up} {
      margin-right: $space-lg;
      width: auto;
      height: auto;
    }
  }

  &__burger {
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
    text-align: center;
    // background-color: cc(branding);

    @media #{$mq-large-up} {
      display: none;
    }
  }

  &__nav {
    margin-top: $space-xl;
    visibility: hidden;
    opacity: 0;
    overflow-x: hidden;

    .header__item {
      transform: scale(1.1) translateY(-20px);
      transition: all 200ms cubic-bezier(0.4, 0.01, 0.165, 0.99);
    }

    @media #{$mq-large-up} {
      display: flex;
      align-items: center;
      margin-top: 0;
      visibility: visible;
      opacity: 1;
      overflow-x: initial;

      .header__item {
        transform: scale(1) translateY(0);
      }
    }
  }

  &__item {
    padding: $space-sm 0;
    border-top: 1px solid cc(light, 0.1);

    &:first-child {
      border: none;
    }

    @media #{$mq-large-up} {
      margin: 0 14px;
      padding: 0;
      border: none;
    }

    @media #{$mq-xlarge-up} {
      margin: 0 20px;
      padding: 0;
      border: none;
    }
  }

  &__link {
    color: cc(light, text);
    display: inline-block;
    font-size: $text-sm;
    position: relative;

    &:after {
      content: "";
      opacity: 0;
      width: 100%;
      height: 2px;
      background-color: cc(branding);
      position: absolute;
      left: 0;
      bottom: -5px;
      transform: translateY(-10px);
      transition: all 500ms cb(reg);
    }

    &:hover,
    &.js-active {
      &:after {
        content: "";
        opacity: 1;
        transform: translateY(0);
      }
    }

    &.btn--link {
      background-color: cc(bg-dark, 0.25);

      @media #{$mq-large-up} {
        padding: 5px 10px;
        white-space: nowrap;
      }
    }

    .notification {
      position: absolute;
      top: -12px;
      right: -12px;
      font-size: $text-xs;

      @media #{$mq-large-up} {
        top: -5px;
        right: -5px;
      }
    }
  }

  &__nav-action {
    display: flex;
    align-items: center;
    position: absolute;
    top: 60px;
    margin-top: -45px;
    right: 70px;

    @media #{$mq-large-up} {
      position: initial;
      margin-top: 0;
      margin-left: auto;
    }

    .header__item {
      padding: 0;

      &:last-child {
        border: none;
      }
    }

    .header__link {
      &:after {
        display: none;
      }
    }
  }

  &__notification {
    margin-right: $space-md;
    font-size: 20px;
    position: relative;
    vertical-align: middle;

    @media #{$mq-large-up} {
      margin-right: 0;
      font-size: 22px;
    }

    .icon {
      display: block;
    }
  }
}
