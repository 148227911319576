.mbt-chip {
  background-color: cc(success, alert);
  border-radius: calc(#{$radius-lg} * 2);
  padding: calc(#{$space-xs} / 2) $space-xs;
  display: inline-flex;
  align-items: center;

  &--promo  {
    background-color: cc(default, secondary);
    font-size: $text-xs;
    font-weight: $semi-bold;
  }

  &--icon {
    background-color: cc(light);
    border-radius: $radius-xxl;

    .mbt-chip__text {
      color: cc(h900, dark);
      font-weight: var(--font-weight-semi-bold);
      font-size: var(--text-xs);
    }
  }

  &__icon {
    font-size: var(--text-xs);
    color: cc(alert, notification);
    margin-right: calc(#{$space-xs} / 2);
  }

  &__text {
    color: cc(light);
    font-weight: var(--font-weight-bold);
    font-size: 10px;
    text-transform: uppercase;

    @media #{$mq-medium-up} {
      font-size: var(--text-xs);
    }
  }
}