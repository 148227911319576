// --------------------------------
// Margin
// --------------------------------

.margin-gutter {
  margin-top: $gutter-width;
}

.margin-top-xs {
  margin-top: $space-xs;
}

.margin-top-sm {
  margin-top: $space-sm;
}

.margin-top-md {
  margin-top: $space-md;
}

.margin-top-xl {
  margin-top: $space-xl;
}

.margin-left-sm {
  margin-left: $space-sm;
}

.margin-left-xs {
  margin-left: $space-xs;
}

.margin-right-xs {
  margin-right: $space-xs;
}

.margin-bottom-xs {
  margin-bottom: $space-xs;
}

.margin-bottom-sm {
  margin-bottom: $space-sm;
}

.margin-bottom-xl {
  margin-bottom: $space-xl;
}

.margin-bottom-md {
  margin-bottom: $space-md;
}

.padding-top-sm {
  padding-top: $space-sm;
}

// --------------------------------
// Break
// --------------------------------

.break {
  display: block;
}

// --------------------------------
// Typography - size
// --------------------------------

.text-xxxl {
  font-size: $text-xxxl;
}

.text-xxl {
  font-size: $text-xxl;
}

.text-xl {
  font-size: $text-xl;
}

.text-lg {
  font-size: $text-lg;
}

.text-md {
  font-size: $text-md;
}

.text-sm {
  font-size: $text-sm;
}

.text-xs {
  font-size: $text-xs;
}

// Typography - weight

.weight-light {
  font-weight: $light;
}

.weight-regular {
  font-weight: $regular;
}

.weight-medium {
  font-weight: $medium;
}

.weight-semi-bold {
  font-weight: $semi-bold;
}

.weight-bold {
  font-weight: $bold;
}

// --------------------------------
// opacity
// --------------------------------

.opacity-5 {
  opacity: 0.5;
}

// --------------------------------
// align
// --------------------------------

.middle {
  vertical-align: middle;
}

// --------------------------------
// text align
// --------------------------------

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

// --------------------------------
// Justify Content
// --------------------------------

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

// --------------------------------
// Align Items
// --------------------------------

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

// --------------------------------
// Flex
// --------------------------------
.flex-column {
  flex-direction: column;
}

.wrap {
  flex-wrap: wrap;
}

// --------------------------------
// Image
// --------------------------------

.img-cover {
  object-fit: cover;
  height: 100%;
}

// --------------------------------
// Border Radius
// --------------------------------

.border-radius-sm {
  border-radius: $radius-sm;
}

.border-radius-md {
  border-radius: $radius-md;
}

// --------------------------------
// Image Ratio
// --------------------------------

.aspect-ratio-16\:9 {
  --aspect-ratio: calc(16 / 9);
}

.aspect-ratio-3\:2 {
  --aspect-ratio: calc(3 / 2);
}

.aspect-ratio-4\:3 {
  --aspect-ratio: calc(4 / 3);
}

.aspect-ratio-5\:4 {
  --aspect-ratio: calc(5 / 4);
}

.aspect-ratio-1\:1 {
  --aspect-ratio: calc(1 / 1);
}

.aspect-ratio-4\:5 {
  --aspect-ratio: calc(4 / 5);
}

.aspect-ratio-3\:4 {
  --aspect-ratio: calc(3 / 4);
}

.aspect-ratio-2\:3 {
  --aspect-ratio: calc(2 / 3);
}

.aspect-ratio-9\:16 {
  --aspect-ratio: calc(9 / 16);
}

.height-100 {
  height: 100%;
}

.width-100 {
  width: 100%;
}