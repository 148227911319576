.mbt-button {
  display: flex;
  align-items: center;
  padding: 10px 12px;
  margin: 0 4px;
  appearance: none;
  border: 2px solid cc(branding);
  border-radius: $radius-sm;
  font-size: $text-xs;
  font-weight: $semi-bold;
  color: cc(light, default);
  background-color: cc(branding);
  white-space: nowrap;

  &:hover {
    cursor: pointer;
    background-color: darken(cc(branding), 3);
  }

  &--outline {
    background-color: cc(light);
    color: cc(branding);

    &:hover {
      background-color: cc(light);
      border-color: darken(cc(branding), 5);
      color: darken(cc(branding), 5);
    }
  }

  &--secondary {
    border: 2px solid cc(contrast, secondary);
    background-color: cc(contrast, secondary);

    &:hover {
      cursor: pointer;
      background-color: darken(cc(contrast, secondary), 3);
    }

    &--outline {
      background-color: cc(light);
      color: cc(contrast, secondary);

      &:hover {
        background-color: cc(light);
        border-color: darken(cc(contrast, secondary), 5);
        color: darken(cc(contrast, secondary), 5);
      }
    }
  }

  &--medium {
    font-size: $text-lg;
    font-weight: $medium;
  }


}

.mbt-button-tiny {
  display: flex;
  align-items: center;
  appearance: none;
  border: none;
  border-radius: $radius-xs;
  color: cc(03, neutral);
  background-color: cc(03, neutral, .1);
  font-weight: $semi-bold;
  font-size: $text-xs;
  padding: 2px 8px;

  &:hover {
    cursor: pointer;
    background-color: cc(03, neutral, .2);
  }

  &--active {
    color: cc(branding);
    background: rgba(0, 183, 232, 0.1);

    &:hover {
      background: rgba(0, 183, 232, 0.2);
    }
  }
}