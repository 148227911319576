.hero {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: cc(bg-dark);
  overflow: hidden;
  z-index: 0;
  padding: 100px 0 70px 0;

  @media #{$mq-large-up} {
    min-height: 300px;
    height: 50vh;
    padding: 0;
  }

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: cc(h800, dark);
    position: absolute;
    top: 0;
    left: 0;
    z-index: $zindex-backward-xs;
    opacity: .5;
  }

  &--home {
    max-height: 80vh;
    transform: translateZ(0);

    @media #{$mq-large-up} {
      height: 80vh;
    }
  }

  &--setting {
    min-height: 0;
    height: 100px;
    overflow: visible;
  }

  &__overlay {
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    pointer-events: none;
  }

  &__img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: $zindex-backward-xs;
    background-size: cover;
    background-position: top ;
    background-repeat: no-repeat;
    animation: scaleDown 2s cb(reg);
  }

  &__curve {
    display: none;

    @media #{$mq-large-up} {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 30%;
    }
  }

}