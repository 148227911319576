.alert {
  width: 100%;
  border-radius: $radius-sm;
  padding: $space-sm;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 10px 10px 0px cc(h900, dark, 0.01);
  position: absolute;
  bottom: 20px;
  left: 0;
  z-index: $zindex-popover;
  transform: translateY(50px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s cb(reg);

  @media #{$mq-large-up} {
    width: 400px;
    left: 50px;
    bottom: 50px;
  }

  &.js-visible {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }

  &--cookie {
    width: 100%;
    bottom: 25px;

    @media #{$mq-large-up} {
      width: 80%;
    }
  }

  &--success {
    background-color: cc(branding);
    color: cc(light, text);
    position: fixed;
  }

  &--error {
    color: cc(error, alert);
    position: fixed;
  }

  &--info {
    color: cc(info, alert);
    position: fixed;
  }

  &--warning {
    color: cc(warning, alert);
    position: fixed;
  }

  &--load {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
    position: relative;
    bottom: auto;
    left: auto;
    width: 100%;
    margin-bottom: $space-sm;
    background-color: cc(bg-dark);
    color: cc(light, text);

    .icon {
      color: cc(info, alert);
    }
  }

  &--promo {
    position: relative;
    top: 0;
    left: 0;
    bottom: initial;
    width: 100%;
    background-color: cc(bg-dark);
    border-radius: 0;
    color: cc(light, text);
    font-size: $text-sm;
    padding: $space-sm $space-lg;
    transform: translateY(0);
    display: none;

    a {
      color: cc(branding);
    }

    .alert__close {
      color: cc(branding);
      vertical-align: flex-start;
    }

    &.js-visible {
      display: flex;
      height: 100px;

      @media #{$mq-medium-up} {
        height: 50px;
      }

      & + .header {
        margin-top: 100px;

        @media #{$mq-medium-up} {
          margin-top: 50px;
        }
      }
    }
  }

  &--app {
    position: fixed;
    width: 90%;
    margin-left: 5%;
    box-shadow: 0px 10px 20px 0px cc(h900, dark, 0.4);
    font-size: $text-sm;
    padding: $space-xs;
    border-radius: $radius-lg;

    &:before {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid cc(light);
    }

    a {
      color: cc(branding);
    }

    .alert__close {
      align-self: flex-start;
      i {
        font-size: $text-xl;
      }
    }

    @media #{$mq-large-up} {
      display: none;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    font-weight: $regular;

    .icon {
      margin-right: $space-sm;
      font-size: $text-lg;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;

    .loader {
      margin-right: $space-sm;
    }
  }

  &__close {
    color: cc(dark, text);
    display: inline-block;
    vertical-align: middle;
    font-size: $text-md;
    display: flex;
    align-items: center;
  }
}
