.progress-bar {

    &__info {
        margin-bottom: $space-xs;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }

    &__price {
        font-size: $text-md;
        font-weight: $bold;
    }

    &__budget {
        font-size: $text-xs;
        opacity: .9;
    }

    &__percent {
        font-size: $text-sm;
        font-weight: $bold;
    }

    &__bar {
        width: 100%;
        height: 6px;
        border-radius: 6px;
        background-color: cc(h900, dark, .2);
    }

    &__track {
        height: inherit;
        background-color: cc(light);
        border-radius: inherit;
        transition: all .2s cb(reg);
    }
}