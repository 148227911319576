$colors: (
  default: (bg: #f7f7f7,
    bg-medium: #e7e7e7,
    bg-dark: #05113a,
    branding: #00b7e8,
    branding-light: #9db5e9,
    light: #ffffff,
    white: #ffffff,
    blue: #92a5ef,
  ),
  // New color
  primary: (default: #00b7e8,
  ),
  secondary: (default: #3bc96d,
    contrast: #FF6633),
  action : (default : #FF6633),
  neutral: (01: #002530,
    02: #3e4351,
    03: #777e90,
    04: #b1b5c3,
    05: #ebedf0,
    06: #f4f5f6,
  ),
  service: (flight: #8ecae6,
    stay: #5181fc,
    event: #4c3cae,
    car: #ffb703,
    golf: #fb8500,
    restaurant: #fe7e7e,
  ),
  // -------
  dark: (h400: #e7e7e7,
    h500: #d0d7d8,
    h600: #777e90,
    h800: #003341,
    h900: #002530,
  ),
  text: (light: #ffffff,
    medium: #8b95b4,
    dark: #020d38,
  ),
  notification: (alert: #ff7070,
    promo: #f9d37b,
  ),
  border: (light: #f1f2f6,
    medium: #c0c3d0,
    dark: #061548,
  ),
  form: (switch-off: #4d718a,
    switch-on: #3483fc,
  ),
  alert: (warning: #f0bb63,
    success: #2cab7e,
    info: #00bdf7,
    error: #ff6e69,
  ),
  other: (airbnb: #e4464c,
  ),
);

body {
  background-color: cc(bg);
}