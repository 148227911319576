:root {
  /* style */
  --btt-icon-size: 1.25em;
}

.back-to-top {
  position: fixed;
  right: $space-md;
  bottom: $space-md;
  padding: $space-md;
  z-index: $zindex-fixed-element;
  border-radius: 50%;
  background-color: cc(h800, dark);
  transition: 0.3s;
  width: var(--btt-icon-size);
  height: var(--btt-icon-size);
  display: none;

  @media #{$mq-large-up} {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    background-color: cc(h900, dark);
  }

  .icon {
    color: cc(light, text);
    font-size: $text-xl;
  }
}

.back-to-top {
  // if JS is enabled, hide back to top
  visibility: hidden;
  opacity: 0;
}

.back-to-top.is-visible {
  // show button
  visibility: visible;
  opacity: 1;
}
