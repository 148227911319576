.content {
  width: 100%;
  padding-top: $space-md;

  @media #{$mq-large-up} {
    width: calc(100% - 350px - #{$gutter-width});
    padding-top: 0;
  }

  &--center {
    margin: 0 auto;
  }

  &--full {
    width: 100%;
  }

  &__header {
    width: 100%;


    @media #{$mq-large-up} {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  &__group-title {
    margin-bottom: $space-sm;

    .edit-title {
      font-size: $text-xl;
      font-weight: $medium;
      color: cc(dark, text);
      outline: none;
      padding: 0;
      border: none;
      margin-bottom: $space-xs;
      background-color: cc(h400, dark, .5);
      width: 100%;
      display: block;
    }

    &--edit {
      display: flex;
      align-items: center;
      width: inherit;

      &:hover {
        a {
          opacity: 1;
        }
      }

      a {

        color: cc(medium, text);
        font-size: $text-sm;
        margin-left: $space-sm;
        display: flex;
        align-items: center;
        transition: all 500ms cb(reg);

        @media #{$mq-large-up} {
          opacity: 0;
        }
      }

      .icon {
        margin-right: $space-xs;
        font-size: $text-lg;
        color: cc(branding);
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;

    a {
      color: cc(medium, text);
      font-size: $text-sm;
      margin-left: $space-sm;
      display: flex;
      align-items: center;

      &:hover {
        color: cc(branding);
      }
    }

    .icon {
      margin-right: $space-xs;
      font-size: $text-md;
    }
  }

  &__title {
    @extend .box__title;
    font-weight: $semi-bold;
    margin-bottom: $space-xs;
  }

  &__subtitle {
    font-weight: $regular;
    font-size: $text-sm;
    color: cc(dark);
    display: none;

    @media #{$mq-large-up} {
      display: block;
    }

    strong {
      color: cc(branding);
    }

    &--page {
      color: cc(medium, text);
      font-size: $text-lg;
      margin-bottom: $space-md;
    }
  }

  &__sort {
    font-size: $text-sm;
    display: none;

    @media #{$mq-large-up} {
      display: block;
    }

    a {
      color: cc(medium, text);
    }

    strong {
      color: cc(dark, text);
    }

    .icon {
      color: cc(branding);
    }

    .dropdown__sub {
      top: 40px;
      right: -56px;

      &:after {
        display: none;
      }

      @media #{$mq-large-up} {
        right: 0;

        &:after {
          display: block;
        }
      }
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    margin-bottom: $space-md;
    justify-content: space-between;

    @media #{$mq-large-up} {
      justify-content: initial;
    }

    .content__search {
      margin-left: $space-md;
    }

    .toggle {
      display: none;

      @media #{$mq-large-up} {
        display: block;
      }
    }

    .mbt-filter-content {
      @media #{$mq-medium-up} {
        display: none;
      }
    }
  }

  &__notif {
    display: flex;
    font-weight: $medium;

    @media #{$mq-large-up} {
      align-items: center;
      margin-bottom: $space-md;
    }

    .icon--alert-triangle-outline {
      margin-right: $space-xs;
      color: cc(warning, alert);
    }

    .btn {
      margin-left: $space-sm;
    }
  }

  &__nav {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $space-md;
  }



}