.mbt-footer {
  background: cc(light);

  a {
    color: cc(h600, dark);
    font-size: $text-sm;
    font-weight: $semi-bold;
    padding: $space-xs 0;

    &:hover {
      color: cc(default, primary);
    }
  }

  p {
    font-size: $text-sm;
    color: cc(03, neutral);
    font-weight: $regular;
  }

  h5 {
    font-weight: $semi-bold;
    color: cc(02, neutral);
    margin-bottom: $space-md;
  }

  &__logo {
    margin-right: auto;
    margin-bottom: $space-xs;
  }

  &__social {
    padding: $space-sm 0;

    a {
      font-size: $text-lg;
      margin-left: $space-xs;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__list {

    ul {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
    }

    h5 {
      color: cc(h900, dark);
      font-weight: $semi-bold;
      font-size: $text-sm;
      margin-bottom: $space-xs;
    }

    a {
      margin: $space-xs 0;
    }
  }

  &__content {
    padding: $space-xxl 0;

    li {
      display: flex;
      flex-direction: column;
    }
  }

  &__bottom {
    padding: $space-xs 0;
    background-color: cc(bg-dark);
    color: cc(white);

    @media #{$mq-medium-up} {
      padding: $space-lg 0;
    }

    p {
      margin-bottom: $space-sm;

      @media #{$mq-medium-up} {
        margin-bottom: 0;
      }
    }

    .mbt-footer__list {
      @media #{$mq-medium-up} {
        display: flex;
      }
    }

    ul {
      // margin-left: -12px;
      // margin-right: -12px;

      @media #{$mq-large-up} {
        // flex-direction: row;
        // justify-content: flex-start;
      }


      a {
        font-size: $text-xs;
        white-space: nowrap;

        @media #{$mq-medium-up} {
          margin: 0 $space-sm;
        }
      }
    }
  }

  /*
  &__actions {
    display: flex;
    align-items: center;
    margin-bottom: $space-xl;

    &-link {
      color: cc(light, text);
      display: inline-block;
      font-size: $text-sm;
      position: relative;

      &:hover,
      &.js-active {
        &:after {
          content: '';
          opacity: 1;
          transform: translateY(0);
        }
      }
    }

    .footer__item {
      background-color: cc(h900, dark);
      padding: $space-xs $space-sm;
      border-radius: $radius-md;
      margin-top: 0;

      &:first-child {
        padding: $space-xs $space-sm;
        margin-right: $space-lg;
      }
    }

  }

  &__item {

    &:first-child {
      margin-top: 0;
      padding-right: 80px;
    }

    margin-top: $space-xxl;

    @media #{$mq-large-up} {
      margin-top: 0;
    }

  }

  &__social {
    display: flex;
    margin-top: $space-md;

    li {
      margin-left: $space-md;

      &:first-child {
        margin-left: 0;
      }
    }

    a {
      color: cc(light, text);
    }
  }

  &__partner{
    margin-top: $space-lg;
  }

  &__link {
    display: block;
    margin-top: $space-xs;
    color: cc(medium, text);
    font-weight: $light;

    &:first-child {
      margin-top: 0;
    }
  }

  &__title {
    color: cc(light, text);
    font-weight: $semi-bold;
    margin-bottom: $space-md;
  }

  &__bottom {
    background-color: cc(h900, dark);
    padding: $space-lg 0;
    margin-top: $space-xxxl;
    font-size: $text-xs;
  }

  &__content {
    &--bottom {
      display: flex;
      flex-direction: column;
      text-align: center;

      @media #{$mq-medium-up} {
        flex-direction: row;
        justify-content: space-between;
      }

      p,
      a {
        margin-bottom: $space-xs;

        @media #{$mq-medium-up} {
          margin-bottom: 0;
        }
      }

      a {
        color: cc(medium, text);
        font-weight: $light;
        margin-left: $space-md;
        display: block;

        &:hover {
          color: cc(branding);
        }

        @media #{$mq-medium-up} {
          display: inline-block;
        }
      }
    }
  }
  */

}