.modal {
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: cc(bg-dark, 0.6);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 0.2s cb(reg);
  overflow: auto;

  &.js-visible {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;

    .modal__content {
      transform: translateY(0);
    }
  }

  &__content {
    border-radius: $radius-sm;

    max-width: 100%;
    max-height: 100%;
    margin: $space-sm;
    background-color: cc(light);
    transform: translateY(100px);
    transition: all 0.5s cb(reg);

    @media #{$mq-large-up} {
      max-width: 600px;
      max-height: 500px;
      margin: 0;
    }

    &--big {
      width: 500px;
      max-width: 800px;
    }

    &--full {
      width: 80%;
      max-width: 100%;
      height: 80%;
      max-height: 80%;
    }

    &--package {
      width: 900px;
      max-width: 1000px;
      //height: 50vh;
      max-height: 80vh;
    }

    &--login {
      max-height: 80vh;

      .modal__body {
        padding: $space-sm $space-lg;
        background-color: cc(bg);
        max-height: 700px;
        overflow: auto;
        border-bottom-left-radius: $radius-sm;
        border-bottom-right-radius: $radius-sm;
      }

      .form__item {
        padding: 4px 0;
        &:first-child {
          padding: 4px 0;
        }
      }
    }
  }

  &__header {
    padding: $space-sm $space-lg;
    background-color: cc(bg-light);
    display: flex;
    align-items: center;
    background-color: cc(bg);
    border-top-left-radius: $radius-sm;
    border-top-right-radius: $radius-sm;

    .icon {
      color: cc(branding);
      margin-right: $space-sm;
      font-size: $text-xl;
    }
  }

  &__title {
    font-size: $text-lg;
    font-weight: $semi-bold;
    color: cc(dark, text);
  }

  &__body {
    height: 100%;
    padding: $space-lg;
    font-weight: $regular;

    &--large {
      padding: $space-sm $space-lg;
      background-color: cc(bg);
      max-height: 400px;
      overflow: auto;
    }

    .map {
      iframe {
        height: 580px;
        max-height: 1000px;
      }
    }

    .video {
      overflow: hidden;
      padding-top: 56.25%;
      iframe {
        border: 0;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }

  &__footer {
    padding: $space-sm $space-lg $space-sm $space-lg;
    background-color: cc(light);
    border-bottom-left-radius: $radius-sm;
    border-bottom-right-radius: $radius-sm;

    .btn {
      &:last-child {
        margin-left: $space-sm;
      }
    }
  }

  &__close-btn {
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: $zindex-fixed-element;
  }

  &__close-map {
    width: 100%;
    background-color: cc(bg);
    position: absolute;
    z-index: 20;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 $space-sm;

    @media #{$mq-large-up} {
      display: none;
    }

    .btn {
      position: relative;
      top: initial;
      right: initial;
    }
  }

  & .subscribe {
    &__content {
      background: none;
      display: block;
    }

    &__item {
      width: 100%;
    }

    &__title {
      color: cc(branding);
      font-size: $text-xl;
    }

    &__text {
      color: cc(medium, text);
    }

    &__input {
      margin-top: $space-lg;
    }

    &__field {
      background-color: cc(h400, dark, 0.5);
    }
  }
}
