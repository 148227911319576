.mbt-form {
    .btn {
        width: 100%;
        text-align: center;
        padding: 12px 20px;
        font-size: $text-sm;

        @media #{$mq-medium-up} {
            width: auto;
        }
    }
}