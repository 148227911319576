.list {

  &__item {
    margin: $space-lg;
    text-align: center;

    @media #{$mq-large-up} {
      margin: $space-xl;
    }

    &--icon {
      position: relative;

      @media #{$mq-large-up} {

        &:before {
          content: '';
          width: 40px;
          height: 1px;
          background-color: cc(branding, .3);
          position: absolute;
          top: 40px;
          left: calc(-60px);
          transform: translateY(-50%);
        }

        &:first-child {
          &:before {
            display: none;
          }
        }
      }
    }

    &--detail {
      text-align: left;
      margin: 0;
      display: flex;
      align-items: flex-start;
      border-top: 1px solid cc(light, border);
      padding: $space-sm 0;

      &:first-child {
        border: none;
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
        display: block;

        .list__content {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .list__title {
          color: cc(branding);
        }

        .list__price {
          font-size: $text-lg;
        }
      }

      .list__title {
        margin: 0;
      }

      .icon {
        font-size: $text-lg;
        color: cc(branding);
        margin-right: $space-sm;
      }

      span {
        display: block;
        font-size: $text-sm;
        padding-top: 4px;

        .icon {
          font-size: $text-md;
          vertical-align: middle;
          margin-right: $space-xs;
          color: cc(warning, alert);
        }
      }
    }

    &--box {
      text-align: left;
      margin: 0;
      display: flex;
      align-items: center;
      margin-top: $space-sm;

      .list__title {
        margin: 0;
      }

      .icon {
        font-size: $text-xl;
        color: cc(branding);
        margin-right: $space-sm;
      }

    }

  }

  &__icon {
    width: 80px;
    height: 80px;
    border: 1px dashed cc(branding, .3);
    border-radius: 50%;
    font-size: $text-xxl;
    display: flex;
    flex-basis: 80px;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: cc(light);
    margin: 0 auto;

    .icon {
      color: cc(branding);
    }
  }

  &__content {
    width: 100%;
    margin-left: 4px;
  }

  &__head {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    color: cc(dark, text);
    font-weight: $semi-bold;
    display: block;
    margin-top: $space-sm;
  }

  &__price {
    font-weight: $bold;
    color: cc(branding);
    margin-left: $space-sm;
  }

  &__category {
    display: flex;
    justify-content: space-between;
    
    div {
      display: flex;
      align-items: center;
    }

    &--rbnb {
      svg {
        fill: cc(airbnb, other);
      }

      color: cc(dark, text);
    }

    .list__price {
      color: cc(medium, text);
      font-weight: $medium;
    }
  }
}



.test-listing {
  display: flex;

  p {
    flex-wrap: wrap;
  }

  .list__price {}
}
