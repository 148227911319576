.search-bar {
  background-color: cc(light);
  width: 100%;
  margin: 0 auto;
  transition: all 500ms cb(reg);
  padding: $space-md 0 $space-xxl 0;

  @media #{$mq-large-up} {
    display: flex;
    align-items: center;
    height: 80px;
    box-shadow: 0px 25px 60px 0px cc(h900, dark, 0.05);
    padding: 0;
  }

  &.js-active {
    @media #{$mq-large-up} {
      transform: translateY(-60px);
    }

    + .search-bar__clear {
      transform: translate(-50%, -60px);
    }
  }

  &--sub {
    height: 100vh;
    opacity: 0;
    visibility: hidden;
    border-top: 1px solid cc(light, border);
    transition: all 500ms cb(cubic-bezier);
    padding: $space-md;
    z-index: 9999;
    position: absolute;
    top: 0;

    @media #{$mq-large-up} {
      padding: 0;
      height: 0;
      opacity: 1;
      transform: scale(0, 0);
      position: relative;
    }

    &.js-active {
      opacity: 1;
      visibility: visible;
      transform-origin: 50% 100%;
      transform: scaleY(0, 1);

      .search-bar__item {
        height: auto;
      }

      @media #{$mq-large-up} {
        height: 80px;
        transform: translateY(-60px);
        position: relative;

        .search-bar__item {
          height: inherit;
        }
      }
    }

    &-page {
      transition: none;

      &.js-active {
        @media #{$mq-large-up} {
          transform: translateY(0);
        }
      }

      &.sticky {
        transition: none;

        @media #{$mq-large-up} {
          position: fixed;
          top: 80px;
          transition: transform 500ms cb(cubic-bezier);

          .search-bar__dropdown {
            top: 80px;
            bottom: auto;
            left: 0;
            transform-origin: 50% 0;
            z-index: $zindex-overlay;
            box-shadow: 0px 25px 60px 0px cc(h900, dark, 0.05);

            &--date-picker {
              width: 150%;
              left: 50%;
              transform: scaleY(0) translateX(-50%);
              padding: $space-md;

              &.js-open {
                transform: scaleY(1) translateX(-50%);
                max-height: 350px;
              }
            }
          }
        }
      }
    }
  }

  &--page {
    &.js-active {
      @media #{$mq-large-up} {
        transform: translateY(0);
      }
    }

    @media #{$mq-large-up} {
      &.sticky {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;

        ~ .container--content {
          padding-top: 140px;
        }

        .search-bar__dropdown {
          top: 80px;
          bottom: auto;
          left: 0;
          transform-origin: 50% 0;
          z-index: $zindex-overlay;
          box-shadow: 0px 25px 60px 0px cc(h900, dark, 0.05);

          &--date-picker {
            width: 150%;
            left: 50%;
            transform: scaleY(0) translateX(-50%);
            padding: $space-md;

            &.js-open {
              transform: scaleY(1) translateX(-50%);
              max-height: 350px;
            }
          }
        }
      }
    }
  }

  &__container {
    @extend .container;
    margin: 0 auto;
    padding: 0;
    height: inherit;

    @media #{$mq-large-up} {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__content {
    width: 100%;
    padding: $space-sm;

    @media #{$mq-large-up} {
      display: flex;
      height: inherit;
      width: calc(100% - 80px - 50px);
      padding: 0;
    }
  }

  &__item {
    padding: $space-xs;
    font-size: $text-sm;
    font-weight: $medium;
    height: inherit;
    position: relative;
    cursor: pointer;
    border: 1px solid cc(medium, border, 0.5);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-top: $space-xs;

    @media #{$mq-large-up} {
      flex-direction: row;
      align-items: center;
      flex: 1;
      padding: 0 $space-sm;
      border: none;
      border-left: 1px solid cc(light, border);
      border-radius: 0;
      margin-top: 0;
    }

    &:hover > .search-bar__delete {
      opacity: 0.5;
      transition: opacity 0.2s;
    }

    &:first-child {
      margin-top: 0;

      @media #{$mq-large-up} {
        border: none;
      }
    }

    .icon {
      color: cc(branding);
      font-size: $text-xl;
      margin-right: $space-sm;
    }

    .number-input {
      .icon {
        margin: 0;
      }
    }

    .placeholder {
      &.js-active {
        color: cc(dark, text);
      }
    }

    &.disabled {
      cursor: default;
      opacity: 0.8;

      .icon {
        color: cc(medium, text);
      }
    }
  }

  &__label {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    color: cc(medium, text);

    &.error {
      .icon {
        color: cc(error, alert);
      }
      ::placeholder {
        color: cc(error, alert);
      }
      span {
        color: cc(error, alert);
      }
    }
  }

  &__input {
    outline: none;
    border: none;
    background: transparent;
    width: 100%;
    -webkit-appearance: none;
    padding-left: 0;

    &::placeholder {
      color: cc(medium, text);
    }
  }

  &__dropdown {
    width: 100%;
    height: 0;
    visibility: hidden;
    overflow: hidden;
    overflow-y: scroll;
    transition: all 500ms cb(reg);
    transform-origin: 50% 100%;
    margin-top: $space-sm;
    padding: $space-xs;
    display: none;
    z-index: $zindex-popover;

    @media #{$mq-large-up} {
      display: block;
      margin-top: 0;
      padding: $space-sm;
      position: absolute;
      left: 0;
      bottom: 80px;
      transform: scaleY(0);
      border: 1px solid cc(light, border);
      background-color: cc(light);
    }

    &.js-open {
      display: block;
      height: auto;
      visibility: visible;

      @media #{$mq-large-up} {
        max-height: 250px;
        height: auto;
        visibility: visible;
        transform: scaleY(1);
      }
    }

    &--number-of-person {
      &.js-open {
        display: block;
        height: auto;
        visibility: visible;

        @media #{$mq-large-up} {
          max-height: 350px;
          height: auto;
          visibility: visible;
          transform: scaleY(1);
        }
      }
    }

    &--date-picker {
      width: 100%;
      padding: $space-md;

      @media #{$mq-large-up} {
        width: 150%;
        left: 50%;
        transform: scaleY(0) translateX(-50%);
        z-index: 3;
        overflow-y: hidden;
      }

      &.js-open {
        @media #{$mq-large-up} {
          transform: scaleY(1) translateX(-50%);
          max-height: 350px;
        }
      }
    }

    &--multi-date-picker {
      width: 100%;
      transform: scaleY(0) translateX(-50%);

      @media #{$mq-large-up} {
        width: 600px;
        left: 50%;
        padding: $space-md;
        z-index: 3;
        overflow-y: hidden;
      }

      &.js-open {
        transform: scale(1);

        @media #{$mq-large-up} {
          max-height: 350px;
          transform: scaleY(1) translateX(-50%);
        }
      }
    }

    &-item {
      display: flex;
      align-items: center;
      padding: 12px 0;
      border-top: 1px solid cc(light, border);

      &:first-child {
        border: none;
      }

      .icon {
        font-size: $text-lg;
      }

      &--list {
        flex-direction: column;
        align-items: flex-start;
      }

      &--disabled {
        cursor: default;
        opacity: 0.8;

        .icon {
          color: cc(medium, text);
        }
      }
    }

    &-title {
      color: cc(dark, text);
      font-weight: $semi-bold;
      width: 100%;
      margin-bottom: $space-xs;
    }

    &-sub {
      padding-left: $space-xs;

      li {
        margin-top: $space-xs;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  &__more {
    display: block;
    font-size: $text-xl;
    transition: background-color 500ms cb(reg);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto $space-md auto;
    border-radius: 50%;
    background-color: cc(bg-dark);
    color: cc(light);
    width: 60px;
    height: 60px;

    @media #{$mq-large-up} {
      width: 80px;
      height: 80px;
      margin: 0;
      border-radius: 0;
      background-color: cc(bg);
      color: cc(dark, text);
    }

    &:hover {
      background-color: cc(bg-dark);
      color: cc(light);
    }

    .icon {
      transition: all 500ms cb(reg);
    }

    &.js-active {
      background-color: cc(bg-dark);
      color: cc(light);

      .icon {
        transform: rotate(-45deg);
      }
    }
  }

  &__search {
    background-color: cc(branding);
    font-size: $text-xl;
    color: cc(light, text);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 500ms cb(reg);
    padding: $space-sm 0;
    margin: 0 $space-sm;
    border-radius: $radius-xs;

    span {
      font-size: $text-lg;
      margin-right: $space-sm;
    }

    @media #{$mq-large-up} {
      margin: 0;
      position: relative;
      left: 25px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      box-shadow: 0px 25px 50px 0px cc(branding, 0.2);

      span {
        display: none;
      }
    }

    &--page {
      @media #{$mq-xlarge-up} {
        left: 25px;
        width: 50px;
        height: 50px;
      }
    }

    &:hover {
      background-color: darken(cc(branding), 5);
    }
  }

  &__clear {
    background-color: cc(h800, dark);
    padding: 4px $space-sm;
    color: cc(light, text);
    font-size: $text-xs;
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: $space-xs;
    transition: all 500ms cb(reg);

    &.disabled {
      cursor: default;
      opacity: 0.8;

      .icon {
        color: cc(medium, text);
      }
      &:hover {
        background-color: cc(h800, dark);
        opacity: 0.8;

        .icon {
          color: cc(medium, text);
        }
      }
    }

    &:hover {
      background-color: cc(branding);

      .icon {
        color: cc(light, text);
      }
    }

    .icon {
      margin-right: $space-xs;
      color: cc(branding);
      vertical-align: middle;
    }

    span {
      vertical-align: middle;
    }
  }

  &__delete {
    position: absolute;
    right: -5px;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    margin: 0;
    display: inline-block;
    vertical-align: middle;
    line-height: 0;
    border: none;
    cursor: pointer;
    opacity: 0;
    margin-left: auto;
    background: none;

    .icon {
      margin: 0;
      font-size: $text-md;
      color: cc(dark, text);
      line-height: $text-md;
    }

    &.js-visible {
      opacity: 0.5;
      transition: opacity 0.2s;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__concat {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid cc(branding, 0.5);
    padding: $space-sm;

    background-color: cc(light);

    @media #{$mq-large-up} {
      position: fixed;
      top: 0;
      left: 0;
    }
  }

  &__wrapper {
    margin-top: $space-sm;

    @media #{$mq-large-up} {
      margin-top: $space-xl;
      max-height: 150px;
      height: auto;
      overflow-y: scroll;
    }
  }

  &__close {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    padding: 0 0 $space-md 0;
    border-bottom: 1px solid cc(light, border);
    margin-bottom: $space-md;

    @media #{$mq-large-up} {
      display: none;
    }
  }

  &__message {
    border-top: 1px solid cc(h400, dark);
    display: flex;
    color: cc(error, alert);
    font-size: 14px;
    padding-top: 12px;

    .icon {
      color: cc(error, alert);
      font-size: $text-lg;
    }
  }
}
