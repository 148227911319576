.datepicker {
  width: 100%;
  @media #{$mq-large-up} {
    min-height: 305px;
  }

  &--multi {
    width: 100%;

    @media #{$mq-large-up} {
      width: 50%;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: cc(branding);
    font-weight: $bold;
  }

  &__button {
    display: block;
    padding: 0;
    background: none;
    cursor: pointer;
    border: none;

    .icon {
      margin-right: 0;
    }

    @media #{$mq-large-up} {
      width: 30px;
      height: 30px;
    }
  }

  &__month {
    padding-right: $space-xs;

    &-container {
      text-align: center;

      @media #{$mq-large-up} {
        flex: 1;
      }
    }
  }

  &__content {
    width: 100%;
    margin-top: $space-sm;
  }

  &__weekdays {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: cc(dark, text);
    font-weight: $semi-bold;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    padding: 4px 0;
  }

  &__day,
  &__dow {
    width: 30px;
    height: 30px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 200ms cc(reg);
  }

  &__day {
    font-weight: $semi-bold;

    &.js-active {
      color: cc(light, text);
      background-color: cc(branding);
    }

    @media #{$mq-large-up} {
      &:hover,
      &.js-active {
        color: cc(light, text);
        background-color: cc(branding);
      }
    }

    &.js-select {
      &:before {
        content: "";
        width: 100%;
        height: 100%;
        background-color: cc(branding, 0.1);
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        z-index: $zindex-backward-xs;
        border-radius: 50%;
      }
    }

    &--other {
      color: cc(medium, text, 0.5);

      &:hover {
        color: cc(medium, text, 0.5);

        &:after {
          background-color: cc(light);
        }
      }
    }
  }
}
