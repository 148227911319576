.mbt-checkout {
  // width: 100%;
  position: absolute;
  // bottom: 70px;
  top: 70px;

  right: 0;
  box-shadow: 0 20px 30px cc(01, neutral, .3);
  border-radius: $radius-md;
  padding: $space-sm;
  margin: 0 $space-sm;
  z-index: $zindex-popover;
  background-color: cc(light);
  opacity: 0;
  visibility: hidden;

  @media #{$mq-medium-up} {
    position: absolute;
    width: 400px;
    bottom: initial;
    top: 60px;
  }

  &.js-active {
    opacity: 1;
    visibility: visible;
    position: absolute;
  }

  &__item {
    margin-top: $space-sm;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      display: flex;
      justify-content: center;
    }
  }

  &__resume {
    width: 100%;
    background-color: cc(02, neutral, .05);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $space-xs;
    border-radius: $radius-sm;

    p {
      display: flex;
      align-items: center;
      font-weight: $semi-bold;
      color: cc(02, neutral);
      font-size: $text-sm;
    }

    span {
      font-weight: $semi-bold;
      color: cc(branding);
    }
  }
}