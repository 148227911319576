// to edit the breakpoints, check the style.scss file

$breakpoints: (
  xs: 32rem, // ~512px
  sm: 48rem, // ~768px
  md: 64rem, // ~1024px
  lg: 80rem, // ~1280px
  xl: 90rem  // ~1440px
) !default;

@mixin breakpoint($breakpoint, $logic: false) {
  @if( $logic ) {
    @media #{$logic} and (min-width: map-get($map: $breakpoints, $key: $breakpoint)) { @content; }
  } @else {
    @media (min-width: map-get($map: $breakpoints, $key: $breakpoint)) { @content; }
  }
}