.excerpt-button {
  font-size: $text-sm;
  color: cc(branding);
  opacity: 1;

  &.js-open {
    opacity: 0;
  }
}

.excerpt-hidden {
  height: 10rem;
  overflow: hidden;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background: linear-gradient(to bottom, cc(light, 0) 0%, cc(light) 100%);
  }
}

.excerpt-visible {
  max-height: 300px;
  height: auto;
  overflow: auto;
  padding: 0.1rem;
}
