.mbt-filter {
    display: flex;
    align-items: center;

    &-content {
        width: 100%;
        overflow-x: scroll;
        display: flex;
        padding: $space-sm 0;

        @media #{$mq-large-up} {
            overflow-x: initial;
        }

        .mbt-filter {
            margin-left: $space-xs;

            &:first-child {
                margin-left: 0;
            }

            @media #{$mq-large-up} {
                position: relative;
            }
        }

    }

    &__click {
        color: cc(02, neutral);
        background-color: cc(05, neutral);
        font-size: $text-sm;
        display: block;
        padding: 6px $space-sm;
        border-radius: 50px;
        font-weight: $semi-bold;
        white-space: pre;
        border: 2px solid cc(02, neutral, 0);

        &.js-active {
            background-color: cc(02, neutral);
            border: 2px solid cc(02, neutral);
            color: cc(white);
        }

        &.js-open {
            border: 2px solid cc(02, neutral);

            &+.mbt-filter__sub {
                visibility: visible;
                opacity: 1;
                transform: translateY(0);
                // z-index: 6;
                transition: all $time-min cb(smooth);
            }
        }
    }

    &--tag {
        .mbt-filter__click {
            border: 2px solid cc(04, neutral);
            background-color: transparent;

            &.js-active {
                background-color: cc(02, neutral);
                border: 2px solid cc(02, neutral);
                color: cc(white);
            }
        }
    }

    &__sub {
        visibility: hidden;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: cc(light);
        width: 85vw;
        border-radius: $radius-xs;
        opacity: 0;
        transition: all $time-min cb(smooth);
        z-index: $zindex-popover;
        box-shadow: 0 20px 30px cc(01, neutral, .3);
        border-radius: $radius-md;
        display: flex;
        flex-direction: column;
        padding: $space-sm;

        @media #{$mq-large-up} {
            left: 0;
            top: 0;
            transform: translate(0, 0);
            width: 300px;
            z-index: 99999;
        }

        >* {
            margin-top: $space-md;

            &:first-child {
                margin-top: 0;
            }
        }

        p {
            font-weight: $semi-bold;
            color: cc(01, neutral);
        }

        li {
            margin-top: $space-sm;

            &:first-child {
                margin-top: 0;
            }
        }

        &-btn {
            display: flex;

            >* {
                flex: 1;
                text-align: center;

                &:first-child {
                    margin-right: $space-sm;
                }
            }
        }

    }

    &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background-color: cc(02, neutral, .2);
        z-index: 1;
        overflow: hidden;
        visibility: hidden;
        opacity: 0;

        @media #{$mq-large-up} {
            display: none;
        }
    }

    &.js-open {
        &:before {
            visibility: visible;
            opacity: 1;
        }

        &+.mbt-filter__sub {
            visibility: visible;
            opacity: 1;
        }
    }

    &__item {
        &--between {
            display: flex;
            justify-content: space-between;
        }

        span {
            font-size: $text-sm;
            font-weight: $semi-bold;
        }
    }
}