[class^="aspect-ratio"], [class*=" aspect-ratio"] {
    --aspect-ratio: calc(16/9);
    position: relative;
    height: 0;
    padding-bottom: calc(100%/(var(--aspect-ratio)));

    > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &:not(iframe) {
        object-fit: cover;
      }
    }
  }





