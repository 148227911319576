.cards {
  width: 100%;
  background-color: cc(light);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  &--load {
    @keyframes placeHolderShimmer {
      0% {
        background-position: 0 0;
      }

      100% {
        background-position: 300px 0;
      }
    }

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      animation: placeHolderShimmer 1.25s forwards linear infinite;
      background: linear-gradient(to right,
          cc(light, 0.4) 0%,
          cc(bg-medium, 0.2) 27%,
          cc(bg-medium, 0.2) 56%,
          cc(light, 0.4) 100%);
      background-position: 0 0;
    }

    .cards__img {
      background-color: cc(bg-medium);
    }

    .cards__subtitle {
      width: 40%;
      height: 10px;
      background-color: cc(bg-medium);
      border-radius: 50px;
    }

    .cards__title {
      width: 60%;
      height: 20px;
      background-color: cc(bg-medium);
      border-radius: 50px;
    }

    .cards__text {
      width: 100%;
      height: 12px;
      background-color: cc(bg-medium);
      border-radius: 50px;
      margin-top: 20px;
    }

    .cards__time {
      width: 95%;
      height: 15px;
      background-color: cc(bg-medium);
      border-radius: 50px;
      margin-top: 5px;
    }

    .cards__price {
      width: 30%;
      height: 15px;
      background-color: cc(bg-medium);
      border-radius: 50px;
      margin-top: 5px;
    }

    .btn {
      display: none;
    }
  }

  &--horizontal {
    @media #{$mq-medium-up} {
      flex-direction: row;
      flex-wrap: wrap;
    }

    .cards__img {
      @media #{$mq-medium-up} {
        height: inherit;
        width: 35%;
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;
        border-top-right-radius: 0;
      }
    }

    .cards__container {
      @media #{$mq-medium-up} {
        width: 65%;
      }
    }

    &.cards--load {
      min-height: 300px;
    }

    .cards__footer {
      display: block;

      @media #{$mq-medium-up} {
        display: flex;
        align-items: center;
      }

      div {
        margin-left: auto;

        .cards__price {
          margin-left: 0;
          font-size: $text-sm;

          @media #{$mq-medium-up} {
            font-size: $text-md;
          }
        }
      }
    }

    .cards__price {
      @media #{$mq-medium-up} {
        margin-right: $space-lg;
      }
    }

    &.cards--car {
      .cards__container {
        justify-content: center;
      }

      .cards__list--car {
        flex-direction: row;
        flex-wrap: wrap;

        .cards__list-item {
          width: 50%;
        }
      }

      .cards__footer {
        margin-top: 0;
      }
    }
  }

  &--small {
    .cards__img {
      height: 100px;

      @media #{$mq-medium-up} {
        height: 100%;
      }
    }

    .cards__container {
      padding: $space-xs $space-sm;
    }

    .cards__pack {
      margin: 0;
    }

    .cards__text {
      padding: 0;
    }

    .cards__list--package {
      li {
        margin: 0;
      }
    }

    .btn {
      display: none;
    }
  }

  &--minimal {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    position: relative;
    border-radius: 6px;
    z-index: 0;
    overflow: hidden;
    display: block;
    display: flex;

    @media #{$mq-medium-up} {
      height: 150px;
    }

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      background-color: cc(h900, dark);
      position: absolute;
      top: 0;
      left: 0;
      z-index: $zindex-backward;
      opacity: 0.8;
    }

    .cards__img {
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      height: inherit;
      width: 100%;
      z-index: -2;
      position: absolute;
      height: 100%;
    }

    .cards__container {
      width: 100%;
    }

    .cards__title {
      color: cc(light, text);
    }
  }

  &--slider {
    box-shadow: 0px 10px 10px 0px cc(h900, dark, 0.005);
  }

  &--add {
    padding: $space-xl;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: cc(bg-dark, 0.05);

    .cards__text {
      margin: $space-lg 0 $space-sm 0;
    }
  }

  &--blog {
    .cards__subtitle {
      color: cc(branding);
    }

    .cards__footer {
      @media #{$mq-medium-up} {
        justify-content: flex-start;
      }
    }
  }

  &--flight {
    position: relative;
    height: inherit;

    .cards__btn-actions {
      position: absolute;
      top: 8px;
      right: 8px;
    }

    .cards__actions {
      display: block;

      @media #{$mq-large-up} {
        display: flex;
        align-items: center;
      }

      div {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media #{$mq-large-up} {
          display: inline-block;
        }
      }
    }

    .cards__days {
      display: block;
      margin-bottom: $space-sm;

      @media #{$mq-large-up} {
        margin-bottom: 0;
      }
    }

    .cards__price {
      @media #{$mq-large-up} {
        margin-right: $space-lg;
      }
    }
  }

  &__small {
    display: flex;
    align-items: center;
    padding: $space-xs $space-sm;
    border-radius: $radius-xs;
    position: relative;

    .cards__like {
      margin: 0;
      position: absolute;
      top: -15px;
      left: -15px;
    }

    .cards__logo {
      width: 50px;
      padding: 4px;
      height: auto;
      left: -$space-sm;
      bottom: -$space-xs;
    }

    .cards__img {
      width: 80px;
      height: 80px;
      border-radius: $radius-xs;
    }

    .cards__container {
      padding: 0;
      margin-left: $space-sm;
      border: none;
    }

    .cards__subtitle {
      font-size: $text-xs;
      margin-bottom: 4px;
    }

    .cards__rating {
      justify-content: space-between;

      .rating {
        padding: 4px 0;
      }
    }

    .cards__footer {
      padding: 0;
      margin-top: $space-xs;
      display: flex;
      justify-content: space-between;
    }
  }

  &--car {
    .cards__img {
      background-size: contain;

      @media #{$mq-large-up} {
        background-size: 70%;
      }
    }

    .cards__logo {
      padding: 15px;
    }
  }

  &--rbnb {
    .cards__img {
      &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: cc(airbnb, other);
        opacity: 0.8;
      }

      &:after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        // background: url(/client/public/assets/img/logo-rbnb-light.svg) center center no-repeat;
      }
    }

    .cards__title {
      display: flex;

      img {
        margin-right: $space-sm;
      }
    }
  }

  &__img {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 150px;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media #{$mq-large-up} {
      height: 200px;
    }

    &--contain {
      background-size: cover;
      width: 100% !important;

      @media #{$mq-large-up} {
        width: 27% !important;
      }
    }
  }

  &__like {
    display: inline-block;
    margin: $space-xs;
  }

  &__dropdown {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  &__logo {
    background-color: cc(light);
    width: 100px;
    height: 50px;
    border-top-left-radius: inherit;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    object {
      max-width: 100%;
    }
  }

  &__container {
    padding: $space-md;
    display: flex;
    flex-direction: column;
    flex: 1;

    &--map {
      padding: $space-xs;
    }
  }

  &__subtitle {
    font-size: $text-sm;
    color: cc(medium, text);
    font-weight: $medium;
    display: block;
    margin-bottom: $space-xs;
  }

  &__title {
    font-weight: $semi-bold;
    font-size: $text-lg;
    color: cc(dark, text);
  }

  &__rating {
    display: flex;
    align-items: center;

    a {
      font-size: $text-xs;
      color: cc(medium, text);
      margin-left: $space-md;

      &:hover {
        color: cc(branding);
      }
    }
  }

  &__rating-guest {
    margin-right: $space-xs;

    p {
      background-color: cc(branding);
      font-size: $text-xs;
      font-weight: $bold;
      color: cc(light, text);
      padding: 2px 4px;
      border-radius: $radius-xs;
    }
  }

  &__text {
    padding: $space-xs 0;
    font-size: $text-sm;
    font-weight: $regular;
  }

  &__time {
    font-size: $text-xs;
    font-weight: $regular;
    padding: 4px 0;
    color: cc(medium, text);

    .icon {
      font-size: $text-md;
      vertical-align: middle;
      color: cc(branding);
      margin-right: $space-xs;
    }

    span {
      vertical-align: middle;
    }
  }

  &__tag {
    margin-top: $space-xs;
    font-size: $text-xs;
    font-weight: $bold;
    background-color: cc(bg-medium);
    display: inline-block;
    padding: 4px 8px;
    border-radius: $radius-xs;
    cursor: pointer;
    transition: all $time-medium;

    &:hover {
      background-color: cc(success, alert);
      color: cc(light, text);
    }

    &.active {
      background-color: cc(success, alert);
      color: cc(light, text);
    }
  }

  &__details {
    width: 100%;
    background-color: cc(bg-medium, 0.15);
    padding: $space-xs 0;
  }

  &__item {
    display: flex;
    padding: $space-xs $space-sm;
  }

  &__pack {
    width: 100%;
    margin: $space-xs 0 $space-md 0;

    &--package {
      padding-bottom: $space-sm;

      @media #{$mq-medium-up} {
        display: flex;

        flex-wrap: wrap;
      }

      @media #{$mq-large-up} {
        padding-bottom: 0;
        justify-content: space-between;
      }

      .cards__list {
        li {
          padding: 4px 0;
        }

        &-item {
          margin-top: 0;
        }
      }
    }
  }

  &__list {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-size: $text-xs;
    font-weight: $regular;
    text-decoration: none;

    &--package {
      justify-content: flex-start;
    }

    @media #{$mq-large-up} {
      margin-bottom: $space-xs;
    }

    &--column {
      flex-direction: column;
      margin-bottom: 0;

      .cards__list-item {
        margin-top: $space-xs;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      margin-top: $space-sm;

      @media #{$mq-large-up} {
        margin-top: 0;
      }

      &--proposal {
        justify-content: space-between;
      }
    }

    &-wrapper {
      display: flex;
    }

    &--last {
      margin-bottom: 0;
    }

    .icon {
      color: cc(branding);
      font-size: $text-md;
      display: block;
      margin-right: $space-xs;
    }

    a {
      color: cc(branding);
    }

    &--car {
      color: cc(dark, text);
      font-weight: $medium;
      width: 100%;
      margin-top: $space-xs;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .cards__list-item {
        padding: 4px 0;
        width: 50%;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    .btn {
      margin-top: $space-md;
      text-align: center;
    }

    &--flight {
      width: 100%;
      border-top: 1px solid cc(light, border);
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-top: $space-md;

      .btn {
        margin-top: 0;
        margin-right: $space-xs;
      }
    }

    &--car {
      align-items: flex-start;
      margin-top: $space-md;

      @media #{$mq-large-up} {
        margin-top: 0;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
      }

      .cards__days {
        margin-bottom: $space-sm;

        @media #{$mq-large-up} {
          display: block;
          margin-bottom: $space-xs;
        }
      }

      .btn {
        margin-top: 0;

        @media #{$mq-large-up} {
          margin-top: $space-sm;
        }
      }

      div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;

        @media #{$mq-large-up} {
          flex-direction: column;
          align-items: flex-end;
        }
      }
    }
  }

  &__days {
    font-size: $text-sm;
    margin-bottom: $space-xs;
    font-weight: $regular;
  }

  &__mention {
    font-size: $text-sm;
    font-weight: $semi-bold;
    color: cc(branding);
  }

  &__price {
    color: cc(branding);
    font-weight: $semi-bold;
    font-size: $text-sm;

    &-group {
      margin-left: 0;

      @media #{$mq-medium-up} {}
    }

    &--error {
      color: cc(error, alert);
    }

    &-budget {
      color: cc(medium, text);
    }

    &-update {
      text-decoration: line-through;
      // color: cc(medium, text, 0.8);
      // font-size: $text-xs;
      // display: block;
    }

    &-promo {
      font-size: 11px;
      background-color: cc(promo, notification, 0.7);
      padding: 3px 4px;
      text-transform: uppercase;
      border-radius: 4px;
      margin-left: $space-xs;
      color: cc(h800, dark, 0.6);
    }
  }

  &__link {
    color: cc(branding);
    vertical-align: middle;
    display: flex;
    align-items: center;

    .icon {
      margin-left: $space-xs;
      font-size: $text-lg;
    }
  }

  &__footer {
    padding-top: $space-sm;
    margin-top: auto;

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &--flight {
      background-color: cc(bg, 0.45);
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: $space-md;
      font-size: $text-sm;
      color: cc(medium, text);
    }
  }

  &__option {
    display: flex;

    li {
      margin-left: $space-sm;

      &:first-child {
        margin-left: 0;
      }
    }

    .icon {
      font-size: $text-lg;
      display: block;
    }
  }

  &__actu {
    height: 350px;

    .cards__subtitle {
      color: cc(branding);
    }

    .cards__img {
      width: 100%;

      @media #{$mq-large-up} {
        width: 45%;
      }
    }

    .cards__container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media #{$mq-large-up} {
        width: 55%;
      }
    }

    &--minimal {
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
      position: relative;
      border-radius: 6px;
      z-index: 0;
      overflow: hidden;
      display: block;
      height: 150px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:after {
        content: "";
        width: 100%;
        height: 100%;
        background-color: cc(h900, dark);
        position: absolute;
        top: 0;
        left: 0;
        z-index: $zindex-backward;
        opacity: 0.8;
      }
    }
  }

  &__links {
    color: cc(medium, text);
    font-size: $text-sm;
    display: flex;
    align-items: center;

    &:hover {
      color: cc(branding);
    }

    .icon {
      margin-right: $space-xs;
      font-size: $text-md;
    }
  }

  &__location {
    margin-top: $space-sm;

    p {
      color: cc(branding);
      font-size: $text-sm;
      font-weight: $semi-bold;
    }
  }

  &__empty {
    img {
      max-width: 70%;
    }
  }
}
