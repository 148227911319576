.avatar {
  width: 100px;
  height: 100px;
  background-color: red;
  border-radius: 50%;
  position: relative;
  overflow: hidden;

  &__img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
}
