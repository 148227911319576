.story {
  position: relative;
  margin: 0 auto;
  width: 100%;

  @media #{$mq-large-up} {
    width: 80%;
  }

  &:before {

    @media #{$mq-large-up} {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      width: 2px;
      background-color: cc(branding, .3);
      z-index: $zindex-backward;

      left: 0;
    }

  }

  &__section {
    width: 100%;
    margin-top: $space-xl;

  }

  &__head {
    display: flex;
    align-items: flex-start;
    margin-bottom: $space-md;
  }

  &__bullet {

    @media #{$mq-large-up} {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 2px solid cc(bg);
      background-color: cc(branding);
      margin-left: -7px;
      margin-right: $space-md;
    }
    
  }

  &__content {
    background-color: cc(light);
    position: relative;

    @media #{$mq-large-up} {
    
      margin-left: $space-lg;
    }
    
  }

  &__date {
    color: cc(branding);
    font-weight: $semi-bold;
    font-size: $text-sm;
    text-transform: uppercase;
  }

}
