.mbt-header {
  width: 100%;
  padding: 14px $space-sm;
  display: flex;
  align-items: flex-start;
  // flex-direction: column;
  background-color: cc(white);
  height: 60px;
  z-index: 9999;
  justify-content: space-between;
  // overflow-y: scroll;

  &.js-fixed {
    position: fixed;
    top: 0;

    @media #{$mq-medium-up} {
      position: initial;
    }
  }

  &.js-active {
    height: 100vh;
    background-color: cc(white);
    overflow: hidden;
    align-items: flex-start;

    .mbt-header__nav,
    .mbt-header__nav-action {
      visibility: visible;
      opacity: 1;
      overflow-x: initial;
    }

    .mbt-header__item {
      transform: scale(1) translateY(0);
      opacity: 1;
    }

    .mbt-header__menu {
      display: block;
    }

    .btn-mobile {
      display: inline-block;
      position: absolute;
      left: 0;
      bottom: 60px;
      width: 100%;
      padding: 0 16px;

      .btn {
        width: 100%;
        text-align: center;
      }
    }
  }

  @media #{$mq-medium-up} {
    overflow: visible;
    background: cc(white);
    height: 88px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 $space-xxl;
    ;
  }

  &--setting {
    background-color: cc(bg-dark);
    position: relative;

    @media #{$mq-medium-up} {
      height: 100px;
    }


    &:after {
      @media #{$mq-medium-up} {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: -100%;
        background-color: cc(bg-dark);
        z-index: $zindex-backward;
      }
    }

    &:before {
      @media #{$mq-medium-up} {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        right: -100%;
        background-color: cc(bg-dark);
        z-index: $zindex-backward;
      }
    }
  }

  &__logo-wrapper {
    display: flex;
    align-items: center;
    // display: inline-block;
    // position: absolute;
    // top: 30px;
    // margin-top: -16px;

    @media #{$mq-medium-up} {
      // position: initial;
      // margin-top: 0;
    }
  }

  &__logo {
    height: 32px;

    @media #{$mq-medium-up} {
      margin-right: $space-lg;
      width: auto;
      height: auto;
    }

    &.logo-desktop {
      display: none;

      @media #{$mq-medium-up} {
        display: block;
      }
    }

    &.logo-mobile {
      @media #{$mq-medium-up} {
        display: none;
      }
    }
  }

  &__burger {
    // position: absolute;
    // top: 0;
    // left: 50px;
    width: 25px;
    height: 25px;
    text-align: center;
    position: absolute;
    top: 18px;
    left: 60px;
    // background-color: cc(branding);

    @media #{$mq-medium-up} {
      display: none;
    }
  }

  &__nav {
    visibility: hidden;
    opacity: 0;
    overflow-x: hidden;

    @media #{$mq-medium-up} {
      display: flex;
      align-items: center;
      margin-top: 0;
      visibility: visible;
      opacity: 1;
      overflow-x: initial;

      .mbt-header__item {
        transform: scale(1) translateY(0);
      }
    }

    .mbt-header__link {
      visibility: hidden;
      opacity: 0;
      display: none;

      @media #{$mq-medium-up} {
        visibility: visible;
        opacity: 1;
        display: flex;
      }
    }

    .mbt-header__item {
      transform: scale(1.10) translateY(-20px);
      transition: all 200ms cubic-bezier(0.4, 0.01, 0.165, 0.99);
    }

    .mbt-dropdown__sub {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      box-shadow: none;
      width: 100%;
      top: 24px;
      position: relative;

      @media #{$mq-medium-up} {
        top: 60px;
        width: 300px;
        box-shadow: 0 20px 30px cc(01, neutral, .3);
        visibility: hidden;
        opacity: 0;
        position: absolute;
      }
    }

    .mbt-dropdown__link {
      font-size: $text-sm;
      padding: $space-sm 0;

      @media #{$mq-medium-up} {
        font-size: $text-xs;
        padding: 10px 15px;
      }

      .icon {
        font-size: $text-lg;

        @media #{$mq-medium-up} {
          font-size: $text-md;
        }

      }
    }

  }

  &__item {
    // padding: $space-sm 0;
    border-top: 1px solid cc(light, .1);

    &:first-child {
      border: none;
    }

    @media #{$mq-medium-up} {
      margin: 0 14px;
      padding: 0;
      border: none;
    }

    @media #{$mq-xlarge-up} {
      margin: 0 16px;
      padding: 0;
      border: none;
    }

    &.mbt-dropdown {
      position: initial;

      @media #{$mq-medium-up} {
        position: relative;
      }
    }

    &.btn-mobile {
      display: none;
    }
  }

  &__link {
    color: cc(03, neutral);
    display: inline-block;
    font-size: $text-sm;
    position: relative;

    &:after {
      content: '';
      opacity: 0;
      width: 100%;
      height: 2px;
      background-color: cc(branding);
      position: absolute;
      left: 0;
      bottom: -5px;
      transform: translateY(-10px);
      transition: all 500ms cb(reg);
    }

    &:hover,
    &.js-active {
      &:after {
        content: '';
        opacity: 1;
        transform: translateY(0);
      }
    }

    &.btn--link {
      background-color: cc(bg-dark, .25);

      @media #{$mq-medium-up} {
        padding: 5px 10px;
        white-space: nowrap;
      }
    }

    .mbt-notification {
      position: absolute;
      top: -12px;
      right: -12px;
      font-size: $text-xs;
      background-color: cc(default, secondary);

      @media #{$mq-medium-up} {
        top: -5px;
        right: -5px;
      }

    }
  }

  &__nav-action {
    display: flex;
    align-items: center;
    // flex-direction: column;
    // justify-content: flex-end;

    // visibility: hidden;
    // opacity: 0;

    z-index: 99999;
    // flex: 1;

    // position: absolute;
    // top: 6px;
    // right: 0;



    // background-color: red;
    // position: absolute;
    // bottom: 0;
    // top: px;
    // display: flex;
    // flex-direction: column;
    // justify-content: flex-end;
    // align-items: flex-end;
    // flex: 1;
    // width: 100%;
    // margin-top: -45px;
    // margin-top: -45px;
    // right: 70px;
    margin-left: 54px;


    @media #{$mq-medium-up} {
      margin-left: 0;
      position: initial;
      margin-top: 0;
      margin-left: auto;
      visibility: visible;
      opacity: 1;
      flex-direction: row;
      flex: none;
      align-items: center;
    }



    .mbt-dropdown__sub {
      position: absolute;
      // top: $space-xs;
      width: 100%;
      top: initial;
      bottom: 44px;
      box-shadow: 0 -10px 20px cc(01, neutral, .05);
      border: 1px solid cc(05, neutral);

      &:after {
        display: none;
      }

      @media #{$mq-medium-up} {
        top: 40px;
        bottom: initial;
        width: 300px;
      }


    }

    .mbt-header__item {
      // padding: $space-xs 0;
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
        border: none;

        @media #{$mq-medium-up} {
          margin-right: 16px;
        }
      }

      // width: 100%;
      @media #{$mq-large-up} {

        &:first-child {
          position: absolute;
          left: 50%;
          transform: translateX(-58%);
          // right: 0;
          // top: 0; 
        }

      }



      // &:nth-child(4) {
      //   position: absolute;
      //   right: 0;
      //   top: 0;
      // }


      @media #{$mq-medium-up} {
        width: auto;
      }
    }

    .mbt-header__link {
      &:after {
        display: none;
      }

      &-currency {

        @media #{$mq-medium-up} {
          color: cc(03, neutral);
          background: cc(default, secondary, .8);
          border-radius: 100px;
          padding: 2px 2px 2px 8px;
          color: cc(white);
        }
      }
    }

    .btn {
      // width: 100%;
      text-align: center;
      padding: 8px 12px;
      font-size: $text-xs;

      .icon {
        font-size: 16px;

        @media #{$mq-large-up} {
          font-size: 20px;
        }
      }


      @media #{$mq-medium-up} {
        width: auto;
      }

      @media #{$mq-large-up} {
        padding: 12px 20px;
        font-size: $text-sm;
      }


    }

    .btn-desktop {
      display: none;

      @media #{$mq-medium-up} {
        display: block;
      }
    }


  }

  &__notification {
    // margin-right: $space-md;
    font-size: 20px;
    position: relative;
    vertical-align: middle;
    margin-top: 8px;
    // display: none;

    @media #{$mq-large-up} {
      margin-right: 0;
      font-size: 28px;
      display: block;
    }

    .icon {
      display: block;
    }

    .counter {
      background-color: cc(branding);
      color: cc(white);
    }
  }

  &__menu {
    display: none;
    position: absolute !important;
    left: 0;
    top: 60px;
    width: 100%;
    padding: 16px;

    @media #{$mq-medium-up} {
      display: block;
      position: relative !important;
      width: auto;
      top: 0;
    }
  }





}