.mbt-checkbox, .mbt-radio {
  display: flex;
  align-items: center;

  &__input {
    display: none;
    left: 0;
    top: 0;
    margin: 0 !important;
    padding: 0 !important;
    opacity: 0;
    height: 0;
    width: 0;
    pointer-events: none;

    + label {
      &:before {
        content: '';
        display: flex;
        width: 18px;
        height: 18px;
        border: 2px solid cc(medium, border);
        margin-right: 20px;
        transition: transform 0.2s;
        border-radius: $radius-xs;
      }
    }

    &:checked {
      + label {
        &:before {
          content: $icon--checkmark-outline;
          color: cc(light);
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: cc(branding);
          box-shadow: none;
          border-color: cc(branding);
        }
      }
    }

    &:active {
      + label {
        &:before {
          transform: scale(0.8);
        }
      }
    }

    &:active {
      &:checked {
        + label {
          &:after {
            transform: none;
            transition: none;
          }
        }
      }
    }
  }

  &__label {
    display: inline-block; // fallback
    display: flex;
    cursor: pointer;
    user-select: none;
    font-weight: $semi-bold;
    font-size: $text-sm;
  }

  &__logo {
    width: 40px;
    height: 40px;
  }
}





