.rating {
  padding: $space-xs 0;
  display: flex;
  align-items: center;

  &__item {
    margin-left: 4px;

    &:first-child {
      margin-left: 0;
    }

    .icon {
      color: cc(bg-medium);
      font-size: $text-sm;
      display: block;

      &--active {
        color: cc(branding);
      }
    }
  }

}
