
.burger {

    &__menu {
      position: absolute;
      top:  50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  
    &__item {
      background-color: cc(light);
      width: 25px;
      height: 3px;
      border-radius: 3px;
      margin-top: 5px;
      transition: all 0.3s ease-in-out;
  
      &:first-child {
        margin-top: 0;
      }
    }
  
    &__toggle {
      width: 100%;
      height: 100%;
      position: absolute;
      top:  50%;
      left: 50%;
      z-index: 10;
      transform: translate(-50%, -50%);
      cursor: pointer;
      opacity: 0;
  
      &:checked {
        + .burger__menu .burger__item--top {
          transform: rotate(-45deg);
          margin-top: 10px;
        }
      }
  
      &:checked {
        + .burger__menu .burger__item--meat {
          transform: rotate(45deg);
          margin-top: -3px;
        }
      }
  
      &:checked {
        + .burger__menu .burger__item--bottom {
          transform: scale(0);
        }
      }
    }
  
  }

