.mbt-datepicker {
	width: 100%;

	&--multi  {
		@media #{$mq-medium-up} {
			display: flex;
		}

		.mbt-datepicker__calendar  {
			&:first-child  {
				margin-right: $space-lg;
			}
		}
	}

	&__calendar {
		width: 100%;
		margin-top: $space-lg;
		&:first-child {
			margin-top:0 ;
		}

		@media #{$mq-medium-up} {
			width: 50%;
			margin-top: 0;
		}

		&--mobile {
			@media #{$mq-medium-up} {
				display: none;
			}
		}
	}

	&__head  {
		display: flex;
		width: 100%;
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-weight: $semi-bold;
		color: cc(01, neutral);
	}

	&__button {
		display: none;
		align-items: center;
		justify-content: center;
		padding: 0;
		background-color: cc(default, primary);
		cursor: pointer;
		border: none;
		border-radius: 50%;
		color: cc(white);

		.icon {
			margin-right: 0;
		}

		@media #{$mq-medium-up} {
			width: 30px;
			height: 30px;
			display: flex;
		}
	}

	&__month {
		padding-right: $space-xs;

		&-container {
			justify-content: center;
			display: flex;

			@media #{$mq-medium-up} {
				flex: 1;
			}
		}
	}

	&__content {
		width: 100%;
		margin-top: $space-sm;
	}

	&__weekdays {
		width: 100%;
		display: flex;
		justify-content: space-between;
		font-size: $text-sm;
		font-weight: $semi-bold;
	}

	&__row {
		display: flex;
		justify-content: space-between;
		padding: 4px 0;
	}

	&__day,
	&__dow {
		width: 30px;
		height: 30px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		transition: all 200ms cc(reg);
		font-size: $text-sm;
		color: cc(02, neutral);
	}

	&__day {
		font-weight: $semi-bold;

		&.js-active {
			color: cc(light, text);
			background-color: cc(branding);
		}

		@media #{$mq-medium-up} {

			&:hover,
			&.js-active {
				color: cc(light, text);
				background-color: cc(02, neutral);
			}
		}



		&.js-select {

			&:before {
				content: "";
				width: 100%;
				height: 100%;
				background-color: cc(02, neutral, .15);
				position: absolute;
				top: 50%;
				left: 0;
				transform: translate(0, -50%);
				z-index: $zindex-backward-xs;
				border-radius: 50%;
			}

		}


		&--other {
			color: cc(04, neutral);

			&:hover {
				color: cc(white);

				&:after {
					background-color: cc(light);
				}
			}
		}
	}

	&__dow {
		color: cc(03, neutral);
	}
}