.mbt-section {
  padding: $space-xl 0;
  background: cc(light);

  &--no-paggind-top {
    padding-top: 0px;
  }

  &--highlight {
    background: cc(bg);
  }

  &--highlight-02 {
    background-color: #FBFBFC;
    padding: 0;
  }

  h3 {
    font-size: $text-xl;
    color: cc(02, neutral);
    font-weight: $semi-bold;
    margin-bottom: $space-xs;
  }

  &__header {
    padding-bottom: $space-lg;

    &--center {
      @media #{$mq-medium-up} {
        text-align: center;
      }

      h1 {
        max-width: 620px;
        margin: 0 auto;
      }
    }

    &-- &--link {

      @media #{$mq-medium-up} {
        display: flex;
        justify-content: space-between;
      }
    }

    h2 {
      font-size: $text-xl;
      color: cc(branding);
      font-weight: $regular;
    }

    h1 {
      font-size: $text-xxl;
      color: cc(h900, dark);
      font-weight: $bold;
    }

    p {
      font-size: $text-lg;
      color: cc(h600, dark);
      font-weight: $medium;
      margin-top: $space-sm;
    }
  }

  &__illus {
    display: flex;

    // align-items: flex-start;
    &--sign-up {
      display: none;
    }

    @media #{$mq-large-up} {
      display: flex;
    }
  }

  &__list {
    font-weight: $regular;
    font-size: $text-lg;

    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    overflow-x: auto;
    position: relative;

    @media #{$mq-medium-up} {}

    // li {
    //   display: flex;
    //   flex-direction: column;
    //   align-items: center;
    //   justify-content: space-between;
    // }

    &--center {
      text-align: center;
    }
  }

  &__item {
    min-width: 80%;
    display: block;
    margin-bottom: 1rem;
    text-align: center;

    @media #{$mq-medium-up} {
      min-width: unset;
      margin-bottom: unset;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: $space-sm;

    @media #{$mq-medium-up} {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: $space-lg;
    }

    @media #{$mq-medium-up} {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: $space-xl;
    }
  }

  &__form {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &-justify-space-between {
      justify-content: space-between;
      gap: 30px
    }

    .btn {
      padding: 12px 20px;
      font-size: $text-sm;
    }

    div {
      max-width: 350px;
    }

    h3 {
      font-size: $text-xxl;
      color: cc(02, neutral);
      font-weight: $semi-bold;
      margin-bottom: $space-xs;
    }

    p {
      margin-bottom: $space-md;
    }

    ul {
      li {
        margin: $space-sm 0;

        &:last-child {
          span {
            background-color: cc(blue, default);
          }
        }
      }

      p {
        margin-bottom: 0;
        line-height: $heading-lh;
        color: cc(02, neutral);
        font-weight: $semi-bold;
      }

      span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: $space-md;
        width: $space-xxl;
        padding: 0 $space-xs;
        background-color: cc(default, secondary);
        border-radius: $radius-lg;
        color: cc(white);
        margin-right: $space-sm;
      }
    }
  }

  &__step {
    position: relative;

    ul {
      display: flex;
      overflow-x: scroll;
      width: 100%;
      -webkit-overflow-scrolling: touch;
      padding: 16px 0;

      @media #{$mq-large-up} {
        overflow-x: initial;
        padding: 32px 0;
        justify-content: space-between;
      }

      ::-webkit-scrollbar {
        -webkit-appearance: none;
        background-color: red;
      }
    }

    li {
      width: 200px;
      margin-left: 8px;

      @media #{$mq-large-up} {
        width: 100%;
        margin-left: 16px;
      }

      &:first-child {
        margin-left: 0;
      }

      >* {
        width: inherit;
      }
    }

    &-nav {
      display: none;

      @media #{$mq-large-up} {
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: $zindex-header;

        &:first-child {
          left: -40px;
        }

        &:last-child {
          right: -40px;
        }
      }

    }



  }


}