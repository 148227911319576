.car {

  &__content {

    @media #{$mq-large-up} {
      display: flex;
      align-items: center;
    }
  }

  &__img {
    text-align: center;
    margin-bottom: $space-xl;

    @media #{$mq-large-up} {
      margin-right: $space-xl;
      width: 250px;
      margin-bottom: 0;
    }
    
  }

  &__details {
    background-color: cc(bg-medium, .15);
    padding: $space-md;
    display: flex;
    align-items: center;
  }


  &__reviews {
    width: 250px;
    display: flex;
    align-items: center;
    margin-right: $space-xl;
  }

  &__score {
    width: 50px;
    height: 50px;
    background-color: cc(branding);
    border-radius: 50%;
    text-align: center;
    line-height: 50px;
    margin-right: $space-md;

    span {
      font-weight: $semi-bold;
      color: cc(light, text);
      font-size: $text-lg;
      display: inline-block;
    }
  }

  &__mention {
    color: cc(dark, text);
    font-weight: $semi-bold;
  }

  &__count {
    display: block;
  }

  &__warranty {
    margin-left: $space-xl;
  }


}
