.separate {
  display: flex;
  align-items: center;
  margin: $space-sm 0;

  &__line {
    display: block;
    width: 50%;
    height: 2px;
    background-color: cc(h500, dark);
  }

  &__text {
    text-transform: uppercase;
    font-weight: $semi-bold;
    background-color: cc(bg);
    padding: 0 $space-sm;
    font-size: $text-sm;
  }
}
