.timeline {

  &__item {
    position: relative;
    padding-bottom: $space-xxl;
    margin-right: 0;

   justify-content: center;

   @media #{$mq-medium-up} {
     justify-content: initial;
   }

    &:last-child {
      padding-bottom: 0;

      .timeline__step {
        &:after {
          display: none;
        }
      }
    }
  }

  &__step {
    width: 80px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    

    @media #{$mq-medium-up} {
      align-items: initial;
    }
    

    &:after {

      @media #{$mq-medium-up} {
        content: "";
        width: 1px;
        height: calc(100% + 2rem + #{$space-xxl});
        background-color: cc(branding);
        position: absolute;
        top: 0;
        left: 40px;
        z-index: $zindex-backward;
      }
     
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width:  50px;
      height: 50px;
      border-radius: 50%;
      border: 1px solid cc(branding);
      font-size: $text-xl;
      background-color: cc(bg);
      color: cc(branding);

      &:last-child {
        width: 20px;
        height: 20px;
        font-size: $text-md;
        background-color: cc(branding);
        color: cc(light, text);
        display: none;
        position: absolute;
        top: 0;
        right: 10px;

      }
    }

    &.js-active {

      span {

        &:first-child {
          background-color: cc(branding);
          color: cc(light, text);
        }

        &:last-child {
          display: block;
          right: 50%;
          transform: translateX(30px);
          
          @media #{$mq-large-up} {
            right: 10px;
            transform: translateX(0);
          }
        }
      }
    }

  }

  &__header {
    display: flex;
    justify-content: center;
    margin-bottom: $space-sm;

    @media #{$mq-medium-up} {
      justify-content: space-between;
    }
  }

  &__title {
    color: cc(branding);
    font-weight: $semi-bold;
    font-size: $text-lg;
   display: flex;
   align-items: center;

   .icon {
     margin-left: $space-sm;
     color: cc(warning, alert);
   }
  }

  &__actions {
    display: flex;
    align-items: center;

    a {
      color: cc(medium, text);
      font-size: $text-sm;
      margin-left: $space-sm;
      display: flex;
      align-items: center;

      &:hover {
        color: cc(branding);
      }
    }

    .icon {
      margin-right: $space-xs;
      font-size: $text-md;
    }
  }

  &__notif {
    display: flex;
    align-items: center;
    font-weight: $medium;

    .icon {
      margin-right: $space-xs;
      color: cc(warning, alert);
    }
  }

  &--reservation {

    .timeline__header {
      justify-content: start;
      align-items: center;
    }
    .timeline__step {
      width: auto;
      margin-right: $space-sm;
      &:after {
        display: none;
      }
    }
  }

}

