.loader {

  &__bar {
    width: 100%;
    height: 3px;
    background-color: cc(h500, dark);
    overflow: hidden;
  }

  &__line {
    display: block;
    width: 0%;
    height: inherit;
    background-color: cc(branding);
    // delete, just for example
    animation: getWidth 3s cb(io) infinite;
    animation-fill-mode: forwards;
    //
    transition: all 2s cb(io);
  }

  &__ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  &__ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: cc(branding);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  &__ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }

  &__ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  &__ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  &__ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }

  &__ellipsis {

    &--small {
      width: 80px;
      height: 10px;

      div {
        top: 0;
        width: 10px;
        height: 10px;
      }
    }
  }

}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

@keyframes getWidth {
  100% {
    width: 100%;
  }
}
