.mbt-step {
    display: flex;
    justify-content: center;

    &__item {
        width: 25px;
        height: 25px;
        border-radius: 100px;
        background-color: cc(05, neutral);
        font-weight: $semi-bold;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5%;
        font-size: $text-sm;
        position: relative;

        @media #{$mq-large-up} {
            margin-left: $space-md;
        }

        &:before {
            content: '';
            width: 100%;
            height: 1px;
            background-color: cc(05, neutral);
            position: absolute;
            right: 100%;
            top: 50%;
            transform: translateY(-50%);
        }

        &:first-child {
            margin-left: 0;

            &:before {
                display: none;
            }
        }

        &.error {
            background-color: cc(error, alert);

            a {
                color: cc(06, neutral);
            }

            &:before {
                background-color: cc(default, primary);
            }
        }

        &.js-active {
            background-color: cc(default, primary);

            a {
                color: cc(06, neutral);
            }

            &:before {
                background-color: cc(default, primary);
            }
        }

        a {
            color: cc(01, neutral);
        }
    }

}