.mbt-carousel {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  position: relative;

  &__item {
    flex: 1;
  }

  @media #{$mq-large-up} {
    flex-wrap: wrap;
  }
}
